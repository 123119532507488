/* eslint-disable react-hooks/exhaustive-deps */
import { memberFields } from "./helpers/constants";
import { useMember } from "../../../../components/Providers/MemberProvider";
import { useParams } from "react-router-dom";
import CreateForm from "../../../../components/FormPage/CreateForm/CreateForm";
import Toastily from "../../../../components/Toastily/Toastily";
import { useToastily } from "../../../../components/Toastily/useToastily";
import { validateCpf } from "../../../../components/Form/FormInputs/helpers/utils";
import { getOrganizationById } from '../../../../services/OrganizationService'
import * as yup from "yup";
import { triggerCreateMember } from "./helpers/helpers";
import { useEffect, useState } from "react";
import ProfileCard from "../../../../components/FormPage/Cards/ProfileCard/ProfileCard";
import Page from "../../../../components/Page/Page";

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .test("cpf validation", "Documento Inválido", (cpf) => {
      if (!cpf) return false;
      return validateCpf(cpf.replace(/\D/g, ""));
    }),
  email: yup
    .string()
    .required("Campo obrigatório")
    .matches(/\S+@\S+\.\S+/, "Por favor, informe um e-mail válido!")
});

const MemberProfile = () => {
  const params = useParams()
  const [organization, setOrganization] = useState({})
  const [aMembersField, setMembersField] = useState([])
  const { member } = useMember();
  const { toast, actions: actionsToast } = useToastily();
  const [picture, setPicture] = useState();

  useEffect(async () => {
    let org = await getOrganizationById(params.id)
    setOrganization(org)
    const fieldOrgIndex = memberFields.findIndex((m) => m.name === "organization");
    memberFields[fieldOrgIndex]['defaultValue'] = org?.name
    setMembersField(memberFields)
  }, [params] )

  const onSubmit = async (data) => {
    try {
     
      data.organizationId = params.id
      await triggerCreateMember(
        {
          ...data,
          avatar: picture
        },
        member
      );
      actionsToast.setSuccess("Membro criado com sucesso!");

    } catch (err) {
      console.log(err)
      actionsToast.setError(
        err.message ||
          "Não foi possível realizar a criação de um novo membro. Tente novamente mais tarde!"
      );
    }
  };

  const getPicture = (value) => {
    setPicture(value);
  };

  return (
    <>
      <Page showBreadcrumb={true} fullWidth={true}>
        <Toastily {...toast} />
        <CreateForm
          {...{
            fields: aMembersField,
            validationSchema,
            onSubmit,
            btnSubmit: {
              text: "Criar Membro"
            },
            header: {
              title: "Membro",
              subtitle: `Crie um novo membro para a organização ${organization?.name}`
            },
            rightCardSlot: (
              <ProfileCard {...{ getPicture, avatar: { readonly: false } }} />
            )
          }}
        />
      </Page>
    </>
  );
};

export default MemberProfile;
