import * as yup from "yup";

export const validationSchema = yup.object().shape({
    member: yup.object().shape({
        name: yup.string().required("Campo obrigatório"),
        email: yup
            .string()
            .required("Campo obrigatório")
            .email("Email inválido"),
        documentNumber: yup.string().required("Campo obrigatório"),
        phone: yup.string()
    })
});
