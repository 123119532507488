import React, { useEffect, useState } from "react";
import "./styles.scss";

const transformObjectIntoQuerystring = (object) => {
  let querystring = Object.keys(object).reduce((accumulator, objectKey) => {
    accumulator = `${accumulator}&${objectKey}=${object[objectKey]}`;
    return accumulator;
  }, "");

  return querystring;
};

const EmbeddedForm = (props) => {
  const { PIPEFY_PUBLIC_FORM, title = "", initialValues = {}, size = { width: " 100%", height: "74vh" } } = props;
  const fixedUrl = `${PIPEFY_PUBLIC_FORM}?embedded=true`;
  const [baseUrl, setBaseUrl] = useState(fixedUrl);

  useEffect(() => {
    let url = fixedUrl;
    if (initialValues) url += transformObjectIntoQuerystring(initialValues);
    setBaseUrl(url);
  }, [fixedUrl, initialValues]);

  return (
    <>
      <iframe
        id="myFrame"
        title={title}
        style={{...size}}
        src={baseUrl}
        frameBorder="0"
        className="embedded-form"
      />
    </>
  );
};

export default EmbeddedForm;
