import React from "react";
import "./NotFoundPage.scss"
import { Container, Row, Col } from "react-bootstrap";

const NotFound = () => {

  return (
    <Container fluid className="NotFoundPage d-flex justify-content-center">
      <Row className="w-80 d-flex justify-content-around">
        <Col className="d-flex justify-content-center">
          <div>
            <h2 className="NotFoundPage__title">
              Opa!<br/>
              Página não <br/>encontrada
            </h2>
            <p className="NotFoundPage__description">
              Desculpe 😞 Parece que a página que você <br/>procurou não existe em nosso site.
            </p>
          </div>
        </Col>
        <Col className="d-flex justify-content-center">
          <div className="NotFoundPage__ilustration"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
