const buildPayload = (data, member) => {
  const simulationPayload = {
    type: data.type,
    propertyValue: data.propertyValue,
    monthlyIncome: data.monthlyIncome,
    entranceValue: data.entranceValue,
    term: parseInt(data.terms),
    loanValue: data.loanValue,
    dayDue: parseInt(data.dayDue),
    gracePeriod: parseInt(data.gracePeriod),
    table: data.table,
    itbi: data.itbi === "true" ? true : false,
    legalPerson: data.legalPerson === "true" ? true : false,
    organizationId: member.organizationId,
    memberId: member.id
  };

  return simulationPayload;
};

export { buildPayload };
