import { useReducer } from "react";

const initialState = {
  fixed: true,
  offCanvas: false,
  toogled: false,
  show: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OFF_CANVAS_CLOSE":
      return { fixed: false, toogled: false, offCanvas: true, show: false };
    case "OFF_CANVAS_OPEN":
      return { fixed: false, toogled: false, offCanvas: true, show: true };
    case "FIXED_TOOGLED_OPEN":
      return { fixed: true, toogled: true, offCanvas: false, show: false };
    case "FIXED_TOOGLED_CLOSE":
      return { fixed: true, toogled: false, offCanvas: false, show: false };
    default:
      throw new Error();
  }
};

const NavProvider = ({ initState = true, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setState = (action) => dispatch(action);

  const toogleOffCanvas = () => {
    state.show === true
      ? setState({ type: "OFF_CANVAS_CLOSE" })
      : setState({ type: "OFF_CANVAS_OPEN" });
  };

  return children({ ...{ state, setState, toogleOffCanvas } });
};

export default NavProvider;
