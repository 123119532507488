/* eslint-disable jsx-a11y/anchor-is-valid */
import { DropDown } from "App/components/DropDown";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth";
import "./styles.scss"

export const Divider = ({ clsx = "" }) => (
  <hr className={`sidebar-divider ${clsx}`} />
);

const CommonLink = ({ title, fa, route, onClick = null }) => {
  const isthirdPartyLink = route?.includes("https://");

  const ThirdPartyLink = ({ title, fa, route }) => (
    <a
      className="nav-link"
      href={route}
      target="_blank"
      rel="noopener noreferrer"
    >
      {fa && <i className={`fas fa-fw fa-${fa}`}></i>}
      <span>{title}</span>
    </a>
  );

  const InternalLink = ({ title, fa, route }) => (
    <Link className="nav-link" to={route}>
      {fa && <i className={`fas fa-fw fa-${fa}`}></i>}
      <span>{title}</span>
    </Link>
  );

  const ButtonLink = ({ title, fa, onClick }) => {
    return <a className="nav-link" href="!#" onClick={onClick}>
      {fa && <i className={`fas fa-fw fa-${fa}`}></i>}
      <span>{title}</span>
    </a>
  }

  route = route || "#";


  return (
    <li className="nav-item">
      {onClick && <ButtonLink {...{title, fa, onClick}}/>}
      {(!onClick) && (isthirdPartyLink ? (
        <ThirdPartyLink {...{ title, fa, route }} />
      ) : (
        <InternalLink {...{ title, fa, route }} />
      ) )}
    </li>
  );
};

export const NavList = ({userLeads, navLinks}) => {
  const { user } = useAuth();
  const isInGroup = (authGroup) => user.cognitoGroups?.includes(authGroup);
  const buildLinksThroughGroupsPermission = (lista) =>
    lista
      .map((item) => {
        const obj = { ...item };
        const isGroupVerificationRequired = !!obj.authGroup;

        if (item.visibility && !item.visibility({ user, userLeads })) {
          return null;
        }
        if (isGroupVerificationRequired) {
          if (isInGroup(obj.authGroup)) return obj;
          return null;
        }
        if (obj.items) obj.items = buildLinksThroughGroupsPermission(obj.items);

        return obj;
      })
      .filter((item) => item != null);

  const rebuiltLinks = buildLinksThroughGroupsPermission(navLinks);
  return (
      <>
        {rebuiltLinks.map(( item ) => (
          <>
            {!!item.items?.length ? <DropDown wrapperClass="nav-item" {...item} /> : <CommonLink {...item} />}
          </>
        ))}
      </>
  );
};
