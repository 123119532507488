import React from "react";
import OffcanvasSidebar from "./OffcanvasSidebar";
import FixedSidebar from "./FixedSidebar";

export const Sidebar = ({ state, setState, toogleOffCanvas }) => {
  return (
    <>

        {!state.fixed && state.offCanvas && (
          <OffcanvasSidebar {...{ ...state, setState, toogleOffCanvas }} />
        )}
    </>
  );
};
