import { Storage } from "aws-amplify";

const getOrganizationImage = (name) => {
  const URL = Storage.get(`organizations/image/${name}`);
  return URL;
};

const uploadOrganizationImage = (name, content) => {
  const URL = Storage.put(`organizations/image/${name}`, content, {
    contentType: "image/png"
  });
  
  return URL;
};

export { getOrganizationImage, uploadOrganizationImage };
