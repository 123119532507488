import {getAvatarUrl} from "App/helpers/storage/uploadAvatar";
import defaultProfileImage from "images/undraw_profile_1.svg";


export const getOrganizationField = (organization) =>
    [
      {
        'name': 'organization',
        'placeholder': organization.name,
        'defaultValue': organization.name
      }
    ]

export const getMemberFields = (member) => {
  let memberFields = Object.keys(member).map( (memberProperty) => {
    return {
      name: memberProperty,
      defaultValue: member[memberProperty],
    }
  })

  return memberFields
}

export const getAvatarDefaultValue = async (avatarPath) => {
  try {
    const avatarUrl = await getAvatarUrl(avatarPath)
    return [
      {
        'name': 'avatar',
        'defaultValue': avatarUrl
      }
    ]
  } catch (error) {
    return [
      {
        'name': 'avatar',
        'defaultValue': defaultProfileImage
      }
    ]
  }
}
