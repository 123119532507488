import { Storage } from "aws-amplify";

const getMemberAvatar = (name) => {
  const URL = Storage.get(`members/avatar/${name}`);
  return URL;
};

const uploadMemberAvatar = (name, content) => {
  const URL = Storage.put(`members/avatar/${name}`, content, {
    contentType: "image/png"
  });
  
  return URL;
};

export { getMemberAvatar, uploadMemberAvatar };
