import { uploadMemberAvatar } from "../../../../../helpers/storage/memberAvatar";
import { removeEmptyKeys } from "../../../../../helpers/utils";
import {
  createMember,
  getMemberByEmail,
  updateMember
} from "../../../../../services/MemberService";

const uploadAvatar = async (memberId, file) => {
  const filename = `${memberId}.png`;

  await updateMember(memberId, {
    avatar: filename
  });

  await uploadMemberAvatar(filename, file);
};

const triggerCreateMember = async (formData, member) => {
  const thereIsMember = !!(await getMemberByEmail(
    formData.email,
    member.organizationId
  ));

  if (thereIsMember) {
    throw new Error("Já existe um membro com esse email");
  }

  formData.file = formData.avatar;
  formData.avatar = null;

  const { id } = await createMember({
    ...removeEmptyKeys(formData),
    organizationId: member.organizationId
  });

  if (formData.file) {
    await uploadAvatar(id, formData.file);
  }
};

export { triggerCreateMember };
