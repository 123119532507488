export const organizationInviteFields = [
  {
    id: "memberName",
    name: "memberName",
    label: "Nome:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "email",
    name: "email",
    label: "Email:",
    type: "email",
    inputClassLabel: ["bold"],
  },
  {
    id: "name",
    name: "name",
    label: "Razão Social:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "legalName",
    name: "legalName",
    label: "Nome Fantasia:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "documentNumber",
    name: "documentNumber",
    label: "CNPJ:",
    type: "cnpjDocument",
    inputClassLabel: ["bold"],
  },
  {
    id: "phone",
    name: "phone",
    label: "Telefone:",
    type: "phone",
    inputClassLabel: ["bold"],
  },
];