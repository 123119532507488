import React from "react";
import { SimplePage } from "App/components/Pages/SimplePage";
import { useMember } from "App/components/Providers/MemberProvider";
import Btn from "App/components/Btn";
import BtnDropDown from "App/components/Btn/BtnDropDown/BtnDropDown";
import icone1 from "images/icone-1.svg";
import womanIllustration from "images/illustrations/illustration_7.svg";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const Button = ({
                    title = "Faça uma Simulação",
                    classNames = ["primary", "round"],
                    list,
                    isDropDown = true,
                    handleClick = () => {},
                    ...rest
                }) => (
    <Col>
        {isDropDown ? (
            <BtnDropDown {...{ title, classNames, list, ...rest }} />
        ) : (
            <Btn {...{ title, classNames, ...rest }} onClick={handleClick} />
        )}
    </Col>
);

const Dashboard = () => {
    const { member } = useMember();

    const buttonsData = [
        {
            title: "Faça uma Simulação",
            classNames: ["primary", "round"],
            list: [
                { text: "Home Equity", action: "/private/simulations/new/HE" },
                { text: "Financiamento", action: "/private/simulations/new/FI" },
            ],
        },
        {
            title: "Cadastre sua operação",
            classNames: ["primary", "round"],
            list: [
                { text: "Home Equity", action: "/private/leads/new/HE" },
                { text: "Financiamento", action: "/private/leads/new/FI" },
            ],
        }
    ];

    return (
        <SimplePage
            title={`Olá, ${member?.name || ""}!`}
            classNames={["space-small", "background-large"]}
            icon={icone1}
        >
            <Container>
                <img
                    alt={"hello_wave"}
                    style={{
                        width: "40em",
                        position: "absolute",
                        right: "0",
                    }}
                    src={womanIllustration}
                    className="my-2"
                />
                <Row className="d-flex m-auto">
                    <Col className=" d-flex m-auto">
                        <Row className="Dashboard__buttons d-flex  my-lg-5">
                            {buttonsData.map((button, index) => (
                                <Button key={index} {...button} />
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </SimplePage>
    );
};

export default Dashboard;
