import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss"
import SearchListSimple from "./SearchListSimple";
import HeaderFilterSimple from "./HeaderFilterSimple";
import {Row, Col} from "react-bootstrap"

const ListFilteredAndPaginatedList = ({list, listOptions, filterOptions, headerButtons}) => {
  let headerFilters;

  if (headerButtons) {
    headerFilters = (
      <>
        <div className="FilterablePaginatedList__headerButtons">
          { headerButtons.map( (button) => button ) }
        </div>
        <HeaderFilterSimple list={list} {...filterOptions} /> 
      </>
    )
  } else {
    headerFilters = <HeaderFilterSimple list={list} {...filterOptions} /> 
  }

  return (
    <SearchListSimple list={list} {...listOptions} headerFilter={headerFilters}/>
  )
}

export default ListFilteredAndPaginatedList;
