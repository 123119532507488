import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { DataStore } from "@aws-amplify/datastore";
import { Organization } from "../../../../../../models";
import { FormGroup, Row } from "react-bootstrap";
import "./styles.scss";

const maxLength = 40;

const fetchOrganizations = async () => {
  const models = await DataStore.query(Organization, (o) =>
    o.name("contains", "").or((o) => o.documentNumber("contains", ""))
  );
  return models.map(({ id, name, documentNumber }) => ({
    value: id,
    label: `${documentNumber} (${name})`
  }));
};

const setCurrentValue = (organization, currentValue, setCurrentValue) => {
  if (!currentValue && organization) {
    setCurrentValue({
      value: organization.id,
      label: organization.name
    });
  }
};

const formatOptionLabel = ({ label }) => {
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`;
  } else {
    return label;
  }
};

const OrganizationSelect = ({
  name,
  cognitoGroups = [],
  organization,
  inputClassNames = [],
                              currentValue, setCurrentValueState
}) => {
  const [organizationsOptions, setOrganizationsOptions] = useState([]);
  const {
    formState: { errors }
  } = useFormContext();
  const listRef = useRef(null);

  useEffect(() => {
    const fetchOrganizationsAndSetState = async () => {
      const options = await fetchOrganizations();
      setOrganizationsOptions(options);
    };

    if (cognitoGroups.includes("Pontte_Users") && organization) {
      fetchOrganizationsAndSetState();
    }
  }, [cognitoGroups, organization]);

  useEffect(() => {
    setCurrentValue(organization, currentValue, setCurrentValueState);
  }, [organization, currentValue, setCurrentValueState]);

  const handleChange = (selectedOption) => {
    setCurrentValueState(selectedOption);
  };

  return (
    <Row className={"mb-4 " + inputClassNames.join(" ")} ref={listRef}>
      <FormGroup>
        <label className={"input-label bold form-label"} htmlFor={name}>
          <span>Parceiro</span>
        </label>
        <Select
          styles={{ backgroundColor: "white" }}
          options={organizationsOptions}
          value={currentValue}
          onChange={handleChange}
          className={"simple-select-input"}
          isDisabled={!cognitoGroups.includes("Pontte_Users")}
          name={name}
          placeholder="Selecione uma organização"
          isSearchable={true}
          formatOptionLabel={formatOptionLabel}
          menuPlacement={"bottom"}
          menuShouldScrollIntoView={true}
        />
        {errors[name] && <span>{errors[name].message}</span>}
      </FormGroup>
    </Row>
  );
};

export default OrganizationSelect;
