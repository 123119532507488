import { configSimulation as config } from "App/helpers/simulations/configSimulationHE";
import { validateMaxLoanValue } from "App/helpers/simulations/validationSimulation";
import * as yup from "yup";

export const validateSimulation = (values) => {
  const errors = {};

  if (!values.loanValue) {
    errors.loanValue = {};
    errors.loanValue.message = true;
  }

  const minLoanValue = config.common.loan.min.value;
  if (values.loanValue < minLoanValue) {
    errors.loanValue = {};
    errors.loanValue.message = `O valor mínimo de empréstimo é de ${config.common.loan.min.label}.`;
  }

  const maxLoanValue = config.common.loan.max.value;
  if (values.loanValue > maxLoanValue) {
    errors.loanValue = {};
    errors.loanValue.message = `O valor máximo de empréstimo é de ${config.common.loan.max.label}.`;
  }

  if (!values.propertyValue) {
    errors.propertyValue = {};
    errors.propertyValue.message = true;
  }

  if (values.propertyValue < config.common.property.min.value) {
    errors.propertyValue = {};
    errors.propertyValue.message = `O valor de um imóvel é a partir de ${config.common.property.min.label}`;
  }

  if (!values.monthlyIncome || values.monthlyIncome <= 0) {
    errors.monthlyIncome = {};
    errors.monthlyIncome.message = true;
  }

  if (!validateMaxLoanValue(values.loanValue, values.propertyValue)) {
    errors.loanValue = {};
    errors.loanValue.message = `O valor não pode ser maior que ${config.common.loan.percentage.max.label} do valor do imóvel`;
  }
  
  return { errors };
};

export const validationSchema = yup.object().shape({
  propertyValue: yup
    .number()
    .required()
    .typeError("Campo obrigatório")
    .min(
      config.common.property.min.value,
      `O valor de um imóvel é a partir de ${config.common.property.min.label}`
    ),
  monthlyIncome: yup.number().required("Campo obrigatório").typeError("Campo obrigatório"),
  loanValue: yup
    .number()
    .required("Campo obrigatório")
    .min(
      config.common.loan.min.value,
      `O valor mínimo de empréstimo é de ${config.common.loan.min.label}`
    )
    .max(
      config.common.loan.max.value,
      `O valor máximo de empréstimo é de ${config.common.loan.max.label}`
    )
    .test(
      "financed-based-on-the-property-value",
      `O valor não pode ser maior que ${config.common.loan.percentage.max.label} do valor do imóvel`,
      (value, ctx) => validateMaxLoanValue(value, ctx.parent.propertyValue)
    )
});
