/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth";
import { navLinks } from "./constants";

export const Divider = ({ clsx = "" }) => (
  <hr className={`sidebar-divider ${clsx}`} />
);

const CommonLink = ({ title, fa, route }) => {
  const isthirdPartyLink = route.includes("https://");

  const ThirdPartyLink = ({ title, fa, route }) => (
    <a
      className="nav-link"
      href={route}
      target="_blank"
      rel="noopener noreferrer"
    >
      {fa && <i className={`fas fa-fw fa-${fa}`}></i>}
      <span>{title}</span>
    </a>
  );

  const InternalLink = ({ title, fa, route }) => (
    <Link className="nav-link" to={route}>
      {fa && <i className={`fas fa-fw fa-${fa}`}></i>}
      <span>{title}</span>
    </Link>
  );

  return (
    <li className="nav-item">
      {isthirdPartyLink ? (
        <ThirdPartyLink {...{ title, fa, route }} />
      ) : (
        <InternalLink {...{ title, fa, route }} />
      )}
    </li>
  );
};

const NavCollapseLink = ({ title, fa, items }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <li className="nav-item">
      <div
        className={`nav-link ${collapse ? "collapsed" : ""}`}
        href="#"
        data-toggle="collapse"
        data-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
        onClick={() => setCollapse((collapse) => !collapse)}
      >
        <span>
          <i className={`fas fa-fw fa-${fa}`}></i>
          <span>{title}</span>
        </span>
      </div>
      <div
        id="collapseTwo"
        className="collapse show"
        aria-labelledby="headingTwo"
        data-parent="#accordionSidebar"
      >
        {collapse && (
          <div className="bg-light py-0 collapse-inner rounded">
            {items.map((item) => (
              <CommonLink {...item} />
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

const NavItemLink = ({ item }) => {
  const hasSubItems = !!item.items?.length;

  return hasSubItems ? <NavCollapseLink {...item} /> : <CommonLink {...item} />;
};

export const Menu = ({userLeads}) => {
  const { user } = useAuth();

  const isInGroup = (authGroup) => user.cognitoGroups?.includes(authGroup);

  const buildLinksThroughGroupsPermission = (lista) =>
    lista
      .map((item) => {
        const obj = { ...item };

        const isGroupVerificationRequired = !!obj.authGroup;

        if (item.visibility && !item.visibility({ user, userLeads })) {
          return null;
        }

        if (isGroupVerificationRequired) {
          if (isInGroup(obj.authGroup)) return obj;
          return null;
        }

        if (obj.items) obj.items = buildLinksThroughGroupsPermission(obj.items);

        return obj;
      })
      .filter((item) => item != null);

  const rebuiltLinks = buildLinksThroughGroupsPermission(navLinks);

  return (
      <>
        {rebuiltLinks.map(({ title, items, authGroup }) => (
          <>
            {title && <span className="nav-title">{title}</span>}
            {items.map((item) => (
              <NavItemLink {...{ item }} />
            ))}
            {isInGroup(authGroup) && <Divider />}
          </>
        ))}
      </>
  );
};
