import { useEffect, useState } from "react";
import { useMember } from "App/components/Providers/MemberProvider/MemberProvider";
import { ListFilterableAndPaginated } from 'App/containers/FilterablePaginatedList'
import { getAllPhases } from "App/services/PhaseService";
import { formatListCells } from "./helpers";
import { showColumnsLead, leadsStatusColumn } from "./constants";
import { useSubscribeToLeads } from "App/services/LeadService";

const getStatusOptionList = async () => {
  const allPhases = await getAllPhases();

  return allPhases.map( (phase) => {
    return {
      "value": phase.id,
      "label": phase.name
    }
  });
}

const LeadsList = () => {
  const {subscribeLeads} = useSubscribeToLeads()
  const [items, setItems] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    nameColumn: ['name'],
    statusColumn: 'Phase',
    statusOptions: leadsStatusColumn,
    dateColumn: 'createdAt',
    statusPlaceholder: "Filtrar status"
  });
  const [loading, setLoading] = useState(false);
  const { member } = useMember();

  useEffect(async () => {
    try {
      const phases = await getStatusOptionList();
      setFilterOptions( (oldState) => {
        return {
          ...oldState,
          statusOptions: phases
        }
      })
    } catch (error) {
      console.log("Error on ListLeadsContaiger -> getPhases");
      console.log({error})
    }
  }, [])

  useEffect(async () => {
    try {
      if (member) {
        setLoading(true);
        const leads = await subscribeLeads()
        setItems(leads)
        setLoading(false);
      }
    } catch (error) {
      console.log("Error on getLeads");
      console.log({error})
    }
  }, [member]);

  

  const leadListOptions = {
    showColumns: showColumnsLead,
    formatCell: formatListCells
  }

  return (
    <>
      <ListFilterableAndPaginated list={items} listOptions={leadListOptions} filterOptions={filterOptions} />
    </>
  );
};

export default LeadsList;
