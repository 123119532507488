import { useEffect, useState } from "react";
import { subscribeToModel } from "../../../helpers/dataStore";
import { useNavigate } from "react-router-dom";
import PaginatedList from "../../../components/Lists/PaginatedList";
import { Container } from "react-bootstrap";

const Orgs = () => {
  const [orgs, setOrgs] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    subscribeToModel({
      model: "Organization",
      callback: (o) => {
        setOrgs(o);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (orgs.length > 0) {
      setItems(
        orgs.map(({ id, name, legalName, phone, documentNumber }) => ({
          id,
          name,
          legalName,
          phone,
          documentNumber
        }))
      );
    }
  }, [orgs]);

  const header = ["Nome", "Nome Legal", "Telefone", "Documento"];

  const props = {
    title: "Organizações",
    items,
    header,
    onItemClick: ({ item }) => navigate(`/internal/organizations/${item.id}`),
    loading
  };

  return (
    <Container>
      <h1
        className="L-title"
        style={{ marginBottom: "6rem", marginLeft: "2rem" }}
      >
        Organizações
      </h1>
      <PaginatedList {...{ ...props }} />;
    </Container>
  );
};

export default Orgs;
