const configSimulation = {
  common: {
    loan: {
      max: {
        label: "R$ 20 milhões",
        value: 20000000.0
      },
      min: {
        label: "R$ 100 mil",
        value: 100000.0
      },
      percentage: {
        max: {
          label: "60%",
          value: 0.6 // the maximum loan value cannot be greater than 60% of the property value
        }
      }
    },
    compromisedIncome: {
      percentage: {
        max: {
          label: "30%",
          value: 0.3 // you can have up to 30% of your compromised income
        }
      }
    },
    ltv: {
      min: {
        label: "80%",
        value: 0.8
      }
    },
    property: {
      min: {
        label: "R$ 200 mil",
        value: 200000.0
      }
    }
  },
  financing: {
    ltv: {
      max: {
        label: "80%",
        value: 0.8
      }
    }
  },
  homeEquity: {
    ltv: {
      max: {
        label: "60%",
        value: 0.6
      }
    }
  }
};

export { configSimulation };
