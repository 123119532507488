/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { formatHeader, columns, triggerCalculateFlow, formatInstallments } from "./helpers";

const SimulateFI = ({ simulation, children, }) => {
  const [flow, setFlow] = useState({});
  const [installments, setInstallments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (simulation) {
      setLoading(true);
      triggerCalculateFlow(simulation, setFlow).finally(() =>
        setLoading(false)
      );
    }
  }, [simulation]);

  useEffect(() => {
    if (flow.installment) {
      setInstallments(formatInstallments(flow.installment));
    }
  }, [flow]);

  const header = formatHeader(simulation, flow);

  return children({ flow, installments, columns, header, loading });
};

export default SimulateFI;
