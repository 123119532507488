import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SimplePage } from "App/components/Pages/SimplePage";
import { useMember } from "App/components/Providers/MemberProvider";
import { useAuth } from "App/components/Auth";
import { getOrganizationById } from "App/services/OrganizationService";
import { useToastily } from "../../../../components/Toastily/useToastily";
import Toastily from "../../../../components/Toastily/Toastily";
import MemberForm from "./MemberForm";

const MemberCreate = () => {
  const [organization, setOrganization] = useState(null);
  const [currentValue, setCurrentValueState] = useState(null);
  const { member: currentMember } = useMember();
  const { user } = useAuth();
  const { toast } = useToastily();
  const fetchOrganization = async () => {
    try {
      const memberOrganization = await getOrganizationById(
        currentMember.organizationId
      );
      setOrganization(memberOrganization);
    } catch (error) {
      console.error("Error fetching organization:", error);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, [currentMember]);

  return (
    <SimplePage title="Adicionar usuário" large={true}>
      <Toastily {...{ ...toast }} />
      <Card className="card-member">
        <Row className="card-member-header">
          <Col className="card-member-header title">
            <h1 className="L-title">Informações do Usuário</h1>
          </Col>
        </Row>
        <hr />
        <Row className="card-member-body">
          <MemberForm
            user={user}
            organization={organization}
            currentValue={currentValue}
            setCurrentValueState={setCurrentValueState}
            cognitoGroups={user.cognitoGroups}
            isSubmitting={false} // Replace with your formState.isSubmitting
          />
        </Row>
      </Card>
    </SimplePage>
  );
};

export default MemberCreate;
