const HeaderCard = ({ title, subtitle, btnHeader }) => (
    <div className="profile-header flex-column justify-content-center">
      <h1 className="profile-header-title"> {title} </h1>
      {subtitle && (
        <p className="profile-header-description">
          <span>{subtitle}</span>
        </p>
      )}
      {btnHeader && (
        <button
          className="profile-header-edit-button"
          onClick={btnHeader.onClick}
        >
          {btnHeader.text}
        </button>
      )}
    </div>
);

export default HeaderCard;
