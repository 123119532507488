import React from "react";
import {Auth, Storage} from "aws-amplify";
import AwsConfig from "../../../aws-exports.js";
import ApiConfig from "../../services/api/ApiConfig";
import {getUser as getCurrentUser} from "../../services/UserService.js";

Auth.configure(AwsConfig);


const getCurrentUserSessionInfo = async () => {
    const session = await Auth.currentSession();

    const {attributes} = await Auth.currentUserInfo();

    const user = await getCurrentUser(attributes["sub"]);
    // user.Member = user.Members.items.find((m) =>  m.organizationId === organization) no futuro
    //user.Member = {...user.Members.items[0], avatar: await Storage.get(`members/avatar/${user.Members.items[0].id}.png`)}
    user.Member = {...user.Members.items[0], avatar: await Storage.get(user.Members.items[0]?.avatar)}
    const {payload} = session.getIdToken();

    const tokenId = session.getIdToken().getJwtToken();

    ApiConfig.setAuthorization(tokenId);

    if (session) return {...user, cognitoGroups: payload["cognito:groups"]};

    throw new Error("Session is not found");
};
export const AuthProvider = ({AuthContext, children}) => {
    const [user, setUser] = React.useState({
        username: "",
        password: "",
        isAuthenticated: false
    });

    const [error, setError] = React.useState(null);

    const signup = async (userInfo, callback, onError) => {
        const {email: username, password} = userInfo;

        try {
            const cognito = await Auth.signUp({
                username,
                password
            });

            setUser({...cognito.user, isAuthenticated: false});

            callback({userId: cognito.userSub});
        } catch (err) {
            if (err.code) onError({code: err.code, user: userInfo});
            console.log("error signing up:", err);
        }
    };

    const signin = async (newUser, callback, onError) => {
        const {email: username, password} = newUser;

        try {
            const user = await Auth.signIn(username, password);

            if (!user) throw new Error();

            const userSession = await getCurrentUserSessionInfo();

            setUser({...userSession, isAuthenticated: true});

            callback(userSession);
        } catch (err) {
            if (err.code) onError({code: err.code, user: newUser});
            if (err.code !== "UserNotConfirmedException") setError({type: "signin", code: err.code});
            console.log(`error signing ${err.code}`);
        }
    };

    const signout = async (callback) => {
        try {
            await Auth.signOut();
            setUser({isAuthenticated: false});
            callback();
        } catch (err) {
            console.log("error signing out", {err});
        }
    };

    const confirmSignup = (userInfo) => {
        const {email, code, userInviteId} = userInfo;

        return Auth.confirmSignUp(email, code, {
            clientMetadata: {
                userInviteId
            }
        });
    };

    const resendCode = (email) => {
        return Auth.resendSignUp(email);
    };

    const getCurrentSession = async () => {
        const userSession = await getCurrentUserSessionInfo();
        setUser({...userSession, isAuthenticated: true});
    };

    const sendForgotPassword = async (username) => {
        return await Auth.forgotPassword(username);
    }

    const sendForgotPasswordCode = async (values) => {
        await Auth.forgotPasswordSubmit(
            values.username,
            values.code,
            values.password,
        );
    };

    const updateCognitoGroups = async (userId, oldCognitoGroups, newCognitoGroups) => {
        if (oldCognitoGroups !== newCognitoGroups) {
            try {
                const attributes = {
                    'cognito:groups': newCognitoGroups
                };
                await Auth.updateUserAttributes(userId, attributes);
                console.log(`Cognito groups updated from ${oldCognitoGroups} to ${newCognitoGroups}`);
            } catch (error) {
                console.error(`Failed to update Cognito groups: ${error.message}`);
            }
        }
    };
    let value = {
        user,
        error,
        signin,
        signout,
        signup,
        confirmSignup,
        resendCode,
        getCurrentSession,
        sendForgotPassword,
        sendForgotPasswordCode,
        updateCognitoGroups
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
