export const formatMoney = (value) => {
  const settings = {
    decimal_separator: ".",
    value_separator: ".",
    to_fixed: 2
  };

  value = value.replace(/[.]/gi, "");
  value = parseInt(value.trim());
  value = value.toFixed(settings.to_fixed);
  value = value.replace(/(\d)(?=(\d{3})+\.)/g, `$1${settings.value_separator}`);
  value = value.split(".");
  value.splice(-1, 1);

  value = value.join(".");

  return value;
};

export const formatMoney2 = (value = 0) =>
  value
    ? `R$ ${Number(value)
        .toFixed(2)
        .replace(".", ",")
        .replace(/\d(?=(\d{3})+,)/g, "$&.")}`
    : "R$ 0,00";

export const formatValueToShortScale = (value) => {
  if (value === 0) return "0.0";
  const c = 1000;
  const d = 2;
  const scales = ["", "K", "M", "B", "T"];
  const valueScale = Math.floor(Math.log(value) / Math.log(c));
  return `${parseFloat((value / Math.pow(c, valueScale)).toFixed(d))}${scales[valueScale]}`;
};

export const formatGracePeriod = (gracePeriod) => {
  if (gracePeriod === undefined) {
    return "-";
  }
  if (parseInt(gracePeriod) === 0) {
    return "Não preciso";
  }
  return gracePeriod === 1 ? "1 mês" : `${gracePeriod} meses`;
};

export const months = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro"
};

export const formatMonth = (monthNumber) => {
  if (!monthNumber) {
    return "-";
  }

  if (monthNumber == "-1") {
    return "Não quero";
  }

  return months[monthNumber];
};

/**
 * @param {*} date expected dd-mm-yyyy
 * @returns month/year
 */
export const translateDate = (date) => {
  if (date) {
    const month = Number(date[3] + date[4]);
    const n = 4;
    const year = date.slice(date.length - n);
    return `${months[month]}/${year}`;
  }

  return "-";
};

/**
 * @param {*} date expected yyyy-mm-dd
 * @returns month/year
 */
export const translateDate2 = (date) => {

  if (date) {
    const month = Number(date[5] + date[6]);
    const year = date.slice(0, 4);
    return `${months[month]}/${year}`;
  }

  return "-";
};

export const formatTerms = (y) => (y === undefined ? "-" : `${y} meses`);

export const formatDate = (date) => {
  const newDate = new Date(date?.toLocaleString());
  return newDate.toLocaleDateString("pt-BR");
};

export const formatDateToLocaleDateBR = (d) => {
  if (!d) return null;

  const date = new Date(d);

  return date.toLocaleDateString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    timeZone: "UTC"
  });
};

export const formatDateToLocaleDateTimestampBR = (d) => {
  if (!d) return null;

  const date = new Date(d);
  return date.toLocaleDateString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const getDayDueDay = (date) => new Date(date).getUTCDate();

// dd/mm/aaaa -> aaaa-mm-dd
export const changeFormatDateStr = (str) =>  str ? str.split("/").reverse().join("-") : "";
