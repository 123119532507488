export const buildItemPagination = (list = [], numberPerPage = 5) => {
  let paginatedItems = [[]];
  let futureIndex = numberPerPage;
  let listIndex = 0;

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    if (i > futureIndex - 1) {
      futureIndex = futureIndex + numberPerPage;
      listIndex = listIndex + 1;
      paginatedItems[listIndex] = [];
    }

    paginatedItems[listIndex].push(item);
  }

  return paginatedItems;
};

const find = (list, key) => list.filter(({ id }) => id === key)[0];

export const curateFieldsToForms = (object, fields) =>
  Object.entries(object)
    .map(([key, value]) => {
      const field = find(fields, key);

      if (!field) return null;

      return {
        ...find(fields, key),
        value,
        defaultValue: value
      };
    })
    .filter((obj) => obj !== null);

export const getGuestAuthType = (hostAuthGroup, email) => {
  if (hostAuthGroup.includes("Pontte_Users")) {
    if (email.includes("@pontte")) {
      return "Pontte_Users";
    }
  }

  return null;
};

export const removeEmptyKeys = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => !!value));

/**
 * format a string array into an object with "label" and "value"
 * @param {array<string | object>} list
 * @returns {object} [{ label: "SIM", value: 1 }]
 */
export const curateOptions = (list) => {
  const isStrArray = list.every((item) => typeof item === "string");

  if (isStrArray) {
    return list.map((item) => ({ value: item, label: item }));
  }

  return list;
};

/**
 * Grouping list of [qty] into [qty] items
 * @param {*} object
 * @param {*} qtd
 * @returns [[x][x], [x][x]]
 */
export const groupListByNumberOfItems = (list, qtd = 2) =>
  list.reduce(
    (a, _item, i, array) =>
      i % qtd === 0 ? [...a, array.slice(i, i + qtd)] : a,
    []
  );

export const camelToSnake = (string) =>
  string.replace(/[\w]([A-Z])/g, (m) => m[0] + "_" + m[1]).toLowerCase();

export const objectCamelToSnake = (object) => {
  const data = {};
  Object.keys(object).map((key) => (data[camelToSnake(key)] = object[key]));
  return data;
};

export const setPosition = (index, total) => `${index + 1}/${total}`;

/**
 * Check if value is a valid url
 * 
 * @param { string } value to be checked as valid url.
 * @return { boolean } true if is a valid url, false if not is a valid url.
 */
export const isAValidUrl = (value) => {
  try {
    new URL(value)
    return true;
  } catch (error) {
    return false;
  }
}
