import { sendEmailRDStation } from "App/helpers/plugaFetcher";
import { getOrganizationById } from "App/services/OrganizationService";
import { DataStore } from "@aws-amplify/datastore";
import { UserInvite as UserInviteModel } from "../../../../../../models";

const saveUserInvite = (organizationId, hostId, guestId, authType) =>
  DataStore.save(
    new UserInviteModel({
      guestId,
      hostId,
      organizationId,
      status: "pending",
      authType
    })
  );

const determineGuestAuthType = (email, defaultPermission) =>
  email.includes("@pontte") ? "Pontte_Users" : defaultPermission;

const validateHostOrganizationID = (hostOrganizationID) => {
  if (!hostOrganizationID) {
    throw new Error("OrganizationId is not found");
  }
};

const triggerSendInvite = async ({
  host: { id: hostMemberId, organizationId: hostOrganizationID },
  guest: {
    id: guestMemberId,
    email: guestEmail,
    organizationId: guestOrganizationID,
    authType: guestUserPermission
  },
  organization
}) => {
  try {
    validateHostOrganizationID(hostOrganizationID);

    const guestAuthType = determineGuestAuthType(
      guestEmail,
      guestUserPermission
    );

    console.log({ guestUserPermission });

    const { name: partnerOrgName } = await getOrganizationById(
      guestOrganizationID
    );

    const { id: userInviteId } = await saveUserInvite(
      guestOrganizationID,
      hostMemberId,
      guestMemberId,
      guestAuthType
    );

    await sendEmailRDStation({
      userInviteId,
      email: guestEmail,
      partnerOrgName,
      partnerOrgId: hostOrganizationID
    });

    return {
      id: userInviteId,
      guestId: guestMemberId,
      hostId: hostMemberId,
      organizationId: hostOrganizationID,
      status: "pending",
      authType: guestAuthType
    };
  } catch (error) {
    console.error("Error in triggerSendInvite:", error);
    throw error;
  }
};

export default triggerSendInvite;
