import { createContext, useContext, useEffect, useState } from "react";

export const NotificationContext = createContext([]);

export const NotificationProvider = ({ children }) => {
  const [list, setList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);
  const [filterSize, setFilterSize] = useState(3);

  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    setFilteredList(
      list
        .sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0))
        .slice(0, filterSize)
    );
  }, [list, filterSize]);

  useEffect(() => {
    setShowMore(() => list.length !== filteredList.length);
  }, [filteredList.length, list.length]);

  const handleRemoveItem = (itemId) => {
    setList(list.filter((item) => item.id !== itemId));
  };

  const handleLoadItems = () => {
    setFilterSize((size) => size + 3);
  };

  const handleOpenNotifications = (status) => {
    setIsOpenNotifications(status);
    setFilterSize(3);
  };

  return (
    <NotificationContext.Provider
      value={{
        setNotifications: setList,
        notifications: filteredList,
        notificationsSize: list.length,
        isOpenNotifications,
        handleOpenNotifications,
        handleRemoveItem,
        handleLoadItems,
        showMore,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);

};
