import { DataStore } from "@aws-amplify/datastore";
import { UserInvite, UserInvite as UserInviteModel } from "../../models";

const saveUserInvite = (organizationId, hostId, guestId, authType) =>
  DataStore.save(
    new UserInviteModel({
      guestId,
      hostId,
      organizationId,
      status: "pending",
      authType
    })
  );

const getUserInviteById = async (id) => {
  if (!id) throw new Error("Invalidate id");
  return DataStore.query(UserInvite, id);
};

const updateUserInvite = (userInvite) =>
  DataStore.save(
    UserInvite.copyOf(userInvite, (ui) => {
      ui = userInvite;
    })
  );

const updateUserInviteStatus = (userInvite, status) =>
  DataStore.save(
    UserInvite.copyOf(userInvite, (ui) => {
      ui.status = status;
    })
  );

export {
  saveUserInvite,
  getUserInviteById,
  updateUserInvite,
  updateUserInviteStatus
};
