const buildPayload = (data, member) => {
  const simulationPayload = {
    type: data.type,
    propertyValue: data.propertyValue,
    table: data.table,
    loanValue: data.loanValue,
    monthlyIncome: data.monthlyIncome,
    skipMonth: parseInt(data.skipMonth),
    gracePeriod: parseInt(data.gracePeriod),
    dayDue: parseInt(data.dayDue),
    term: parseInt(data.terms),
    organizationId: member.organizationId,
    memberId: member.id,
    use_new_tac_calc: data.use_new_tac_calc
  };

  return simulationPayload;
};

export { buildPayload };
