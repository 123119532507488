import { organizationFields } from "./helpers/constants";
import CreateForm from "../../../../components/FormPage/CreateForm/CreateForm";
import Toastily from "../../../../components/Toastily/Toastily";
import { useToastily } from "../../../../components/Toastily/useToastily";
import { validateCnpj } from "../../../../components/Form/FormInputs/helpers/utils";
import * as yup from "yup";
import { triggerCreateOrganization } from "./helpers/helpers";
import ProfileCard from "../../../../components/FormPage/Cards/ProfileCard/ProfileCard";
import { useState } from "react";

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .test("cnpj validation", "Documento Inválido", (cnpj) => {
      if (!cnpj) return false;
      return validateCnpj(cnpj.replace(/\D/g, ""));
    })
});

const OrganizationNew = () => {
  const { toast, actions: actionsToast } = useToastily();
  const [picture, setPicture] = useState();

  const onSubmit = async (data) => {
    try {
      await triggerCreateOrganization({ ...data, image: picture });
      actionsToast.setSuccess("Organização criada com sucesso!");
    } catch (err) {
      console.error(err);
      actionsToast.setError(
        err.message ||
          "Não foi possível realizar a criação de uma nova organização. Tente novamente mais tarde!"
      );
    }
  };

  const getPicture = (value) => {
    setPicture(value);
  };

  const props = {
    fields: organizationFields,
    validationSchema,
    onSubmit,
    btnSubmit: {
      text: "Criar Organização"
    },
    header: {
      title: "Organização",
      subtitle: "Crie uma nova organização"
    },
    rightCardSlot: (
      <ProfileCard {...{ getPicture, avatar: { readonly: false } }} />
    )
  };

  return (
    <>
      <Toastily {...toast} />
      <CreateForm {...props} />
    </>
  );
};

export default OrganizationNew;
