import {
  DAY_OPTIONS,
  FI_TERMS_OPTIONS,
  GRACE_PERIOD_OPTIONS,
  ITBI_OPTIONS,
  LEGAL_PERSON_OPTIONS,
  MONTH_OPTION,
  TABLE_OPTIONS
} from "../../../constants";

const fields = [
  {
    id: "propertyValue",
    name: "propertyValue",
    label: "Valor do Imóvel:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "gracePeriod",
    name: "gracePeriod",
    label: "Carência:",
    type: "select",
    options: [...GRACE_PERIOD_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  },
  {
    id: "entranceValue",
    name: "entranceValue",
    label: "Valor de Entrada:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "terms",
    name: "terms",
    label: "Prazo de Pagamento:",
    type: "select",
    options: [...FI_TERMS_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  },
  {
    id: "loanValue",
    name: "loanValue",
    label: "Valor a Financiar:",
    type: "money",
    defaultValue: "0",
    isDisabled: true,
    allowNegative: true,
    inputClassLabel: ["bold"]
  },
  {
    id: "monthlyIncome",
    name: "monthlyIncome",
    label: "Renda mensal:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "dayDue",
    name: "dayDue",
    label: "Dia do pagamento:",
    type: "select",
    options: [...DAY_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  },
  {
    id: "legalPerson",
    name: "legalPerson",
    label: "Tomador do Empréstimo:",
    type: "select",
    options: [...LEGAL_PERSON_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  },
  {
    id: "itbi",
    name: "itbi",
    label: "ITBI:",
    type: "select",
    options: [...ITBI_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  },
  {
    id: "table",
    name: "table",
    label: "Tabela:",
    type: "select",
    options: [...TABLE_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  }
];

export { fields };
