import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormWizard from "../../Form/FormWizard/FormWizard";
import LoadingIndicator from "../../LoadingIndicator";
import ShowRecords from "../../ShowRecords";
import FormCard from "../Cards/FormCard/FormCard";
import FormPage from "../FormPage";

const EditForm = ({
  fields,
  validationSchema,
  onSubmit,
  onError,
  btnSubmit,
  header,
  children,
  readonly=true
}) => {
  const [isReadonly, setIsReadonly] = useState(readonly);

  const methods = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const { handleSubmit, formState } = methods;

  const BtnToggleForm = () => (
    <Button
      className="text-white"
      onClick={() => setIsReadonly((bool) => !bool)}
    >
      {isReadonly ? "Editar" : "Voltar"}
    </Button>
  );

  return (
    <FormPage {...{ header }}>
      {formState.isSubmitting && <LoadingIndicator />}
      <Col>
        <FormCard header={<BtnToggleForm />}>
          {isReadonly ? (
            <ShowRecords {...{ fields }} />
          ) : (
            <FormProvider {...methods}>
              <form
                style={{ width: "100%" }}
                onSubmit={handleSubmit((e) => {
                  onSubmit(e);
                  setIsReadonly(true);
                }, onError)}
              >
                <div className="form-records">
                  <FormWizard
                    {...{ fields }}
                    inputClass="form-records-fields"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button className="text-white" type="submit">
                    {btnSubmit?.text}
                  </Button>
                </div>
              </form>
            </FormProvider>
          )}
        </FormCard>
      </Col>
      {children}
    </FormPage>
  );
};

export default EditForm;
