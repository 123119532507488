import {
  validateCompromisedIncome,
  validateLTV,
  ValidationError
} from "App/helpers/simulations/validationSimulation";
import { objectCamelToSnake } from "App/helpers/utils";
import { ApiPortal } from "App/services/api/ApiPortal";

export const saveSimulation = async (values, toast, cb) => {
  try {
    const api = new ApiPortal();
    const data = objectCamelToSnake(values);
    const flow = await api.calculateFlowFI({ ...data, use_new_tac_calc: true  });

    validate({
      formValues: data,
      calculatedData: flow,
      toast
    });

    cb();
  } catch (error) {
    if (!(error instanceof ValidationError)) {
      console.log(
        `Error when trying to calculate the simulation ${JSON.stringify(error)}`
      );
      toast.setError("Tente novamente mais tarde!");
    }
  }
};

const validate = ({ formValues, calculatedData, toast }) => {
  validateCompromisedIncome(
    calculatedData.installment,
    formValues["monthly_income"],
    toast
  );
  validateLTV(calculatedData.ltv, toast);
};
