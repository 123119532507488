import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useMember } from "App/components/Providers/MemberProvider";
import { useToastily } from "../../../../components/Toastily/useToastily";
import triggerSendInvite from "./components/member";
import { createMember } from "../../../../services/MemberService";
import {
  generateAvatarFileName,
  uploadAvatar
} from "../../../../helpers/storage/uploadAvatar";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OrganizationSelect from "./components/OrganizationSelect";
import {
  Avatar,
  Document,
  Email,
  Phone,
  TextInput
} from "App/components/Form/FormInputs";
import Btn from "App/components/Btn";
import { validationSchema } from "./validations";
import CognitoInput from "./components/CognitoInput";
import OrganizationSelectInput from "../Show/OrganizationSelectInput";

const MemberForm = ({
  user,
  organization,
  currentValue,
  setCurrentValueState,
  cognitoGroups,
  isSubmitting
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      member: {
        name: "",
        email: "",
        documentNumber: "",
        phone: ""
      }
    }
  });
  const { handleSubmit } = methods;

  const { member: currentMember } = useMember();
  const { actions: actionsToast } = useToastily();
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    const { member, organization } = formData;
    try {
      if (member.avatar) {
        const fileName = generateAvatarFileName(member.avatar);
        const { key: avatarPath } = await uploadAvatar(fileName, member.avatar);
        member.avatar = avatarPath;
      }
      console.log({ member });
      const createdMember = await createMember({
        ...member
      });

      await triggerSendInvite({
        host: currentMember,
        guest: { ...createdMember },
        organization
      });

      actionsToast.setSuccess("Usuário criado com sucesso!");
      navigate(`/private/members/${createdMember.id}`);
    } catch (error) {
      actionsToast.setError(
        `Não foi possível atualizar os dados. Tente novamente mais tarde. ${error}`
      );
      console.log({ error });
    }
  };

  const isPontteUserWithOrganization =
    [...cognitoGroups].includes("Pontte_Users") && organization;
const selectOrg = ()=>{
  if (!isPontteUserWithOrganization){
    methods.setValue('member.organizationId', currentMember.organizationId)
  }

  return(
      <>
        {isPontteUserWithOrganization ? (
            <OrganizationSelect
                inputClass=""
                name="organization"
                {...{ currentValue, setCurrentValueState }}
                cognitoGroups={cognitoGroups}
                organization={organization}
            />
        ) : (
            <OrganizationSelectInput member={currentMember} />
        )}
      </>
  )
}
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col className="form-records d-flex">
              <Row className="d-flex justify-content-lg-center">
                <Col className="col-lg-8">
                  {selectOrg()}
                  <Col>
                    <Row>
                      <CognitoInput
                        {...{
                          hostUser: user,
                          methods,
                          name: "member.authType"
                        }}
                      />
                    </Row>
                  </Col>
                  <Row>
                    <Col>
                      <TextInput
                        id="name"
                        name="member.name"
                        label="Nome:*"
                        type="short_text"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                      <Email
                        id="email"
                        name="member.email"
                        label="Email:*"
                        type="email"
                        inputClassLabel={["bold", "col-lg-12"]}
                        {...methods}
                      />
                    </Col>
                    <Col>
                      <Document
                        id="documentNumber"
                        name="member.documentNumber"
                        label="CPF:*"
                        type="cpfDocument"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                      <Phone
                        id="phone"
                        name="member.phone"
                        label="Telefone:"
                        type="phone"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="col-lg-4 d-flex align-items-center"
                  style={{ flex: 1 }}
                >
                  <Avatar
                    id="avatar"
                    name="member.avatar"
                    label=""
                    type="avatar"
                    inputClassLabel={["bold"]}
                    classNames={["large"]}
                    {...methods}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={"mt-5"}>
            <div className="d-flex justify-content-start mb-4">
              <Btn
                title="Salvar"
                classNames={["primary", "pill"]}
                loading={isSubmitting}
              />
            </div>
          </Row>
        </Container>
      </form>
    </FormProvider>
  );
};

export default MemberForm;
