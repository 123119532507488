import { useEffect, useState } from "react";
import { buildItemPagination } from "../../helpers/utils";

const PaginatedContainer = ({ items, numberPerPage = 5, children }) => {
  const [paginatedList, setPaginatedList] = useState([[]]);
  const [currentPage, setCurrantPage] = useState(0);
  const [inputBack, setInputBack] = useState(1);

  useEffect(() => {
    if (items) {
      setPaginatedList(buildItemPagination(items, numberPerPage));
    }
  }, [items, numberPerPage]);

  const onNextPage = () => {
    if (currentPage < paginatedList.length - 1) {
      setCurrantPage((cp) => {
        setInputBack(cp + 2);
        return cp + 1;
      });
    }
  };

  const onBackPage = () => {
    if (currentPage > 0) {
      setCurrantPage((cp) => {
        setInputBack(cp);
        return cp - 1;
      });
    }
  };

  const setPage = (page) => {
    if (page.trim() === "") {
      setInputBack(page);
      return;
    }

    const isInTheRange = page > 0 && page <= paginatedList.length;

    if (isInTheRange) {
      setInputBack(parseInt(page));
      setCurrantPage(parseInt(page) - 1);
    }
  };

  return children({
    list: paginatedList[currentPage],
    setPage,
    onNextPage,
    onBackPage,
    currentPage: inputBack,
    totalPages: paginatedList.length
  });
};

export default PaginatedContainer;
