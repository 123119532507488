import { sendEmailRDStation } from "App/helpers/plugaFetcher";
import { getGuestAuthType } from "App/helpers/utils";
import { getMemberById } from "App/services/MemberService";
import { getOrganizationById } from "App/services/OrganizationService";
import { saveUserInvite } from "App/services/UserInviteServices";

export const triggerGetMember = async (id, cb) => {
  const member = await getMemberById(id);
  cb(member);
};

export const triggerSendInvite = async ({ user, member }, guestMember) => {
  const hostOrganizationID = member.organizationId;
  const hostMemberId = member.id;
  const hostCognitoGroups = user.cognitoGroups;

  if (!hostOrganizationID) throw new Error("OrganizationId is Not Found");

  const guestAuthType = getGuestAuthType(hostCognitoGroups, guestMember.email); // PontteUser or null

  const { name: partnerOrgName } = await getOrganizationById(
    hostOrganizationID
  );

  const { id: userInviteId } = await saveUserInvite(
    hostOrganizationID,
    hostMemberId,
    guestMember.id,
    guestAuthType
  );

  sendEmailRDStation({
    userInviteId,
    email: guestMember.email,
    partnerOrgName,
    partnerOrgId: hostOrganizationID
  });
};
