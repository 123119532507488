const organizationFields = [
  {
    id: "name",
    name: "name",
    label: "Nome: *",
    type: "short_text",
    placeholder: "Digite",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "documentNumber",
    name: "documentNumber",
    label: "CNPJ: *",
    type: "cnpjDocument",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "legalName",
    name: "legalName",
    label: "Razão Social: *",
    type: "short_text",
    placeholder: "Digite",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "phone",
    name: "phone",
    label: "Telefone: ",
    type: "phone",
    inputClassLabel: ["form-field-label"]
  }
];

export { organizationFields };
