
  const showColumnsLead = [
    {"label": "Cliente", "key": "name"},
    {"label": "Data de cadastro", "key": "createdAt"},
    {"label": "Valor da operação", "key": "value"},
    {"label": "Status", "key": "Phase"},
  ];

  const leadsStatusColumn = [
  {
    "value": "Pendente",
    "label": "Pendente"
  },
  {
    "value": "Negado",
    "label": "Recusado"
  },
  {
    "value": "Concluído",
    "label": "Finalizado"
  }
  ]

  export {
    showColumnsLead,
    leadsStatusColumn
  }