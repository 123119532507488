import { DIRECTIONS, SectionedForm } from "App/components/Form/SectionedForm";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { getMemberByEmail, saveMember } from "App/services/MemberService";
import {
  getOrganizationByDocumentNumber,
  saveOnPipefy,
  saveOrganization,
  updateOrganization
} from "App/services/OrganizationService";
import { sendEmailRDStation } from "App/helpers/plugaFetcher";
import { getGuestAuthType } from "App/helpers/utils";
import { validationSchema } from "./validations";
import { organizationInviteFields } from "./fields";
import { DataStore } from "@aws-amplify/datastore";
import { UserInvite as UserInviteModel } from "../../../../models";

const saveUserInvite = (organizationId, hostId, guestId, authType) =>
  DataStore.save(
    new UserInviteModel({
      guestId,
      hostId,
      organizationId,
      status: "pending",
      authType: "Admin"
    })
  );

const InviteOrganization = ({ hostMember }) => {
  const { actions: actionsToast, toast } = useToastily();

  const onSubmit = async ({
    documentNumber,
    name,
    email,
    legalName,
    phone,
    memberName
  }) => {
    try {
      let guestOrganizaton = await getOrganizationByDocumentNumber(
        documentNumber
      );
      if (!guestOrganizaton) {
        guestOrganizaton = await saveOrganization({
          email,
          documentNumber,
          name,
          legalName,
          phone
        });
      }
      const hostMemberId = hostMember.member.id;
      const hostCognitoGroups = hostMember.user.cognitoGroups;
      const guestAuthType = getGuestAuthType(hostCognitoGroups, email);
      let guestMember = await getMemberByEmail(email, guestOrganizaton.id);
      if (!guestMember) {
        guestMember = await saveMember(email, guestOrganizaton.id, memberName);
      }
      const pipefyId = await saveOnPipefy({
        organization: guestOrganizaton,
        member: guestMember
      });
      const guestOrganizationUpdated = await updateOrganization(
        guestOrganizaton.id,
        {
          ...guestOrganizaton,
          pipefyId
        }
      );

      const { id: userInviteId } = await saveUserInvite(
        guestOrganizaton.id,
        hostMemberId,
        guestMember.id,
        guestAuthType
      );

      sendEmailRDStation({
        userInviteId,
        email: email,
        name,
        partnerOrgId: guestOrganizaton.id
      });

      actionsToast.setSuccess("Convite enviado com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possivel enviar o convite. Tente novamente mais tarde."
      );
      console.log({ error });
    }
  };

  return (
    <>
      <Toastily {...toast} />
      <SectionedForm
        btnTitle="Enviar Convite"
        direction={DIRECTIONS.VERTICAL}
        sections={[
          {
            fields: organizationInviteFields,
            inputClass: "form-records-fields"
          }
        ]}
        onSubmit={onSubmit}
        validation={validationSchema}
      />
    </>
  );
};

export default InviteOrganization;
