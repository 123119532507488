import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles.scss";

const BtnDropDown = ({ classNames, title, list }) => (
  <Dropdown className={"w-100"}>
    <Dropdown.Toggle variant="primary" id="dropdown-basic" size={"lg"} style={{width: '100%'}} className={"button button__primary btn-group-lg"}>
      {title}
    </Dropdown.Toggle>

    <Dropdown.Menu className="w-100">
      {list?.map(({ text, action }) => (
        <Dropdown.Item href={action}>{text}</Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default BtnDropDown;
