import { useMember } from "App/components/Providers/MemberProvider";
import { Card, Col, Row } from "react-bootstrap";
import { SimplePage } from "App/components/Pages/SimplePage";
import LoadingIndicator from "App/components/LoadingIndicator";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { MemberProfile as MemberProfileContainer } from "App/containers/Members/Profile"
import iconePageUser from "images/icon-page-profile.svg";
import "./styles.scss";
import { getOrganizationById } from "App/services/OrganizationService";

const MemberProfilePage = () => {
  const { actions: actionsToast, toast } = useToastily();
  const { member, organizationId } = useMember();

  return (
    <SimplePage title="Minha conta" large={true} icon={iconePageUser}>
      <Toastily {...toast} />
      {!member ? (
        <LoadingIndicator />
      ) : (
        <Card className="card-member-profile">
          <Row className="card-member-profile-header">
            <Col className="card-member-profile-header title">
              <h1 className="L-title">Suas informações</h1>
            </Col>
          </Row>
          <hr />
          <Row className="card-member-profile-body">
            <MemberProfileContainer member={member} />
          </Row>
        </Card>
      )}
    </SimplePage>
  );
};

export default MemberProfilePage;
