import { useState, useEffect } from "react"
import PaginatedList from "../../components/Lists/PaginatedList"
import { FilterableSearchContext } from "../search/Context"
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss"

const SearchListSimple = ({list, headerFilter, showColumns, formatCell, onItemClick}) => {
  const [filteredList, setFilteredList] = useState([])
  useEffect( () => {
    setFilteredList(list)
  }, [list])

  return (
    <>
      <FilterableSearchContext.Provider value={{result: filteredList, setResult: setFilteredList}} >
        <FilterableSearchContext.Consumer>{ (value) => {
          const props = {
            items: value.result,
            header: showColumns,
            formatCell: formatCell,
            childTopBar: headerFilter,
            showColumns: showColumns,
            onItemClick: onItemClick
          };
          return <>
            <PaginatedList {...{...props}}  />
          </>
        } }</FilterableSearchContext.Consumer>
      </FilterableSearchContext.Provider>
    </>
  )
}

export default SearchListSimple
