import * as React from "react";
import "../Layout/style.scss";
import LogoPontte from "../../../images/logo.svg";
import "./style.scss";

const Footer = () => (
  <div className="footer-container">
    <span className="title"> Powered by </span>
    <img className="logo" src={LogoPontte} alt="Logo Pontte" />
  </div>
);

export default Footer; 
