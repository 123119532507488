import React, {useEffect, useState} from 'react';
import {getOrganizationById} from "App/services/OrganizationService";
import OrganizationSelect from "./OrganizationSelect";

const MyComponent = ({member}) => {
    const memberId = member.id
    const [organization, setOrganization] = useState(null);
    const [currentValue, setCurrentValueState] = useState(null);

    useEffect(async () => {
        if (memberId) {
            try {
                const memberOrganization = await getOrganizationById(
                    member.organizationId
                );
                setOrganization(memberOrganization);

            } catch (error) {
                console.log({ error });
            }
        }
    }, [memberId]);

    return (
                <OrganizationSelect
                    id="organization"
                    name="organization"
                    label="Organização: *"
                    type="select"
                    placeholder="Organização"
                    defaultValue=""
                    options={[{ label: "Selecione...", value: "" }]}
                    inputClassLabel={["bold"]}
                    isDisabled={true}
                    {...{ currentValue, setCurrentValueState }}
                    inputClass=""
                    organization={organization}
                />
    );
};

export default MyComponent;
