import React from "react";
import { useNavigate } from "react-router-dom";
import { VideoPlayer } from "../../../components/VideoPlayer"
import { Card, Container, Row } from "react-bootstrap";
import video_central_do_parceiro from "videos/central_do_parceiro.mp4" 
import Page from "../../../components/Page/Page";
import "./Help.scss";

const Help = () => {
  const navigate = useNavigate();
  const skipTutorial = () => {
    navigate("/private/dashboard")
  }
  const srcFile =
    "https://portal-parceiro-frontend-storage145250-test.s3.amazonaws.com/public/central_do_parceiro.mp4";

  return (
    <Page noSpaces={true} medium={false} large={true}>
      <div>
        <div className="d-flex justify-content-center">
          <span className="card-title text-center font-weight-bold text-primary text-purple title Help__title">
            Conheça a sua Central do Parceiro
          </span>
        </div>
        <div className="d-flex py-2 mb-4 justify-content-center">
          <span className="card-text header-text Help__subtitle">
            Indique a melhor solução de crédito para o seu cliente e ainda ganhe por isso!
          </span>
        </div>
      </div>
      <div className="d-lg-flex w-100 justify-content-center align-items-center VideoPlayerContainer">
        <VideoPlayer src={srcFile} buttonTitle="Pular Tutorial" buttonAction={skipTutorial} />
      </div>
    </Page>
  );
};

export default Help;
