import { Storage } from "aws-amplify";

const getAvatarUrl = (name) => {
  const URL = Storage.get(name);
  return URL;
};

const uploadAvatar = (name, file) => {
  const URL = Storage.put(`avatar/${name}`, file, {
    contentType: file.contentType
  });
  
  return URL;
};

const generateAvatarFileName = (file) => {
  return `${crypto.randomUUID()}.${file.name.split(".").splice(-1)}`
}

export { getAvatarUrl, uploadAvatar, generateAvatarFileName };
