import { Link } from "react-router-dom";
import { NavList } from "./NavList/NavList";
import LeadProvider from "../Providers/LeadsProvider/LeadsProvider";
import "./NavList/styles.scss";
import Logo from "../Logo/Logo";
import Button from "../Btn/Btn"
import UserMenu from "./UserMenu";

const BaseHeader = ({navLinks, children, toogleOffCanvas, loginButton, items, userData, transparent = false }) => {
  let classNames = ['header']
  if (transparent) {
    classNames.push('header--transparent')
  }
  return (
    <div className={classNames.join(" ")}>
      <div className="header-wrapper">
        <Link to="/" className="header__logo" >
          <Logo color="#5c3b6b" />
        </Link>

        {navLinks?.length > 0 && (
          <div className="header__menu d-none d-lg-flex">
            <LeadProvider>{ ({leads}) => <NavList userLeads={leads} navLinks={navLinks} />}</LeadProvider>
          </div>
        )}
        <div className="d-flex d-align-center">
          {children}
          {items && (
            <UserMenu user={userData} userMenu={items}/>
          )}
          {loginButton && 
            (<Button 
              title={loginButton.title}
              classNames={loginButton.classNames}
              onClick={ loginButton.onClick}
            />)
          }
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-lg-none rounded-circle mr-3"
            onClick={() => toogleOffCanvas()}
          >
            <i className="fa fa-bars" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BaseHeader;
