import { configSimulation as config } from "./configSimulationHE";

class ValidationError {
  constructor(message) {
    this.message = message;
    this.type = "VALIDATION_ERROR";
  }
}
const {
  common: {
    compromisedIncome: {
      percentage: {
        max: {
          value: maxCompromisedIncomeValue,
          label: maxCompromisedIncomeLabel
        }
      }
    }
  }
} = config;
const validateCompromisedIncome = (installments, monthlyIncome, toast) => {
  const getFirstInstallmentWithValue = (i) => i.find((i) => i.installment > 0);

  const { installment } = getFirstInstallmentWithValue(installments);

  const compromisedIncome = installment / monthlyIncome;

  const isValid = compromisedIncome <= maxCompromisedIncomeValue;

  if (!isValid) {
    const msg = `A renda é insuficiente. O comprometimento de renda deve ser menor que ${maxCompromisedIncomeLabel}`;
    toast.setError(msg, "Dados Inválidos");
    throw new ValidationError(msg);
  }
};

const validateLTV = (ltv, toast) => {
  const isValid = ltv <= config.common.ltv.min.value;

  if (!isValid) {
    const msg = `LTV deve ser inferior a ${config.common.ltv.min.label}`;
    toast.setError(msg, "Dados Inválidos");
    throw new ValidationError(msg);
  }
};

const validateMaxLoanValue = (loanValue, propertyValue) => {
  return loanValue <= propertyValue * config.common.loan.percentage.max.value;
};

export {
  ValidationError,
  validateCompromisedIncome,
  validateLTV,
  validateMaxLoanValue
};
