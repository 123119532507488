import { configSimulation as config } from "App/helpers/simulations/configSimulationFI";
import * as yup from "yup";

const validateMaxLoanValue = (loanValue, propertyValue) => {
  return loanValue <= propertyValue * config.financing.ltv.max.value;
};

const validationSchema = yup.object().shape({
  propertyValue: yup
    .number()
    .required()
    .typeError("Campo obrigatório")
    .min(
      config.common.property.min.value,
      `O valor de um imóvel é a partir de ${config.common.property.min.label}`
    ),
  monthlyIncome: yup.number().required().typeError("Campo obrigatório"),
  entranceValue: yup.number().required().typeError("Campo obrigatório"),
  terms: yup.number().required().typeError("Campo obrigatório"),
  loanValue: yup
    .number()
    .required()
    .min(
      config.common.loan.min.value,
      `O valor mínimo de empréstimo é de ${config.common.loan.min.label}`
    )
    .max(
      config.common.loan.max.value,
      `O valor máximo de empréstimo é de ${config.common.loan.max.label}`
    )
    .test(
      "financed-based-on-the-property-value",
      `O valor não pode ser maior que ${config.financing.ltv.max.label} do valor do imóvel`,
      (value, ctx) => validateMaxLoanValue(value, ctx.parent.propertyValue)
    ),
  dayDue: yup.number().required().typeError("Campo obrigatório"),
  gracePeriod: yup.string().required("Campo obrigatório"),
  table: yup.string().required("Campo obrigatório"),
  itbi: yup.string().required("Campo obrigatório"),
  legalPerson: yup.string().required("Campo obrigatório")
});

export { validationSchema };
