import { useEffect, useState } from "react";
import { useMember } from "App/components/Providers/MemberProvider";
import { Card, Col, Row } from "react-bootstrap";
import { SimplePage } from "App/components/Pages/SimplePage";
import LoadingIndicator from "App/components/LoadingIndicator";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { OrganizationProfile as OrganizationProfileContainer } from "App/containers/Organizations/Profile"
import iconePageUser from "images/icone-page-user.svg";
import "./styles.scss";
import { getOrganizationById } from "App/services/OrganizationService";

const OrganizationProfilePage = () => {
  const [organization, setOrganization] = useState();
  const { actions: actionsToast, toast } = useToastily();
  const { member, organizationId } = useMember();

  useEffect( async () => {
    const memberOrganization = await getOrganizationById(organizationId)
    setOrganization(memberOrganization)
  }, [organizationId])

  return (
    <SimplePage title="Configurações" large={true} icon={iconePageUser}>
      <Toastily {...toast} />
      {!member ? (
        <LoadingIndicator />
      ) : (
        <Card className="card-organization-profile">
          <Row className="card-organization-profile-header">
            <Col className="card-organization-profile-header title">
              <h1 className="L-title">Informações da sua empresa</h1>
            </Col>
          </Row>
          <hr />
          <Row className="card-organization-profile-body">
            <OrganizationProfileContainer member={member} organization={organization}/>
          </Row>
        </Card>
      )}
    </SimplePage>
  );
};

export default OrganizationProfilePage;
