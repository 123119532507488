import React from "react";
import arrowPurple from "../../../../../images/angle_r_circle_purple.svg";
import arrowGray from "../../../../../images/angle_r_circle_desab.svg";
import { useMediaQuery } from "react-responsive";

const TableHeader = ({ head, tableNavButton, tablePage, tableTitle }) => {
  const handleClick = (type) => {
    const isNotToPassData =
      (type === "forward" && tablePage === lastPage) ||
      (type === "back" && tablePage === firstPage);

    if (isNotToPassData) return;

    tableNavButton(type);
  };

  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1000 });
  const lastPage = isTabletOrMobileDevice ? 4 : 2;
  const firstPage = 0;

  return (
    <>
      <section className="row filter-block ">
        <div className="container">
          {tableTitle && <div className="filter__title">{tableTitle}</div>}
          <div className="filter__drop-down"></div>
        </div>
      </section>
      <section className="dash-installments-table-head">
        <div className="head-block">
          <div className="head-container">
            <div className=" left">
              <button className="nav-btn" onClick={() => handleClick("back")}>
                <img
                  src={tablePage === firstPage ? arrowGray : arrowPurple}
                  className="arrow left"
                  alt="dash installments back"
                />
              </button>
            </div>
            <div className="box-container center">
              <div className="grid-container">
                {head.map((title, index) => (
                  <div key={index} className={"item"}>
                    <span>{title}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className=" right">
              <button
                className="nav-btn"
                onClick={() => handleClick("forward")}
              >
                <img
                  src={tablePage === lastPage ? arrowGray : arrowPurple}
                  className="arrow right"
                  alt="dash installments next"
                />
              </button>
            </div>
          </div>
          <div className="border-bottom" />
        </div>
      </section>
    </>
  );
};

export default TableHeader;
