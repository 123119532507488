import LoadingIndicator from "../../LoadingIndicator";
import Header from "./Header";
import List from "./List";

import "./styles.scss";

const InstallmentsTable = ({
  header,
  columns,
  body,
  tableTitle,
  footerChild,
  loading
}) => (
  <>
    <div className="installments-table">
      <div className="installments-table-wrapper limit-grid">
        <section className="installments-table-detail">
          <Header items={header} />
        </section>
      </div>
    </div>
    <section className="dash-installments-wrapper limit-grid">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <List
          items={body}
          header={columns}
          tableTitle={tableTitle}
          footerChild={footerChild}
        />
      )}
    </section>
  </>
);

export default InstallmentsTable;
