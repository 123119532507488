import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../Auth";
import LoadingIndicator from "../LoadingIndicator";
import { PrivateLayout, PublicLayout } from "./Layouts";

import "./style.scss";

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const isPrivate =
    location.pathname.split("/")[1] === "private" ||
    location.pathname.split("/")[1] === "internal";

  const auth = useAuth();

  useEffect(() => {
    auth.getCurrentSession().finally(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <LoadingIndicator isFull={true} />
  ) : !isPrivate ? (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  ) : (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

export default Layout;
