import React from "react";
import LargeIframe from "../../../../components/Iframe/LargeIframe";

const HelpContent = () => (
  <LargeIframe
    url={"https://www.sejaparceiro.pontte.com.br/cópia-material-design"}
  />
);

export default HelpContent;
