import "./style.scss";
import { DropDown } from "App/components/DropDown";
import { useAuth } from 'App/components/Auth/useAuth'
import { Avatar } from "../Form/FormInputs";

const UserMenu = ({userMenu}) => {
  const { user, user: {Member} } = useAuth();
  const getFormatedUserName = (user) => {
    return user?.name?.split(" ")[0]
  }

  return (
    <div className="UserMenu">
      <DropDown 
        wrapperClass="UserMenu" 
        className="nav-link"
        items={userMenu}
        title={(
          <>
          <span className="UserMenu__greeting d-none d-lg-inline">
            Olá, {getFormatedUserName(user)}!
          </span>
          <Avatar
            readonly
            classNames={['tiny']}
            alt="profile img"
            defaultValue={Member?.avatar}
          />
        </>
        )}
      />        
    </div>
  );
};

export default UserMenu;
