const { gql } = require('graphql-request');

const createTableRecord = gql`
    mutation CreateTableRecord($tableId: ID!, $fieldsAttributes: [FieldValueInput]) {
      createTableRecord(input: { table_id: $tableId, fields_attributes: $fieldsAttributes }) {
        table_record {
          id
        }
      }
    }
  `;
export { createTableRecord };
