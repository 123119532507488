/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import PaginatedContainer from "../../../../containers/PaginatedContainer/PaginatedContainer";
import { useMediaQuery } from "react-responsive";

import "./styles.scss";

import { FooterPaginated } from "../../ListItems";

const List = ({ items, header, tableTitle, footerChild }) => {
  const table = {
    head: [...header.map(({ value }) => value)],
    body: [...header.map(({ key }) => key)]
  };

  let { head: tHead, body: tBody } = table;

  const handleMediaQueryChange = () => {
    if (isTabletOrMobileDevice) {
      tHead.splice(0, 1);
      tBody.splice(0, 1);
    }
    setState((prevState) => ({
      ...prevState,
      body: table.body.slice(0, tableSize),
      head: table.head.slice(0, tableSize)
    }));
  };

  const isTabletOrMobileDevice = useMediaQuery(
    { maxDeviceWidth: 639 },
    undefined,
    handleMediaQueryChange
  );

  if (isTabletOrMobileDevice) {
    tHead.splice(0, 1);
    tBody.splice(0, 1);
  }

  let tableSize = isTabletOrMobileDevice ? 3 : 7;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      body: tBody.slice(0, tableSize),
      head: tHead.slice(0, tableSize)
    }));
  }, [items]);

  const [state, setState] = useState({
    scrollIndex: 0,
    tablePage: 0,
    body: tBody.slice(0, tableSize),
    head: tHead.slice(0, tableSize)
  });

  const tableNavButton = (type) => {
    const { tablePage } = state;
    const page = type === "forward" ? tablePage + 1 : tablePage - 1;
    const move = type === "forward" ? 2 : -1;
    const scrollIndex = tablePage + move;
    const head = table.head.slice(scrollIndex, scrollIndex + tableSize);
    const body = table.body.slice(scrollIndex, scrollIndex + tableSize);
    setState((prevState) => ({
      ...prevState,
      head,
      body,
      tablePage: page
    }));
  };

  const { head, body, tablePage } = state;
  const total = items.length;

  return (
    <PaginatedContainer items={items} numberPerPage={13}>
      {({ list, ...footer }) => (
        <div className="dash-installments-tab active">
          <div className="dash-installments-table-container">
            {items && (
              <div className="dash-installments-table">
                <TableHeader
                  {...{ tablePage, head, tableNavButton, tableTitle }}
                />
                <TableBody {...{ body, items: list, total }} />
              </div>
            )}
            <FooterPaginated {...footer}>{footerChild}</FooterPaginated>
          </div>
        </div>
      )}
    </PaginatedContainer>
  );
};

export default List;
