import { useState } from "react";

export const useToastily = () => {
  const initialState = {
    error: false,
    success: false,
    message: null,
    title: null,
    variant: null,
    show: false
  };

  const [toast, setToast] = useState(initialState);

  const actions = {
    setError: (message, title) =>
      setToast({
        error: true,
        success: false,
        message: message,
        title: title || "Erro Interno",
        variant: "danger",
        fa: "exclamation",
        show: true,
        setToast
      }),
    setSuccess: (message, title) =>
      setToast({
        error: false,
        success: true,
        message: message,
        title: title || "Sucesso!",
        variant: "primary",
        fa: "check",
        show: true,
        setToast
      })
  };

  return { toast, actions };
};
