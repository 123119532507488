import arrowBack from "../../../../../images/arrow-back.svg";
import arrowNext from "../../../../../images/arrow-next.svg";
import { Footer } from "..";

import "./styles.scss";

const FooterPaginated = ({
  currentPage,
  setPage,
  totalPages,
  onNextPage,
  onBackPage,
  children
}) => (
  <Footer>
    <div className="children">{children}</div>
    <div className="footer-info">
      <div className="footer-info qty">Exibindo página</div>
      <div className="footer-info">
        <div className="backbox">
          <div className="arrow-back" onClick={onBackPage}>
            <img src={arrowBack} alt="arrow-back" />
          </div>
          <div className="current-page">
            <input
              type="text"
              value={currentPage}
              onChange={({ currentTarget }) => setPage(currentTarget.value)}
              placeholder={"0"}
            />
          </div>
        </div>
      </div>
      <div className="footer-info info">
        <span> de </span> <strong> {totalPages} </strong>
      </div>
      <div className="footer-info">
        <div className="nextbox">
          <div className="arrow-next" onClick={onNextPage}>
            <img src={arrowNext} alt="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  </Footer>
);

export default FooterPaginated;
