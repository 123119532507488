import { interest_rate } from "../../../../../services/Constants";
import {
  DAY_OPTIONS,
  GRACE_PERIOD_OPTIONS,
  MONTH_OPTION,
  TERMS_OPTIONS
} from "../../../constants";

export const TABLE_OPTIONS = [{
  label: "Selecione...", value: ""
}, {
  label: "SAC", value: "SAC"
}, {
  label: "PRICE", value: "PRICE"
}];

const fields = [
  {
    id: "loanValue",
    name: "loanValue",
    label: "Valor do empréstimo:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "terms",
    name: "terms",
    label: "Prazo de pagamento:",
    type: "select",
    options: TERMS_OPTIONS,
    classNames: ["mb-3 form-field"],
    inputClassLabel: ["bold"]
  },
  {
    id: "monthlyIncome",
    name: "monthlyIncome",
    label: "Renda mensal:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "propertyValue",
    name: "propertyValue",
    label: "Valor do imóvel:",
    type: "money",
    inputClassLabel: ["bold"]
  },
  {
    id: "gracePeriod",
    name: "gracePeriod",
    label: "Carência:",
    type: "select",
    options: GRACE_PERIOD_OPTIONS,
    classNames: ["mb-3 form-field"],
    inputClassLabel: ["bold"]
  },
  {
    id: "dayDue",
    name: "dayDue",
    label: "Dia do pagamento:",
    type: "select",
    options: DAY_OPTIONS,
    classNames: ["mb-3 form-field"],
    inputClassLabel: ["bold"]
  },
  {
    id: "interestRate",
    name: "interestRate",
    label: "Taxa:",
    type: "short_text",
    isDisabled: true,
    defaultValue: interest_rate,
    inputClassLabel: ["bold"]
  },
  {
    id: "table",
    name: "table",
    label: "Tabela:",
    type: "select",
    options: [...TABLE_OPTIONS],
    defaultValue: "",
    inputClassLabel: ["bold"]
  }
];


export { fields };
