import { useEffect, useState } from "react";
import { SectionedForm, DIRECTIONS } from "App/components/Form/SectionedForm";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { uploadAvatar, generateAvatarFileName } from "App/helpers/storage/uploadAvatar";
import { updateFieldsWithDefaultValues } from "App/helpers/forms/utils";
import { getOrganizationFields, getAvatarDefaultValue } from "App/helpers/forms/organization"
import { updateOrganization } from "App/services/OrganizationService";
import { validationSchema } from "./validations";
import { organizationAvatarFields, organizationFields, organizationContactFields, organizationWhiteLabelFields } from "./fields";
import { CopyToClipboard } from "App/components/CopyToClipboard";

const Profile = ({organization}) => {
  const [formSections, setFormSections] = useState()
  const { actions: actionsToast, toast } = useToastily();

  useEffect( async () => {
    if (organization) {
      try {
        //const copyToClipboard = <CopyToClipboard text="Endereço" />
        let defaultValues = getOrganizationFields(organization)
        let defaultAvatarValue = await getAvatarDefaultValue(organization.image)
        const organizationFieldsWithDefaultValues = updateFieldsWithDefaultValues(organizationFields, defaultValues)
        const organizationContactFieldsWithDefaultValues = updateFieldsWithDefaultValues(organizationContactFields, defaultValues)
        const avatarFieldWithDefaultValue = updateFieldsWithDefaultValues(organizationAvatarFields, defaultAvatarValue)
        setFormSections([
          { fields: avatarFieldWithDefaultValue, sectionClass: "form-avatar", inputClass: "" },
          { fields: organizationFieldsWithDefaultValues, inputClass: "form-records-fields" },
          { title: "Informações de contato", fields: organizationContactFieldsWithDefaultValues, inputClass: "form-records-fields" },
         // { title: "Link personalizado", subtitle: "Aqui está seu link personalizado da nossa simulação", children: copyToClipboard, sectionClass: "copy-to-clipboard-container", },
        ]);
      } catch (error) {
        console.log({error})
      }
    }
  }, [organization])

  const onSubmit = async (formData) => {
    if (formData.image) {
      const fileName = generateAvatarFileName(formData.image)
      const {key: avatarPath} = await uploadAvatar(fileName, formData.image)
      formData.image = avatarPath
    }
    try {
      await updateOrganization(organization.id, formData);
      actionsToast.setSuccess("Dados atualizados com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possivel atualizar os dados. Tente novamente mais tarde."
      );
      console.log({ error });
    }
  }

  return (
    <>
      <Toastily {...toast} />
      {formSections && (<SectionedForm direction={DIRECTIONS.VERTICAL} sections={formSections} onSubmit={onSubmit} validation={validationSchema}/>)}    
    </>
  )
};

export default Profile;
