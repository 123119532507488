import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const DropDown = ({ wrapperClass, className, title, items }) => (
  <Dropdown className={wrapperClass}>
    <Dropdown.Toggle className={className}>
      {title}
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {items?.map(({ title, route, onClick }) => (
        <Dropdown.Item href={route} onClick={onClick}>{title}</Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default DropDown;
