import React from "react";
import { SimplePage } from "../../../../components/Pages/SimplePage";
import iconePage from "../../../../../images/icone-page-3.svg";
import HE from "../../../../containers/Leads/HE";
import FI from "../../../../containers/Leads/FI";
import { OrganizationProvider } from "../../../../components/Providers/OrganizationProvider/OrganizationProvider";
import { ImageStick } from "../../../../components/ImageStick";
import illustration_3 from "../../../../../images/illustrations/illustration_3.svg";
import illustration_4 from "../../../../../images/illustrations/illustration_4.svg";
import illustration_5 from "../../../../../images/illustrations/illustration_5.svg";
import { useParams, useLocation } from "react-router-dom";
import { FIHEContextSelect } from "../../../../containers/ContextSelect";
import { months } from "App/helpers/formats"; 
import { useMember } from "App/components/Providers/MemberProvider";

const getSkipMonthInitialValueForHEForm = (value) => {
  if (months[value]) return months[value];
  return "Nenhum";
}

const getGracePeriodInitialValueForFIForm = (value) => {
  if (value === 1) return "1 mês";
  if (value > 1 && value <= 6) return `${value} meses`
  if (value === 0) return "Não tenho interesse"
  return "Não informado";
}

const getGracePeriodInitialValueForHEForm = (value) => {
  if (value === 1) return "1 mês";
  if (value > 1 && value <= 6) return `${value} meses`
  if (value === 0) return "Não tenho interesse"
  return "Nenhuma";
}

const getSimulationInitialValuesHE = (simulation) => {
  const initialValues = {
    member_id: simulation.memberId,
    renda_cliente: simulation.monthlyIncome,
    valor_do_im_vel: simulation.propertyValue,
    valor_mensal_total_do_contrato: simulation.loanValue,
    m_s_sem_pagar: getSkipMonthInitialValueForHEForm(simulation.skipMonth),
    n_mero_de_parcelas: simulation.term,
    car_ncia: getGracePeriodInitialValueForHEForm(simulation.gracePeriod), // Metodos para valores de HE
    tabela_amortiza_o: simulation.table
  }

  return initialValues; 
}

const getSimulationInitialValuesFI = (simulation) => {
  const initialValues = {
    member_id: simulation.memberId,
    renda_mensal: simulation.monthlyIncome,
    valor_do_im_vel: simulation.propertyValue,
    tipo_de_opera_o: simulation.legalPerson ? "Pessoa Jurídica" : "Pessoa Física",
    valor_a_financiar: simulation.loanValue,
    m_s_sem_paga: simulation.skipMonth,
    prazo_de_pagamento_de_interesse: `${simulation.term} meses`,
    tem_interesse_em_car_ncia: getGracePeriodInitialValueForFIForm(simulation.gracePeriod), // Metodo para valores de FI
    deseja_incluir_itbi_e_registro_no_financiamento: simulation.itbi ? "Sim" : "Não"
  }

  return initialValues; 
}

const CreateLead = () => {
  const { type = "HE" } = useParams();
  const location = useLocation();
  const simulation = location?.state?.simulation;
  const newType = type === ":type" ? "HE" : type;
  const { member: current_member } = useMember();
  return (
    <SimplePage
      title="Cadastro de Operações"
      large={true}
      icon={iconePage}
      description={
        "Preencha os dados do seu cliente e um dos nossos consultores entrará em contato com você."
      }
    >
      <OrganizationProvider>
        {({ state: organization }) => {
          return (
            <FIHEContextSelect type={newType}>
              {({ contextValue }) => {
                console.log({organization})
                let initialValues = { 
                  parceiro_1: organization.pipefyId,
                  parceiro: organization.pipefyId,
                  member_id: current_member.id,
                  from_portal_parceiro: true
                 };

                if (contextValue === "FI") {
                  initialValues["nome_da_empresa_que_est_indicando"] = organization.name;
                  initialValues["empresa_parceira"] = organization.name;

                    if (simulation) {
                      initialValues = {...initialValues, ...getSimulationInitialValuesFI(simulation)}
                    }
                  }

                if (contextValue === "HE") {
                  initialValues["nome_da_empresa"] = organization.name;
                  initialValues["nome_da_empresa_parceira"] = organization.name;
                  if (simulation) {
                    initialValues = {...initialValues, ...getSimulationInitialValuesHE(simulation)}
                  }
                }

                return contextValue === "HE" ? (
                  <HE initialValues={initialValues} />
                ) : (
                  <FI initialValues={initialValues} />
                );
              }}
            </FIHEContextSelect>
          );
        }}
      </OrganizationProvider>
      <ImageStick
        position={{ right: "0px", top: "9.18%" }}
        size={{ width: "27.64%" }}
        offset={{ right: "-15.05%" }}
        src={illustration_3}
      />
      <ImageStick
        position={{ left: "0px", top: "39.54%" }}
        size={{ width: "35.76%" }}
        offset={{ left: "-27.82%" }}
        src={illustration_4}
      />
      <ImageStick
        position={{ right: "0px", top: "62.96%" }}
        size={{ width: "34.55%" }}
        offset={{ right: "-32.82%" }}
        src={illustration_5}
      />
    </SimplePage>
  );
};

export default CreateLead;
