import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../../Auth";

export const MemberContext = createContext();

export const MemberProvider = ({ children }) => {
  const { user } = useAuth();

  const [member, setMember] = useState();
  const [organization, setOrganization] = useState();

  useEffect(() => {
    if (user.id) {
      const members = user.Member;

      setOrganization(members.organizationId); // this will be removed when we have the "choose organization page"

      setMember(members)
    }
  }, [user, organization]);

  return (
    <MemberContext.Provider value={{ member, organizationId: organization, setOrganization }}>
      {children}
    </MemberContext.Provider>
  );
};

export const useMember = () => {
  return useContext(MemberContext);;
};
