import { uploadOrganizationImage } from "../../../../../helpers/storage/organizationImage";
import { removeEmptyKeys } from "../../../../../helpers/utils";
import {
  createOrganization,
  getOrganizationByDocumentNumber,
  updateOrganization
} from "../../../../../services/OrganizationService";

const uploadAvatar = async (organizationId, file) => {
  const filename = `${organizationId}.png`;

  await uploadOrganizationImage(filename, file);

  await updateOrganization(organizationId, {
    image: filename
  });
};

const triggerCreateOrganization = async (formdata) => {
  const thereIsOrganization = !!(await getOrganizationByDocumentNumber(formdata.documentNumber));
 
  if (thereIsOrganization) {
    throw new Error("Já existe uma organização com esse CNPJ");
  }

  formdata.file = formdata.image;
  formdata.image = null;

  const organizationData = {...removeEmptyKeys(formdata)}
  const { id } = await createOrganization(organizationData);

  if (formdata.file)
    await uploadAvatar(id, formdata.file);
  
};

export { triggerCreateOrganization };
