import React, { useEffect, useState } from "react";

import { getOrganizationById } from "../../../services/OrganizationService";
import { useMember } from "../../Providers/MemberProvider";

const OrganizationProvider = ({ children }) => {
  const { member } = useMember();
  const [state, setState] = useState({ id: 1, pipefyId: "0" });
  const orgId = member?.organizationId;

  useEffect(() => {
    if (orgId) {
      getOrganizationById(orgId).then((r) => {
        setState(r);
      });
    }
  }, [orgId]);

  return <>{children({ state })}</>;
};

export { OrganizationProvider };
