/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getOrganizationById,
  updateOrganization
} from "../../../../services/OrganizationService";
import { useAuth } from "../../../../components/Auth";
import undraw_profile_1 from "../../../../../images/undraw_profile_1.svg";
import { useToastily } from "../../../../components/Toastily/useToastily";
import { curateFieldsToForms } from "../../../../helpers/utils";
import { organizationFields } from "./constants";
import EditForm from "../../../../components/FormPage/EditForm/EditForm";
import ProfileCard from "../../../../components/FormPage/Cards/ProfileCard/ProfileCard";
import { Col } from "react-bootstrap";
import * as yup from "yup";
import Toastily from "../../../../components/Toastily/Toastily";
import { validateCnpj } from "../../../../components/Form/FormInputs/helpers/utils";
import { getOrganizationImage } from "../../../../helpers/storage/organizationImage";

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  legalName: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .test("cnpj validation", "Documento Inválido", (cnpj) => {
      if (!cnpj) return false;
      return validateCnpj(cnpj.replace(/\D/g, ""));
    })
});

const getOrganization = async (orgId, callback) => {
  const organization = await getOrganizationById(orgId);
  callback(organization);
};

const OrganizationProfile = () => {
  const [organization, setOrganization] = useState();
  const [fields, setFields] = useState([]);
  const { toast, actions: actionsToast } = useToastily();
  const [leads, setLeads] = useState({
    completed: 0,
    inProgress: 0,
    rejected: 0
  }); //@todo fake until definition
  const [logo, setLogo] = useState();

  const { id: organizationId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getOrganization(organizationId, (org) => setOrganization(org));
  }, [organizationId]);

  useEffect(() => {
    if (!!organization) {
      const fields = curateFieldsToForms(organization, organizationFields);
      setFields(fields);
    }
  }, [organization]);

  const onSubmit = async (data) => {
    try {
      const organization = await updateOrganization(organizationId, {
        ...data
      });

      setOrganization(organization);

      actionsToast.setSuccess("Organização atualizada com sucesso!");
    } catch (err) {
      actionsToast.setError(
        "Não foi possivel atualizar os dados da organização!"
      );
    }
  };

  const getLogo = async () => {
    const img = await getOrganizationImage( organization?.image);
    setLogo(img);
  };

  useEffect(() => {
    if (organization) {
      getLogo();
    }
  }, [organization]);

  const Card = ({ profile }) =>
    profile && (
      <Col xs={0} xl={4}>
        <ProfileCard
          {...profile}
          config={{
            leftButton: {
              text: "Adicionar membro",
              disabled: isNotAPontteUser(),
              clickHandler: handlerAddMember
            }
          }}
        />
      </Col>
    );

  const isNotAPontteUser = () => {
    return !user?.cognitoGroups?.includes("Pontte_Users");
  };

  const handlerAddMember = () => {
    navigate(`/internal/organizations/${organization.id}/members/new`, {
      replace: true
    });
  };
  
  return (
    <>
      <Toastily {...toast} />
      <EditForm
        {...{
          header: {
            title: organization?.name,
            subtitle: organization?.legalName
          },
          fields,
          validationSchema,
          onSubmit,
          btnSubmit: {
            text: "Atualizar Organização"
          }
        }}
      >
        <Card
          {...{
            profile: {
              organizationId: organization?.id,
              avatar: {
                defaultValue: logo,
                readonly: true
              },
              info: {
                completed: leads.completed,
                inProgress: leads.inProgress,
                rejected: leads.rejected
              }
            }
          }}
        />
      </EditForm>
    </>
  );
};

export default OrganizationProfile;
