import { DataStore } from "aws-amplify";
import { HookActions } from "../../../../../../models"; // Replace with the correct path to your generated models

const handleError = (error, message) => {
  console.error(message, error);
  throw error;
};

const queryById = async (Model, id) => {
  try {
    return await DataStore.query(Model, id);
  } catch (error) {
    handleError(error, "Error fetching by ID:");
  }
};

const queryAll = async (Model) => {
  try {
    return await DataStore.query(Model);
  } catch (error) {
    handleError(error, "Error fetching all:");
  }
};

const saveModel = async (Model, data) => {
  try {
    return await DataStore.save(new Model(data));
  } catch (error) {
    handleError(error, "Error creating:");
  }
};

const updateModel = async (Model, id, updatedFields) => {
  const model = await queryById(Model, id);
  if (!model) {
    throw new Error(`Model with ID ${id} not found`);
  }

  try {
    return await DataStore.save(
      Model.copyOf(model, (updated) => {
        Object.assign(updated, updatedFields);
      })
    );
  } catch (error) {
    handleError(error, "Error updating:");
  }
};

const deleteModel = async (Model, id) => {
  const model = await queryById(Model, id);
  if (!model) {
    throw new Error(`Model with ID ${id} not found`);
  }

  try {
    await DataStore.delete(model);
  } catch (error) {
    handleError(error, "Error deleting:");
  }
};

const createAuthHookAction = async (userId, newAuthType) => {
  const hookActionData = {
    userId,
    action: "updateAuth",
    data: JSON.stringify({ newAuthType }),
    authType: newAuthType,
    status: "pending"
  };

  return await saveModel(HookActions, hookActionData);
};

export const HookActionService = {
  createHookAction: (data) => saveModel(HookActions, data),
  updateHookAction: (id, updatedFields) => updateModel(HookActions, id, updatedFields),
  deleteHookAction: (id) => deleteModel(HookActions, id),
  getHookActionById: (id) => queryById(HookActions, id),
  getAllHookActions: () => queryAll(HookActions),
  createAuthUpdateHookAction: (userId, newAuthType) => createAuthHookAction(userId, newAuthType)
};
