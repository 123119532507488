import React from "react";
import { Container } from "react-bootstrap";
import { Organization } from "../../../../../models";
import Toastily from "../../../../components/Toastily/Toastily";
import { useToastily } from "../../../../components/Toastily/useToastily";
import { sendEmailRDStation } from "../../../../helpers/plugaFetcher";
import { getGuestAuthType } from "../../../../helpers/utils";
import {
  getMemberByEmail,
  saveMember
} from "../../../../services/MemberService";
import {
  saveOrganization,
  getOrganizationByDocumentNumber,
  updateOrganization
} from "../../../../services/OrganizationService";
import { saveUserInvite } from "../../../../services/UserInviteServices";
import FormInvite from "./FormInvite";
import Page from '../../../../components/Page/Page'
import "./styles.scss";
import { saveOnPipefy } from "App/services/OrganizationService";

const triggerSendInvite = async (userMember, {email, documentNumber, name}) => {
  if (!documentNumber) throw new Error("documentNumber is Not Found");

  let guestOrganizaton = await getOrganizationByDocumentNumber(documentNumber)
  if ( !guestOrganizaton ) {
    guestOrganizaton = await saveOrganization({email, documentNumber, name})
  }

  const hostMemberId = userMember.member.id;
  const hostCognitoGroups = userMember.user.cognitoGroups;

  const guestAuthType = getGuestAuthType(hostCognitoGroups, email);

  let guestMember = await getMemberByEmail(email, guestOrganizaton.id);
  if (!guestMember) {
    guestMember = await saveMember(email, guestOrganizaton.id);
  }

  const pipefyId = await saveOnPipefy({organization: guestOrganizaton, member: guestMember})
  const guestOrganizationUpdated = await updateOrganization(guestOrganizaton.id, {...guestOrganizaton, pipefyId})

  const { id: userInviteId } = await saveUserInvite(
    guestOrganizaton.id,
    hostMemberId,
    guestMember.id,
    guestAuthType
  );

  sendEmailRDStation({
    userInviteId,
    email: email,
    name,
    partnerOrgId: Organization.id
  });
};

const Invite = () => {
  const { toast, actions } = useToastily();

  return (
    <>
      <Page showBreadcrumb={true}>
        <Toastily {...toast} />
        <Container className="container-user-invite">
          <header className="L-title">
            Envie um convite para fazer parte do Portal do Parceiro!
          </header>
          <FormInvite {...{ triggerSendInvite, actions }} />
        </Container>
      </Page>
    </>
  );
};

export default Invite;
