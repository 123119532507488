const QUERY_TYPE = {
  'ALL': 'ALL',
  'ORGANIZATION': 'ORGANIZATION',
  'MEMBER': 'MEMBER'
}

const queryFiltersMap = {
  'ORGANIZATION': {
    'documentProperty': 'organizationId',
    'memberProperty': 'organizationId'
  },
  'MEMBER': {
    'documentProperty': 'memberId',
    'memberProperty': 'id'
  }
}

const getQueryType = (groups) => {
  if (Array.isArray(groups) && groups.includes("Pontte_Users")) {
    return QUERY_TYPE.ALL;
  } else if (Array.isArray(groups) && !groups.includes("Pontte_Users") && groups.includes("Admin")) {
    return QUERY_TYPE.ORGANIZATION;
  } else {
    return QUERY_TYPE.MEMBER;
  }
}

const getFilterFunction = (member, queryType) => {
  if (queryType === QUERY_TYPE.ALL) {
    return (document) => document
  } else {
    const filterProperties = queryFiltersMap[queryType]
    return (document) => document[filterProperties.documentProperty]('eq', member[filterProperties.memberProperty])
  }
}

const getQueryFilter = ({member, groups}) => {
  const queryType = getQueryType(groups)
  const filterFunction = getFilterFunction(member, queryType)

  return filterFunction

}

export { getQueryFilter}