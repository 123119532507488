import { useEffect, useState, useContext } from "react";
import { FilterableSearchContext } from "../Context";

const triggerFilteredItems = ({ items, keys, value}) => {
  const lower = (str) => (!!str ? str.toString().toLowerCase() : "");
  console.log({ items, keys, value})
  const filtered = items.filter((item) => {
    if (Array.isArray(value)) {
      return keys.some((key) =>  {
        return value.some( (uniqueValue) => {

          if (typeof item[key] === "object" && Array.isArray(item[key]) === false) {
            return item[key]?.id?.includes(uniqueValue)
          } else {
            return item[key]?.includes(uniqueValue) 
          }

        })      
      })
    } else {
      return keys.some((key) => lower(item[key]).startsWith(lower(value)))
    }
  });

  return filtered
};

const SearchList = ({ itemList, keys, term, children}) => {
  const {setResult} = useContext(FilterableSearchContext)
  const [filtered, setFiltered] = useState(itemList);

  useEffect(()=>{
    if (keys && term && !(Array.isArray(term) && term.length === 0)) {
      const filteredList = triggerFilteredItems({
        keys,
        value: term,
        items: itemList,
      });
      setFiltered(filteredList)
    }
    if ((term === "" || (Array.isArray(term) && term.length === 0)) || keys?.length === 0) {
      setResult(itemList)
    }
  }, [term])

  useEffect(() => {
    setResult && setResult(filtered)
  }, [filtered]);

  return children ? children(filtered) : null;
};

export default SearchList;
