import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

// Get using subscribe
const getUser = async (id) => {
  const { data } = await API.graphql({
    query: queries.getUser,
    variables: { id },
  });

  return data["getUser"];
};

// Create
const createUser = async (user) => {
  return API.graphql({
    query: mutations.createUser,
    variables: {
      input: { ...user }
    },
    authMode: "API_KEY"
  });
};

// Update

// Delete

export { getUser, createUser };
