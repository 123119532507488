export const navLinks = [
  {
    title: "",
    items: [
      {
        title: "Dashboard",
        fa: "tachometer-alt",
        route: "/private/dashboard",
        authGroup: "Pontte_Users",
        items: []
      }
    ]
  },
  {
    title: "FORMULÁRIOS",
    items: [
      {
        title: "Home Equity",
        fa: "landmark",
        items: [
          {
            title: "Cadastro",
            route: "/private/leads/HomeEquity"
          },
          {
            title: "Simulação",
            route: "/private/simulations/HE/new"
          }
        ]
      },
      {
        title: "Financiamento",
        fa: "hand-holding-usd",
        items: [
          {
            title: "Cadastro",
            route: "/private/leads/Financing"
          },
          {
            title: "Simulação",
            route: "/private/simulations/FI/new"
          }
        ]
      }
    ]
  },
  {
    title: "BIBLIOTECA",
    items: [
      {
        title: "Materiais de Apoio",
        fa: "building",
        route: "/private/blog/material-apoio"
      }
    ]
  },
  {
    title: "ACOMPANHAMENTO",
    items: [
      {
        title: "Leads",
        fa: "filter-circle-dollar",
        route: "/private/leads",
        items: [],
        visibility: ({userLeads}) => {
          return userLeads && userLeads.length > 0;
        }
      },
      {
        title: "Status das operações",
        route: "https://app.pipefy.com/request-tracker/",
        fa: "address-card"
      }
    ]
  }
];