import React from "react";
import LargeIframe from "../../../../components/Iframe/LargeIframe";

const HelpContent = () => {
  return (
    <LargeIframe
      url={"https://www.sejaparceiro.pontte.com.br/cópia-material-fi"}
    />
  );
};

export default HelpContent;
