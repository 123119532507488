import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import { Row } from "react-bootstrap";
import Btn from "App/components/Btn";
import { DIRECTIONS, DIRECTION_COMPONENT_MAP } from './constants'
import FormSection from "./FormSection"

const SectionedForm = ({direction = DIRECTIONS.HORIZONTAL, sections, validationSchema, onSubmit, onError}) => {
  const formConfig = {}
  if (validationSchema) {
    formConfig.resolver = yupResolver(validationSchema)
  }
  const methods = useForm(formConfig);
  const { handleSubmit, formState } = methods;
  const FormDirectionComponent = DIRECTION_COMPONENT_MAP[direction]

  return (
    <FormProvider {...methods}>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="form-records">
          <Row>
          {!!Array.isArray(sections) && sections.map( ({sectionClass, fields, title, subtitle, inputClass, children}) => {
            return (
              <>
                {(
                  <FormDirectionComponent className={sectionClass}>
                    <FormSection>
                      <FormSection.Title title={title} />
                      <FormSection.Title title={subtitle} />
                      <FormSection.Wizard {...{fields, inputClass}}/>
                      {children}
                    </FormSection>
                  </FormDirectionComponent>
                )}
              </>
            )
          })}
          </Row>
        </div>
        <div className="d-flex justify-content-start mb-4">
          <Btn title="Salvar" classNames={['primary', 'pill']} loading={formState.isSubmitting} />
        </div>
      </form>
    </FormProvider>
  )
}

export default SectionedForm