import { formatDateToLocaleDateBR, formatMoney2 } from "App/helpers/formats"
import Label from "App/components/Label"

const formatListCells = ({keyName, cellValue, item}) => {
  if (keyName === "Phase") {
    const color = cellValue?.color === "sky" ? "skyblue" : cellValue?.color;
    const name = cellValue?.name;
    return <Label title={name} color={color}/>;
  }
  if (keyName === "createdAt") {
    return formatDateToLocaleDateBR(cellValue)
  }
  if (keyName === "value") {
    return formatMoney2(cellValue.replace(/\./g, '').replace(",", "."))
  }
  return cellValue;
}

export {
  formatListCells
}
