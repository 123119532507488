import React from "react";
import Iframe from "../../../components/Iframe/Iframe";
import "./styles.scss";

const Indication = () => (
  <div className="PublicPage">
    <Iframe url="https://www.sejaparceiro.pontte.com.br/c%C3%B3pia-home" />
  </div>
);

export default Indication;
