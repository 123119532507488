export const GRACE_PERIOD_OPTIONS = [{
    label: "Não preciso", value: "0"
}, {
    label: "1 mês", value: "1"
}, {
    label: "2 meses", value: "2"
}, {
    label: "3 meses", value: "3"
},];

export const TERMS_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "60 meses", value: "60"
}, {
    label: "120 meses", value: "120"
}, {
    label: "180 meses", value: "180"
}, {
    label: "240 meses", value: "240"
}];

export const FI_TERMS_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "60 meses", value: "60"
}, {
    label: "120 meses", value: "120"
}, {
    label: "180 meses", value: "180"
}, {
    label: "240 meses", value: "240"
}, {
    label: "360 meses", value: "360"
}

];

export const MONTH_OPTION = [{
    label: "Selecione...", value: ""
}, {
    label: "Não preciso", value: "-1"
}, {
    label: "Janeiro", value: "1"
}, {
    label: "Fevereiro", value: "2"
}, {
    label: "Março", value: "3"
}, {
    label: "Abril", value: "4"
}, {
    label: "Maio", value: "5"
}, {
    label: "Junho", value: "6"
}, {
    label: "Julho", value: "7"
}, {
    label: "Agosto", value: "8"
}, {
    label: "Setembro", value: "9"
}, {
    label: "Outubro", value: "10"
}, {
    label: "Novembro", value: "11"
}, {
    label: "Dezembro", value: "12"
}];

export const DAY_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "1", value: "1"
}, {
    label: "2", value: "2"
}, {
    label: "3", value: "3"
}, {
    label: "4", value: "4"
}, {
    label: "5", value: "5"
}, {
    label: "6", value: "6"
}, {
    label: "7", value: "7"
}, {
    label: "8", value: "8"
}, {
    label: "9", value: "9"
}, {
    label: "10", value: "10"
}, {
    label: "11", value: "11"
}, {
    label: "12", value: "12"
}, {
    label: "13", value: "13"
}, {
    label: "14", value: "14"
}, {
    label: "15", value: "15"
}, {
    label: "16", value: "16"
}, {
    label: "17", value: "17"
}, {
    label: "18", value: "18"
}, {
    label: "19", value: "19"
}, {
    label: "20", value: "20"
}, {
    label: "21", value: "21"
}, {
    label: "22", value: "22"
}, {
    label: "23", value: "23"
}, {
    label: "24", value: "24"
}, {
    label: "25", value: "25"
}, {
    label: "26", value: "26"
}, {
    label: "27", value: "27"
}, {
    label: "28", value: "28"
}, {
    label: "29", value: "29"
}, {
    label: "30", value: "30"
}, {
    label: "31", value: "31"
}];

export const ITBI_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "Incluir", value: true
}, {
    label: "Não Incluir", value: false
}];

export const LEGAL_PERSON_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "Pessoa física", value: false
}, {
    label: "Pessoa Jurídica", value: true
}];

export const TABLE_OPTIONS = [{
    label: "Selecione...", value: ""
}, {
    label: "SAC", value: "SAC"
}, {
    label: "PRICE", value: "PRICE"
}];


