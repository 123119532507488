import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { subscribeToModel } from "App/helpers/dataStore";
import { useMember } from "App/components/Providers/MemberProvider";
import { useToastily } from "App/components/Toastily/useToastily";
import { ListFilterableAndPaginated } from "App/containers/FilterablePaginatedList";
import { SimplePage } from "App/components/Pages/SimplePage";
import { deleteMember } from "App/services/MemberService";
import { useAuth } from "App/components/Auth";
import Toastily from "App/components/Toastily/Toastily";
import userPageIcon from "images/icone-page-user.svg";
import Btn from "App/components/Btn";
import { triggerSendInvite } from "App/helpers/member";
import "./styles.scss";

const Members = () => {
  const [members, setMembers] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { actions: actionsToast, toast } = useToastily();
  const paginatedListRef = useRef(null);
  const { user } = useAuth();
  let selectedItems = selectedMembers;

  const { member } = useMember();
  const navigate = useNavigate();
  const location = useLocation();
  const filter = () => {
    if (user?.cognitoGroups.includes("Pontte_Users")) {
      return (m) => m;
    }
    return (m) => m.organizationId("eq", member.organizationId);
  };
  useEffect(() => {
    setLoading(true);
    subscribeToModel({
      model: "Member",
      filter: filter(),
      callback: (m) => {
        setMembers(m);
        setLoading(false);
      }
    });
  }, [member]);

  useEffect(() => {
    if (members.length > 0) {
      setItems(
        members.map(({ id, name, email, phone, documentNumber }) => ({
          id,
          name,
          email,
          phone,
          documentNumber
        }))
      );
    }
  }, [members]);

  useEffect(() => {
    if (location.state?.toast) {
      actionsToast.setSuccess(location.state.toast);
    }
  }, [location.state?.toast]);

  const memberStatusOptions = [
    { label: "Ativo", value: "Ativo" },
    { label: "Inativo", value: "Inativo" }
  ];
  const selectItem = (event) => {
    event.stopPropagation();
    const { target } = event;
    if (target.checked) {
      selectedItems.push(target.value);
      selectedItems = [...selectedItems]; // Se eu não atualizar a referencia o meu setSelectedMember não vai disparar o render
    } else {
      selectedItems = [...selectedMembers.filter((id) => id !== target.value)];
    }
    setSelectedMembers(selectedItems);
  };

  const selectAllItems = ({ target }) => {
    let checkboxes = paginatedListRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );
    let checkboxChecked = true;
    selectedItems = [];
    if (!target.checked) {
      checkboxChecked = false;
    }
    checkboxes.forEach((node) => {
      node.checked = checkboxChecked;
      if (checkboxChecked) {
        selectedItems.push(node.value);
      }
    });

    setSelectedMembers(selectedItems);
  };

  const formatCellMember = ({ keyName, cellValue, item }) => {
    if (keyName === "id") {
      return (
        <input
          type="checkbox"
          name="selectedItems"
          key={cellValue}
          value={cellValue}
          onChange={selectItem}
        />
      );
    }

    return cellValue;
  };

  const handleInvite = async () => {
    if (selectedMembers.length === 0) {
      navigate(`/internal/organizations/${member.organizationId}/members/new`, {
        replace: true
      });
    } else {
      try {
        const selected = members.filter((member) => {
          return selectedItems.includes(member.id);
        });
        selected.forEach(async (selectedMember) => {
          await triggerSendInvite({ member: member, user }, selectedMember);
        });
        actionsToast.setSuccess("Convites enviados com sucesso!");
      } catch (error) {
        actionsToast.setError(
          "Não foi possivel enviar o convite. Tente novamente mais tarde."
        );
        console.log({ error });
      }
    }
  };

  const handleDelete = () => {
    try {
      selectedMembers.forEach(async (memberId) => {
        await deleteMember(memberId);
      });
      actionsToast.setSuccess("Usuários deletados com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possivel deletar os usuários. Tente novamente mais tarde."
      );
      console.log({ error });
    }
  };

  const membersListConfig = {
    list: {
      showColumns: [
        {
          label: (
            <input
              type="checkbox"
              name="selectedAll"
              key="selectAll"
              value="all"
              onChange={selectAllItems}
            />
          ),
          key: "id"
        },
        { label: "Nome", key: "name" },
        { label: "Email", key: "email" },
        {
          label: "Telefone",
          key: "phone"
        },
        { label: "Documento", key: "documentNumber" }
      ],
      formatCell: formatCellMember,
      onItemClick: ({ item }) => navigate(`/private/members/${item.id}`)
    },
    filter: {
      nameColumn: "name",
      statusColumn: "status",
      statusOptions: memberStatusOptions,
      dateColumn: "createdAt",
      statusPlaceholder: "Filtrar status"
    }
  };

  const addUserButton = (
    <Btn
      classNames={["primary", "round"]}
      onClick={handleInvite}
      title={
        selectedMembers?.length > 0 ? "Convidar usuários" : "Adicionar usuário"
      }
    />
  );
  const deleteUserButton = (
    <Btn
      classNames={["primary", "round"]}
      onClick={handleDelete}
      title="Excluir usuário"
    />
  );

  return (
    <SimplePage small={true} icon={userPageIcon} title="Usuários">
      <>
        <Toastily {...toast} />
        <div ref={paginatedListRef}>
          <ListFilterableAndPaginated
            list={items}
            listOptions={membersListConfig.list}
            filterOptions={membersListConfig.filter}
            headerButtons={[deleteUserButton, addUserButton]}
          />
        </div>
      </>
    </SimplePage>
  );
};

export default Members;
