import React from "react";
import { Controller } from "react-hook-form";
import {Select} from "../../../../../components/Form/FormInputs";

const CognitoInput = ({ hostUser, name = "member.authType", formControl, methods }) => {
  const getCognitoGroupDefaultValue = (data) => {
    const { cognitoGroups } = data;
    return cognitoGroups.includes("Admin");
  };

  const MEMBER_OPTIONS = [
    { label: "Member", value: "Member" },
    { label: "Admin", value: "Admin" }
  ];

  return (
      <Controller
          name={name}
          control={formControl}
          render={({ field }) => (
              <Select
                  id="authType"
                  label="Grupo de Permissão:"
                  type="select"
                  name={name}
                  inputClassLabel={["bold"]}
                  isDisabled={!getCognitoGroupDefaultValue(hostUser)}
                  options={MEMBER_OPTIONS}
                  value={field.value}
                  onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  {...methods}
              />
          )}
      />
  );
};

export default CognitoInput;
