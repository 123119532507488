import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Phase } from "../../models";

const getAllPhases = async () => {
  try {
    const phases = await DataStore.query(Phase, Predicates.ALL);
    return phases;
  } catch (error) {
    console.log("error on get phases:", error);
    return [];
  }
};

export {
  getAllPhases
}
