import ContextSelect from "./ContextSelect";

const FIHEContainer = ({ type = "", children }) => {
  return (
    <>
      <ContextSelect defaultOption={type.toUpperCase()} children={children} />
    </>
  );
};

export default FIHEContainer;
