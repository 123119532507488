import { useEffect, useState } from "react";
import { getLeadsByMemberId } from "../../../services/LeadService";
import { useMember } from "../MemberProvider/MemberProvider";

const userLeads = async (callback, member) => {
  const leads = await getLeadsByMemberId(member.id);

  callback(leads);
};

const LeadProvider = ({ children }) => {
  const { member } = useMember();

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    if (member?.id) {
      userLeads(setLeads, member);
    }
  }, [member]);

  return children({ leads });
};

export default LeadProvider;
