import { formatMoney2 } from "../../helpers/formats";

const CommonText = ({ label, value }) => (
  <div className="form-field">
    <span className="form-field-label">{label}</span>
    <span className="form-field-value">{value}</span>
  </div>
);

const MoneyText = (field) => (
  <CommonText {...{ ...field, value: formatMoney2(field.value) }} />
);

const ShowRecords = ({ fields }) => {
  const types = {
    money: (field) => <MoneyText {...field} />,
    short_text: (field) => <CommonText {...field} />,
  };

  return (
    <div className="form-records">
      <div className="form-records-fields">
        {fields.map((field) => {
          const component = types[field.type];

          if (!component) {
            return <CommonText key={field.type + field.id} {...field} />;
          }

          return component({ ...field, key: field.type + field.id }); 
        })}
      </div>
    </div>
  );
};

export default ShowRecords;
