import { Row, Col } from "react-bootstrap"

export const DIRECTIONS = {
  'HORIZONTAL': 'horizontal',
  'VERTICAL': 'vertical'
}

export const DIRECTION_COMPONENT_MAP = {
  [DIRECTIONS.HORIZONTAL]: Col,
  [DIRECTIONS.VERTICAL]: Row,
}