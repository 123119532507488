import { getAvatarUrl } from "App/helpers/storage/uploadAvatar";
import defaultProfileImage from "images/undraw_profile_1.svg";

export const getOrganizationField = (organization) => {
  let defaultValues = [
    {
      'name': 'organization',
      'placeholder': organization.name,
      'defaultValue': organization.name
    }
  ]
  
  return defaultValues
}

export const getOrganizationFields = (organization) => {
  let organizationFields = Object.keys(organization).map( (organizationProperty) => {
    return {
      name: organizationProperty,
      defaultValue: organization[organizationProperty],
    }
  })

  return organizationFields
}

export const getAvatarDefaultValue = async (avatarPath) => {
  try {
    const avatarUrl = await getAvatarUrl(avatarPath)
    return [
      {
        'name': 'image',
        'defaultValue': avatarUrl
      }
    ]
  } catch (error) {
    return [
      {
        'name': 'image',
        'defaultValue': defaultProfileImage
      }
    ]
  }
}
