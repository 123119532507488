import React, { useEffect, useState } from "react";
import { groupListByNumberOfItems } from "../../../../helpers/utils";

const Header = ({ items }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields(groupListByNumberOfItems(items, 2));
  }, [items]);

  return fields.map((items) => (
    <div className="installments-table-detail-col">
      {items.map(({ key, value }) => (
        <article className="installments-table-detail-item">
          <p className="installments-table-detail__text">
            <strong>{key}</strong>
            <span>{value}</span>
          </p>
        </article>
      ))}
    </div>
  ));
};

export default Header;
