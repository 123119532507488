const getFirst_installment = (flow) => {
  const index = flow.installment.findIndex((i) => i.quota !== 0);
  return flow.installment[index].installment;
};
const calculateGrossLoan = ({iof, teo, eCpfInvoiceValue, registry_value, netLoan }) => iof + teo + eCpfInvoiceValue + registry_value + netLoan 

const formatFlowDataToPDF = ({ flow, simulation }) => {
  console.log({flow})
  console.log({simulation})
  const contract = {};
  const proposal = { ...flow };
  contract.user = {};
  contract.user.name = contract.user.name ?? "";

  proposal.amortizationSchedule = !!simulation?.table
    ? simulation?.table
    : "SAC";

  proposal.first_installment = getFirst_installment(flow);
  proposal.dueDay = new Date(flow.installment[0].dueOn).getUTCDate();
  proposal.index = flow.index ?? "IPCA";
  proposal.skipMonth = simulation?.skipMonth;
  proposal.interestRate = flow?.interest_rate * 100;
  proposal.itbi = simulation.itbi?.toString();
  proposal.table = simulation.table || "SAC";

  return {
    proposal: {
      ...simulation,
      ...proposal
    },
    contract,
    final: false
  };
};

const authorizePage = ({ user, member, simulation }) => {
  if (Array.isArray(user.cognitoGroups)) {
    if (user.cognitoGroups.includes("Pontte_Users")) {
      return true;
    }
    if (simulation.organizationId === member.organizationId) {
      if (user.cognitoGroups.includes("Admin")) {
        return true;
      }

      if (simulation.memberId === member.id) {
        return true;
      }
    }
  } else {
    if (simulation.memberId === member.id) {
      return true;
    }
  }

  return false;
};

export { formatFlowDataToPDF, authorizePage };
