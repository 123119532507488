import { API, graphqlOperation } from 'aws-amplify';
import { useAuth } from "App/components/Auth";
import { DataStore } from "@aws-amplify/datastore";
import { subscribeToModel } from "App/helpers/dataStore";
import { getQueryFilter } from "App/helpers/auth";
import { listLeads } from "../../graphql/queries"
import { Lead } from "../../models";

const getLeadsByMonth = async (month, year) => {
  const lastDayOfMonth = new Date(year, month, 0).getDate()

  try {
      const {data: {listLeads: {items}}} = await API.graphql(graphqlOperation(listLeads, {
        filter: {
          createdAt: {between: [`${year}-0${month}-01T00:00:00`, `${year}-0${month}-${lastDayOfMonth}}T23:59:59`]}
        }
      }))

      return items
  } catch (error) {
      console.log('error on get leads:', error);
  }
}

const getLeadsByOrganizationIdUntilMonth = async ({month, year, organizationId}) => {
  const lastDayOfMonth = new Date(year, month, 0).getDate()
  if (month.toString().length === 1) {
    month = "0" + (month.toString())
  }

  try {
    const leads = await DataStore.query(Lead, lead => 
      lead.organizationId('eq', organizationId).createdAt('lt', `${year}-${month}-${lastDayOfMonth}}T23:59:59`))

    return leads
  } catch (error) {
      console.log('error on get leads:', error);
  }
}

const getLeadsByOrganizationId = async (organizationId) => {
  try {
    const leads = await DataStore.query(Lead, (lead) => lead.organizationId('eq', organizationId))

    return leads
  } catch (error) {
      console.log('error on get leads:', error);
  }
}

const getLeadsByMemberId = async (memberId) => {
  try {
    const leads = await DataStore.query(Lead, (lead) => lead.memberId('eq', memberId))

    return leads
  } catch (error) {
      console.log('error on get leads:', error);
  }  
}

const useSubscribeToLeads = () => {
  const { user } = useAuth()
  const member = user.Members.items[0];
  const queryFilter = getQueryFilter({groups: user.cognitoGroups, member})
  
  return {
    'subscribeLeads': () => {
      return new Promise((resolve, reject) => {
        try {
          subscribeToModel({
            model: "Lead",
            filter: queryFilter,
            callback: (items) => {
              resolve(items)
            }
          }); 
        } catch (error) {
          console.log("LeadService: Error on useSubscribeToLeads");
          console.log({error});
          reject(error);
        }
      })
    }
  }
}

/**
 * Problaby new universal method do get data
 */
const getLeadsByUser = async ({user}) => {
  const member = user.Members.items[0];
  const queryFilter = getQueryFilter({groups: user.cognitoGroups, member})
  const leads = await DataStore.query(Lead, queryFilter)

  return leads;
}


export { getLeadsByMonth, getLeadsByOrganizationId, getLeadsByOrganizationIdUntilMonth, getLeadsByMemberId, useSubscribeToLeads, getLeadsByUser};
