import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSimulationById } from "../../../services/SimulationService";
// import FiToLeadButton from "../../../components/FiToLeadButton/FiToLeadButton";
import { OrganizationProvider } from "../../../components/Providers/OrganizationProvider/OrganizationProvider";
import EmbeddedForm from "../../../components/Pipefy/EmbeddedForm/EmbeddedForm";
import { simulationToFiLead } from "../../../helpers/simulationConverter";
import { SimplePage } from "../../../components/Pages/SimplePage";
import iconePageSimulation from "../../../../images/icone-page-2.svg";

const ConvertSimulationToLead = () => {
  const { id: simulationId } = useParams();
  const [initialValues, setInitialValues] = useState({});
  // const [showLeadForm, setShowLeadForm] = useState(true);
  const PIPEFY_PUBLIC_FORM = "https://app.pipefy.com/public/form/n3IJVul4";

  useEffect(() => {
    (async () => {
      const simulation = await getSimulationById(simulationId);
      if (simulation) {
        setInitialValues(simulationToFiLead(simulation));
      }
    })();
  }, [simulationId]);

  // const simulationConvertHandler = (lead) => {
  //   setInitialValues(lead);
  //   setShowLeadForm(true);
  // };

  return (
    <SimplePage
      title="Indicar Operação"
      large={true}
      icon={iconePageSimulation}
    >
      {/* <Row>
        <h1 className={"L-title"}>Simulação</h1>
          {!simulation && (<p>Identificador invalido para simulação.</p>)}
          {simulation &&  (<FiToLeadButton simulation={simulation} triggerSimulationConvert={simulationConvertHandler}/>) }
      </Row> */}
      <Row className="mt-2">
        <OrganizationProvider>
          {(organization) => (
            <EmbeddedForm
              title={"cadastro leads Finan"}
              initialValues={initialValues}
              {...{
                PIPEFY_PUBLIC_FORM,
                ...organization.state
              }}
            />
          )}
        </OrganizationProvider>
      </Row>
    </SimplePage>
  );
};

export default ConvertSimulationToLead;
