import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMember } from "App/components/Providers/MemberProvider";
import { SimplePage } from "App/components/Pages/SimplePage";
import { ListFilterableAndPaginated } from 'App/containers/FilterablePaginatedList'
import { useSubscribeToSimulations } from "App/services/SimulationService"
import { formatDateToLocaleDateBR, formatMoney2, formatGracePeriod } from "App/helpers/formats"
import simulationIcon from "images/simulations-icon.svg"

const Simulations = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const {subscribeSimulations} = useSubscribeToSimulations()
  const { member } = useMember();
  const navigate = useNavigate();

  useEffect(async () => {
    if (member) {
      setLoading(true);
      const simulations = await subscribeSimulations()
      setItems(simulations)
      setLoading(false);
    }
  }, [member]);

  const formatCellSimulation = ({keyName, cellValue, item}) => {
    if (keyName === "createdAt") {
      return formatDateToLocaleDateBR(cellValue)
    }
    if (keyName === "type") {
      return cellValue === "HE" ? "Home Equity" : "Financiamento"
    }
    if (keyName === "loanValue") {
      return formatMoney2(cellValue)
    }
    if (keyName === "propertyValue") {
      return formatMoney2(cellValue)
    }
    if (keyName === "monthlyIncome") {
      return formatMoney2(cellValue)
    }
    if (keyName === "gracePeriod") {
      return formatGracePeriod(cellValue)
    }

    return cellValue
  }

  const showColumnsSimulation = [
    {"label": "Valor do Imóvel", "key": "propertyValue"},
    {"label": "Renda Mensal", "key": "monthlyIncome"},
    {"label": "Carência", "key": "gracePeriod"},
    {"label": "Produto", "key": "type"},
    {"label": "Valor da Operação", "key": "loanValue"},
    {"label": "Data da Simulação", "key": "createdAt"}
  ];

  const simulationStatusColumn = [
    {
      "value": "FI",
      "label": "Financiamento"
    },
    {
      "value": "HE",
      "label": "Home Equity"
    }]

    const simulationColumns = {
      name: 'loanValue',
      status: 'type',
      date: 'createdAt'
    }

    const simulationListOptions = {
      showColumns: showColumnsSimulation,
      formatCell: formatCellSimulation,
      onItemClick: ({ item }) => navigate(`/private/simulations/${item.id}`),
      loading: loading
    }
  
    const simulationFilterOptions = {
      nameColumn: simulationColumns.name,
      namePlaceholder: 'Pesquisar valor',
      statusColumn: simulationColumns.status,
      statusOptions: simulationStatusColumn,
      dateColumn: simulationColumns.date,
      statusPlaceholder: "Filtrar produto"
    }

  return (
    <SimplePage small={true} icon={simulationIcon} title="Histórico de simulações" description="Veja aqui as simulações que você realizou:">
      <>
        <ListFilterableAndPaginated list={items} listOptions={simulationListOptions} filterOptions={simulationFilterOptions} />
      </>
    </SimplePage>
  );
};

export default Simulations;
