import { useLocation } from "react-router-dom";
import { AuthContext } from "App/components/Auth";
import { MemberContext } from "App/components/Providers/MemberProvider";
import { useAuth } from "App/components/Auth";
import BaseHeader from "App/components/Header/BaseHeader";
import Admin from "./Admin";
import Basic from "./Basic";
import Member from "./Member";

const isPagePrivate = (pathname) => {
  return (
    pathname.split("/")[1] === "private" ||
    pathname.split("/")[1] === "internal"
  );
};

const isAdminUser = (user) => {
  return user.cognitoGroups?.includes("Admin");
};

const getHeaderComponent = ({
  user,
  isPrivate,
  toogleOffCanvas,
  transparent
}) => {
  if (!isPrivate) {
    return (
      <Basic
        children={(props) => {
          return <BaseHeader transparent={transparent} {...props} />;
        }}
      />
    );
  } else {
    return (
      <AuthContext.Consumer>
        {({ signout, user }) => {
          if (isAdminUser(user)) {
            return (
              <Admin
                signout={signout}
                user={user}
                children={(props) => (
                  <BaseHeader
                    transparent={transparent}
                    toogleOffCanvas={toogleOffCanvas}
                    {...props}
                  />
                )}
              />
            );
          } else {
            return (
              <Member
                signout={signout}
                user={user}
                children={(props) => (
                  <BaseHeader
                    transparent={transparent}
                    toogleOffCanvas={toogleOffCanvas}
                    {...props}
                  />
                )}
              />
            );
          }
        }}
      </AuthContext.Consumer>
    );
  }
};

const HeaderContainer = ({ toogleOffCanvas, transparent = false }) => {
  const user = useAuth();
  const location = useLocation();
  const isPrivate = isPagePrivate(location.pathname);

  return (
    <>{getHeaderComponent({ user, isPrivate, toogleOffCanvas, transparent })}</>
  );
};

export default HeaderContainer;
