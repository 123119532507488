const memberFields = [
  {
    id: "name",
    name: "name",
    label: "Nome: *",
    type: "short_text",
    placeholder: "Digite",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "documentNumber",
    name: "documentNumber",
    label: "CPF: *",
    type: "cpfDocument",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "email",
    name: "email",
    label: "Email: *",
    type: "email",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "phone",
    name: "phone",
    label: "Telefone: ",
    type: "phone",
    inputClassLabel: ["form-field-label"]
  },
  {
    id: "birthDate",
    name: "birthDate",
    label: "Data de nascimento: ",
    type: "date",
    inputClassLabel: ["form-field-label"]
  },
];

export { memberFields };
