// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserInviteStatus = {
  "PENDING": "pending",
  "REGISTERED": "registered",
  "DECLINED": "declined"
};

const { HookActions, Phase, User, WhiteLabel, Simulation, UserInvite, Lead, Member, Organization } = initSchema(schema);

export {
  HookActions,
  Phase,
  User,
  WhiteLabel,
  Simulation,
  UserInvite,
  Lead,
  Member,
  Organization,
  UserInviteStatus
};