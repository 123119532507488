import axios from "axios";

const sendEmailRDStation = async ({
  userInviteId,
  email,
  partnerOrgName,
  partnerOrgId
}) => {
  const rdStationURL =
    "https://api.pluga.co/v1/webhooks/MzkxODM1ODg4MjcxOTY2ODA4NFQxNjQ1NTQ5NDkx";

  const config = {
    header: {
      "Content-Type": "application/json"
    }
  };

  const data = {
    userInviteId,
    email,
    partnerOrgName,
    partnerOrgId,
    registerLink: `https://parcerias.pontte.com.br/register?userInviteId=${userInviteId}`,
    eventName: "teste com lucas"
  };

  const { data: response } = await axios.post(rdStationURL, data, config);

  return response;
};

export { sendEmailRDStation };
