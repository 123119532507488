
class Config {
  env = 'development';

  constants = {
      development: {
          // SIMULATION_API: "https://api-portaldev.pontte.com.br",
          SIMULATION_API: "https://api-portal.pontte.com.br",
          PIPEFY_ORGANIZATION_TABLE: "302834944",
          PIPEFY_PIPE_HE_FORM_URL: "https://app.pipefy.com/public/form/DEzl0m3O",
          PIPEFY_PIPE_FI_FORM_URL: "https://app.pipefy.com/public/form/_3zxNoqi",
          CONCLUSION_PHASE_IDS: ["7aa4cbed-bbfd-4a1f-a566-f7d86f587571", "c380b914-a1e1-4f4e-a526-410cf429793c"]
      },
      staging: {
          SIMULATION_API: "https://api-portalstaging.pontte.com.br",
          PIPEFY_ORGANIZATION_TABLE: "302852039",
          PIPEFY_PIPE_HE_FORM_URL: "https://app.pipefy.com/public/form/DEzl0m3O",
          PIPEFY_PIPE_FI_FORM_URL: "https://app.pipefy.com/public/form/_3zxNoqi",
          CONCLUSION_PHASE_IDS: ["7aa4cbed-bbfd-4a1f-a566-f7d86f587571", "c380b914-a1e1-4f4e-a526-410cf429793c"]
      },
      production: {
          SIMULATION_API: "https://api-portal.pontte.com.br",
          PIPEFY_ORGANIZATION_TABLE: "302835563",
          PIPEFY_PIPE_HE_FORM_URL: "https://app.pipefy.com/public/form/cVjDqgpE",
          PIPEFY_PIPE_FI_FORM_URL: "https://app.pipefy.com/public/form/eZ-2Tnl8",
          CONCLUSION_PHASE_IDS: ["bc659509-18cb-44be-b408-6a559cab8912", "73bdfced-7339-4baa-b446-9f814a05050f"]
      }
  };

  constructor(NODE_ENV) {
      this.env = NODE_ENV;
  }

  run = () => {
      return this.constants[this.env]
  }
}
console.log("ENV", process.env.REACT_APP_STAGE)
export const CONFIG = new Config(process.env.REACT_APP_STAGE || 'production' ).run()
