import { sendEmailRDStation } from "../../../../helpers/plugaFetcher";
import { getGuestAuthType } from "../../../../helpers/utils";
import {
  getMemberByEmail,
  saveMember
} from "../../../../services/MemberService";
import { getOrganizationById } from "../../../../services/OrganizationService";
import { saveUserInvite } from "../../../../services/UserInviteServices";

const triggerSendInvite = async (userMember, guestEmail) => {
  const hostOrganizationID = userMember.member.organizationId;

  if (!hostOrganizationID) throw new Error("organizationId is Not Found");

  const hostMemberId = userMember.member.id;
  const hostCognitoGroups = userMember.user.cognitoGroups;

  const guestAuthType = getGuestAuthType(hostCognitoGroups, guestEmail);

  let guestMember = await getMemberByEmail(guestEmail, hostOrganizationID);

  if (!guestMember) {
    guestMember = await saveMember(guestEmail, hostOrganizationID);
  }

  const { id: userInviteId } = await saveUserInvite(
    hostOrganizationID,
    hostMemberId,
    guestMember.id,
    guestAuthType
  );

  const { name: partnerOrgName } = await getOrganizationById(
    hostOrganizationID
  );

  sendEmailRDStation({
    userInviteId,
    email: guestEmail,
    partnerOrgName,
    partnerOrgId: hostOrganizationID
  });
};

export { triggerSendInvite };
