import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form, Row } from "react-bootstrap";
import TextInput from "../../Form/FormInputs/TextInput";
import { useLocation } from "react-router-dom";
import { validateForm, mapperError } from "./helpers";
import { useAuth } from "../useAuth";
import LoadingIndicator from "../../LoadingIndicator";
import InputMask from "../../Form/FormInputs/Bootstrap/InputMask";
import { subscribeToModel } from "../../../helpers/dataStore";

const RegisterForm = ({ action, invite, buttonText = "Cadastrar", forgotPasswordHandler }) => {
  const [errors, setErrors] = useState(null);
  const [step, setStep] = useState(1);
  const [registerEmail, setRegisterEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const togglePasswordVisibility = (event) => {
    event.preventDefault();
    let updatedVisibility = !passwordVisible
    setPasswordVisible(updatedVisibility)
    setPasswordType(updatedVisibility ? "text": "password");
  }

  let location = useLocation();

  const { pathname } = location || "/";

  const auth = useAuth();

  useEffect(() => {
    if (invite) {
      if (registerEmail) {
        setStep(2);
      }
    }
  }, [invite, registerEmail]);

  useEffect(() => {
    if (invite?.guestId) {
      subscribeToModel({
        model: "Member",
        filter: (m) => m.id("eq", invite.guestId),
        callback: (members) => {
          if (members.length > 0) {
            setRegisterEmail(members[0]["email"]);
          }
        }
      });
    }
  }, [invite?.guestId]);

  const getErrorSubmit = useCallback(() => {
    const msg = auth.error ? mapperError(auth.error.type) : null;
    if (msg) setErrors({ password: { message: msg } });
  }, [auth.error]);

  useEffect(() => {
    getErrorSubmit();

    if (pathname === "/login") setStep(0);
  }, [getErrorSubmit, pathname]);

  const handleLoginValidate = useCallback(
    (values) => {
      const { errors } = validateForm(values, pathname, step);

      setErrors(errors);

      return {
        values,
        errors: errors || {}
      };
    },
    [pathname, step]
  );

  const methods = useForm({
    mode: "all",
    resolver: handleLoginValidate
  });

  const { formState, control, handleSubmit } = methods;

  const onSubmit = async (event) => {
    return await action(event, step, () => setStep(2));
  };

  const isStepLogin = pathname === "/login";
  const isStepInvite = pathname === "/register" && step === 1;
  const isStepRegister = pathname === "/register" && step === 2;

  useEffect(() => {
    if (!!errors) {
      Object.entries(errors).map(([key, value]) =>
        methods.setError(key, value)
      );
    }
  }, [errors, methods]);

  return (
    <>
      {formState.isSubmitting && <LoadingIndicator isFull={true} />}

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isStepLogin && (
            <>
              <TextInput
                control={control}
                type="email"
                name="email"
                defaultValue=""
                placeholder="Email"
              />

              <TextInput
                control={control}
                type={passwordType}
                name="password"
                placeholder="Senha"
                defaultValue=""
                inputClassGroup={["mb-3", "form-field", "position-relative"]}
                domChildren={(
                  <a href="!#" className="change-password-visibility-icon" onClick={togglePasswordVisibility}>
                    {!passwordVisible && <i class="fa fa-light fa-eye"></i>}
                    {passwordVisible && <i class="fa fa-light fa-eye-slash"></i>}
                  </a>
                )}
              />
              <div className="mb-4 form-field d-flex justify-content-end">
                <a href="#" className="ForgotPasswordButton" onClick={forgotPasswordHandler}>Esqueci minha senha</a>
              </div>
            </>
          )}

          {isStepInvite && (
            <TextInput
              control={control}
              type="text"
              name="inviteCode"
              placeholder="Id do convite"
              defaultValue=""
            />
          )}

          {isStepRegister && (
            <>
              <TextInput
                control={control}
                type="text"
                name="name"
                placeholder="Nome"
                defaultValue=""
              />
              
              <InputMask
                error={errors}
                control={control}
                name={"documentNumber"}
                mask={"999.999.999.99"}
                placeholder="CPF"
              />

              {registerEmail && (
                <TextInput
                  control={control}
                  name="email"
                  type="email"
                  placeholder="email"
                  isDisabled={true}
                  defaultValue={registerEmail}
                />
              )}

              <TextInput
                control={control}
                type="password"
                name="password"
                placeholder="password"
                defaultValue=""
              />

              <TextInput
                control={control}
                type="password"
                name="confirmPassword"
                placeholder="confirm password"
                defaultValue=""
              />
            </>
          )}

          <Form.Group className="mb-3" controlId="submit">
            <Row justify="center" width="100%">
              <Button
                style={{ margin: "0 auto" }}
                type="submit"
                disabled={formState.isSubmitting}
                className="btn btn-primary white-button"
              >
                {step === 1 ? "Seguir" : buttonText}
              </Button>
            </Row>
          </Form.Group>
        </Form>
      </FormProvider>
    </>
  );
};

export default RegisterForm;
