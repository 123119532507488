import { GraphQLClient } from 'graphql-request';
const ORGANIZATION_TABLE_ID = "302417340"

class PipefyService {
  constructor(API_KEY) {
    this.API_KEY = API_KEY;
    this.endpoint = 'https://app.pipefy.com/queries';
  }

  gqlRequest({ document, variables }) {
    const client = new GraphQLClient(this.endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.API_KEY}`
      }
    });

    return client.request(document, variables);
  }
}

export { PipefyService };