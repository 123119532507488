import { useEffect, useState } from "react";
import { useAuth } from "App/components/Auth";
import { useMember } from "App/components/Providers/MemberProvider";
import { Card, Col, Row } from "react-bootstrap";
import { SimplePage } from "App/components/Pages/SimplePage";
import LoadingIndicator from "App/components/LoadingIndicator";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { InviteOrganization as InviteOrganizationContainer } from "App/containers/Invite/Organization"
import iconePageUser from "images/icone-page-user.svg";
import "./styles.scss";
import { getOrganizationById } from "App/services/OrganizationService";

const OrganizationInvitePage = () => {
  const { actions: actionsToast, toast } = useToastily();
  const { user } = useAuth();
  const { member } = useMember();

  return (
    <SimplePage title="Convidar Parceiro" medium icon={iconePageUser}>
      <Toastily {...toast} />
      {!member ? (
        <LoadingIndicator />
      ) : (
        <Card className="card-organization-invite">
          <Row className="card-organization-invite-header">
            <Col className="card-organization-invite-header title">
              <h1 className="L-title">Informações do Parceiro</h1>
            </Col>
          </Row>
          <hr />
          <Row className="card-organization-invite-body">
            <InviteOrganizationContainer hostMember={{user, member}}/>
          </Row>
        </Card>
      )}
    </SimplePage>
  );
};

export default OrganizationInvitePage;
