import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const CopyToClipboardTextInput = ({
  id,
  label,
  name,
  type = "text",
  inputClass = "form-control",
  defaultValue = "",
  inputClassLabel = "input-label bold form-label",
  inputClassGroup = "mb-3 form-field",
  isDisabled = false,
  placeholder,
  helpInformation = "",
  domChildren
}) => {
  const { control } = useForm({ defaultValues: { [name]: defaultValue } });
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const renderTooltip = () => (
    <Tooltip id="button-tooltip" className="copy-to-clipboard-tooltip">
      {isCopied ? "Copiado!" : "Copiar para a Área de Transferência"}
    </Tooltip>
  );

  const renderLabel = () => {
    if (!label) return null;

    return (
      <Form.Label
        htmlFor={id}
        className={`${inputClassLabel} input-label bold form-label`}
      >
        {label}
      </Form.Label>
    );
  };

  const renderHelpInformation = () => {
    if (!helpInformation) return null;

    return <Form.Text className="text-muted">{helpInformation}</Form.Text>;
  };
  return (
    <div className={inputClassGroup}>
      {renderLabel()}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
          <InputGroup>
              <Form.Control
                type={type}
                placeholder={placeholder}
                className={inputClass}
                disabled={isDisabled}
                value={value}
                onChange={onChange}
              />
              <OverlayTrigger placement="right" overlay={renderTooltip()}>
                <button
                  className="input btn copy-to-clipboard"
                  onClick={() => handleCopy(value)}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </OverlayTrigger>
          </InputGroup>
        )}
      />
      {renderHelpInformation()}
      {domChildren}
    </div>
  );
};

CopyToClipboardTextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  inputClass: PropTypes.string,
  defaultValue: PropTypes.string,
  inputClassLabel: PropTypes.string,
  inputClassGroup: PropTypes.string,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helpInformation: PropTypes.string,
  domChildren: PropTypes.node
};

export default CopyToClipboardTextInput;
