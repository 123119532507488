/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHookActions = /* GraphQL */ `
  query GetHookActions($id: ID!) {
    getHookActions(id: $id) {
      id
      userId
      action
      data
      authType
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listHookActions = /* GraphQL */ `
  query ListHookActions(
    $filter: ModelHookActionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHookActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        action
        data
        authType
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncHookActions = /* GraphQL */ `
  query SyncHookActions(
    $filter: ModelHookActionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHookActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        action
        data
        authType
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPhase = /* GraphQL */ `
  query GetPhase($id: ID!) {
    getPhase(id: $id) {
      id
      name
      color
      index
      description
      pipefyPipeId
      pipefyId
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPhases = /* GraphQL */ `
  query ListPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPhases = /* GraphQL */ `
  query SyncPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      welcome
      authType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        welcome
        authType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        welcome
        authType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWhiteLabel = /* GraphQL */ `
  query GetWhiteLabel($id: ID!) {
    getWhiteLabel(id: $id) {
      id
      primary
      secondary
      logo
      background
      tertiary
      type
      partnerName
      Organization {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      active
      path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      whiteLabelOrganizationId
      owner
      __typename
    }
  }
`;
export const listWhiteLabels = /* GraphQL */ `
  query ListWhiteLabels(
    $filter: ModelWhiteLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWhiteLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        primary
        secondary
        logo
        background
        tertiary
        type
        partnerName
        Organization {
          id
          name
          documentNumber
          legalName
          phone
          createdAt
          Members {
            nextToken
            startedAt
            __typename
          }
          Leads {
            nextToken
            startedAt
            __typename
          }
          UserInvites {
            nextToken
            startedAt
            __typename
          }
          pipefyId
          Simulations {
            nextToken
            startedAt
            __typename
          }
          image
          address
          complement
          city
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        active
        path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        whiteLabelOrganizationId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWhiteLabels = /* GraphQL */ `
  query SyncWhiteLabels(
    $filter: ModelWhiteLabelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWhiteLabels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        primary
        secondary
        logo
        background
        tertiary
        type
        partnerName
        Organization {
          id
          name
          documentNumber
          legalName
          phone
          createdAt
          Members {
            nextToken
            startedAt
            __typename
          }
          Leads {
            nextToken
            startedAt
            __typename
          }
          UserInvites {
            nextToken
            startedAt
            __typename
          }
          pipefyId
          Simulations {
            nextToken
            startedAt
            __typename
          }
          image
          address
          complement
          city
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        active
        path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        whiteLabelOrganizationId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSimulation = /* GraphQL */ `
  query GetSimulation($id: ID!) {
    getSimulation(id: $id) {
      id
      propertyValue
      loanValue
      monthlyIncome
      loanDate
      skipMonth
      gracePeriod
      linkPDFSimulation
      memberId
      organizationId
      term
      dayDue
      type
      legalPerson
      entranceValue
      table
      itbi
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listSimulations = /* GraphQL */ `
  query ListSimulations(
    $filter: ModelSimulationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSimulations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSimulations = /* GraphQL */ `
  query SyncSimulations(
    $filter: ModelSimulationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSimulations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserInvite = /* GraphQL */ `
  query GetUserInvite($id: ID!) {
    getUserInvite(id: $id) {
      id
      guestId
      hostId
      organizationId
      status
      authType
      email
      organizationName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserInvites = /* GraphQL */ `
  query ListUserInvites(
    $filter: ModelUserInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        guestId
        hostId
        organizationId
        status
        authType
        email
        organizationName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserInvites = /* GraphQL */ `
  query SyncUserInvites(
    $filter: ModelUserInviteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        guestId
        hostId
        organizationId
        status
        authType
        email
        organizationName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      pipefyId
      createdAt
      updatedAt
      name
      phone
      email
      value
      documentNumber
      organizationId
      memberId
      Simulation {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      type
      phaseID
      Phase {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      leadSimulationId
      owner
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pipefyId
        createdAt
        updatedAt
        name
        phone
        email
        value
        documentNumber
        organizationId
        memberId
        Simulation {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        type
        phaseID
        Phase {
          id
          name
          color
          index
          description
          pipefyPipeId
          pipefyId
          Leads {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        leadSimulationId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pipefyId
        createdAt
        updatedAt
        name
        phone
        email
        value
        documentNumber
        organizationId
        memberId
        Simulation {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        status
        type
        phaseID
        Phase {
          id
          name
          color
          index
          description
          pipefyPipeId
          pipefyId
          Leads {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        leadSimulationId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name
      documentNumber
      phone
      createdAt
      birthDate
      organizationId
      email
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      avatar
      userId
      welcome
      address
      complement
      city
      authType
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        documentNumber
        phone
        createdAt
        birthDate
        organizationId
        email
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        avatar
        userId
        welcome
        address
        complement
        city
        authType
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMembers = /* GraphQL */ `
  query SyncMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        documentNumber
        phone
        createdAt
        birthDate
        organizationId
        email
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        avatar
        userId
        welcome
        address
        complement
        city
        authType
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      documentNumber
      legalName
      phone
      createdAt
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserInvites {
        items {
          id
          guestId
          hostId
          organizationId
          status
          authType
          email
          organizationName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pipefyId
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      image
      address
      complement
      city
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
