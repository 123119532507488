import FormWizard from "../FormWizard/FormWizard";
import "./styles.scss";

const FormSection = ({ children }) => {
  return (
    <div className="FormSection">
      { children }
    </div>
  );
};

const Title = ({title}) => !!title && <h2 className="L-title contact-title">{title}</h2>
FormSection.Title = Title

const Wizard = ({fields, inputClass}) => !!fields && <FormWizard fields={fields} inputClass={inputClass} />
FormSection.Wizard = Wizard

export default FormSection
