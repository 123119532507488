import SimulateFI from "./SimulateFI";
import SimulateHE from "./SimulateHE";

const SimulateFlow = ({ ...props }) => {
  const { simulation, children } = props;
  
  if (!simulation) return children({ flow: null })
  
  return simulation.type === "HE" ? (
    <SimulateHE {...props} />
  ) : (
    <SimulateFI {...props} />
  );
};

export default SimulateFlow;