
export const Header = ({ list, showCheckbox }) => {
  return (
    <thead className="list-header">
      <tr className="list-header-item">
        {list.map((item, index) => (
          <th className="list-header-item cell" key={index}>
            {item}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export const Data = ({ value, keyName, cellValue, item, onItemClick }) => {
  const classNames = [];

  const isClickble = !!onItemClick;

  if (isClickble) {
    classNames.push("cell__clickble");
  }

  const styles = classNames.join(" ");

  return (
    <td
      className={"list-items-item cell ".concat(styles)}
      key={`${keyName}_${item.id}`}
      onClick={({ target, currentTarget }) => {
        if (target === currentTarget && onItemClick) {
          onItemClick({ target, cellValue: value, item, keyName })
        }
      }
      }
    >
      {cellValue}
    </td>
  );
};


export const Items = ({
  list,
  children,
  onItemClick,
  handleFormatCell = ({ cellValue }) => cellValue,
  showColumns,
  showCheckbox=false
}) => {
  const getCellProperties = (line) => Object.entries(line);

  return (
    list?.length > 0 && (
      <tbody className="list-items">
        {list.map((line, index) => (
          <tr className="list-items-item" key={line.id}>
            {showColumns?.length &&
              showColumns.map(({ key }, i) => {
                //if (key === "id") return null;

                const curateCell = handleFormatCell({
                  keyName: key,
                  cellValue: line[key],
                  item: line,
                  index: i
                });

                return children({
                  keyName: key,
                  cellValue: curateCell,
                  item: line,
                  value: line[key],
                  onItemClick
                });
              })}
            {!showColumns?.length &&
              getCellProperties(line).map(([keyName, cellValue], i) => {
                if (keyName === "id") return null;

                const curateCell = handleFormatCell({
                  keyName,
                  cellValue,
                  item: line,
                  index: i
                });

                return children({
                  keyName,
                  cellValue: curateCell,
                  item: line,
                  value: cellValue,
                  onItemClick
                });
              })}
          </tr>
        ))}
      </tbody>
    )
  );
};

export const TopInfo = ({ children }) => {
  return <div className="list-topinfo">{children}</div>;
};

export const Footer = ({ children }) => {
  return <div className="list-footer">{children}</div>;
};

export const EmptyList = () => {
  return <div className="empty-list"> Não existem itens para mostrar. </div>;
};
