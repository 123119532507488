import { useState } from "react";
import ChangePasswordCode from "../../components/Auth/Forms/ForgotPasswordForm/ChangePasswordCode";
import { ForgotPasswordForm } from "../../components/Auth/Forms/ForgotPasswordForm";

const ForgotPasswordContainer = () => {
  const [showSendCodeForm, setShowSendCodeForm] = useState(true)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [username, setUsername] = useState("")
  const onCodeSendHandler = (value) => {
    setUsername(value.email)
    setShowSendCodeForm(false)
  }


  return (
    <div>
      {(showSendCodeForm && !passwordChanged) && (
        <ForgotPasswordForm onCodeSendHandler={onCodeSendHandler}/>
      )}
      {(!showSendCodeForm && !passwordChanged) && (
        <ChangePasswordCode username={username} handlePasswordChange={() => {setPasswordChanged(true)}}/>
      )}
      {passwordChanged && <p>Senha modificada com sucesso.</p>}
    </div>
  )
}

export default ForgotPasswordContainer;