import { SimplePage } from "../../../components/Pages/SimplePage";
import { LeadsList } from "App/containers/Leads/List";
import simulationIcon from "../../../../images/simulations-icon.svg"


const Leads = () => {
  return (
    <SimplePage small={true} icon={simulationIcon} title="Histórico de leads" description="Acompanhe aqui os leads que você indicou:">
      <LeadsList />
    </SimplePage>
  )
};

export default Leads;
