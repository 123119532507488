/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { getUserInitialRoute } from "../../../helpers/navigation";
import { subscribeToModel } from "../../../helpers/dataStore";
import { handleCompleteSignup, handleErrorSignup } from "./helpers";

import { useAuth } from "../../../components/Auth";
import { useToastily } from "../../../components/Toastily/useToastily";

import Toastily from "../../../components/Toastily/Toastily";
import FormPage from "../../../components/Auth/FormPage";
import ConfirmationCodeModal from "../../../components/ConfirmationCodeModal";
import LoadingIndicator from "../../../components/LoadingIndicator";

import "./styles.scss";

const RegisterPage = () => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [userInvite, setUserInvite] = useState(null);
  const [registerEmail, setRegisterEmail] = useState();
  const [willResendCode, willSetResendCode] = useState(false);

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const { toast, actions } = useToastily();

  const [userInviteId, setUserInviteId] = useState(
    searchParams.get("userInviteId")
  );

  const triggerInvite = (invite) => {
    if (invite.length === 0)
      actions.setError("Convite não encontrado ou expirado");

    setUserInvite(invite[0]);
    setLoading(false);
  };

  useEffect(() => {
    if (userInviteId) {
      setLoading(true);

      subscribeToModel({
        model: "UserInvite",
        filter: (ui) => ui.id("eq", userInviteId).status("eq", "pending"),
        callback: triggerInvite
      });
    }
  }, [userInviteId]);

  const navigate = useNavigate();
  let location = useLocation();

  const route = getUserInitialRoute(auth.user);

  let from = location.state?.from?.pathname || route;
  const navigateFrom = () => navigate(from, { replace: true });

  const rightSideAction = () => {
    navigateFrom();
  };

  const leftSideAction = async (event, step, setStep2) => {
    const isStepOne = step === 1;
    const isStepTwo = step === 2;

    setRegisterEmail(event.email);

    const onStepOne = () => {
      setUserInviteId(event.inviteCode);
      if (userInvite?.length > 0) setStep2();
    };

    const onStepTwo = () => {
      const onSuccess = ({ userId }) => {
        handleCompleteSignup({
          user: { ...event, userId },
          userInvite,
          openModal: setOpenModalConfirmation
        });
      };

      const onError = ({ code }) =>
        handleErrorSignup(code, () => {
          setOpenModalConfirmation(true);
          willSetResendCode(true);
        });

      auth.signup(event, onSuccess, onError);
    };

    if (isStepOne) onStepOne();
    else if (isStepTwo) onStepTwo();
  };

  const props = {
    headerText: "Faça agora o seu cadastro",
    left: {
      invite: userInvite,
      title: "Ainda não tem uma conta?",
      buttonText: "Cadastrar",
      action: (...args) => leftSideAction(...args)
    },
    right: {
      title: "Já está cadastrado?",
      buttonText: "Entrar",
      action: () => rightSideAction()
    },
    children: ""
  };

  return (
    <>
      {loading && <LoadingIndicator isFull={true} />}
      <Toastily {...{ ...toast }} />
      <ConfirmationCodeModal
        {...{
          toast: actions,
          email: registerEmail,
          userInviteId,
          showModal: openModalConfirmation,
          onHideModal: (_) => _,
          willResendCode
        }}
      />
      <FormPage {...props} />
    </>
  );
};

export default RegisterPage;
