import ROUTES from "../../../routes";
import { useEffect, useState } from "react";
import { useMember } from "App/components/Providers/MemberProvider";
import { getMemberAvatar } from "App/helpers/storage/memberAvatar";

const Member = ({ children, user, signout }) => {
  const [avatar, setAvatar] = useState()
  const {member} = useMember()
  useEffect( async () => {
    if (member?.id) {
      const avatarUrl = await getMemberAvatar(member.id + ".png")
      setAvatar(avatarUrl)
    }
  }, [member])

  const navs = [
    "Página inicial",
    "Simuladores",
    "Cadastro de operações",
    "Histórico de simulações",
    "Materiais de Apoio"
  ];

  const props = {
    navLinks: ROUTES.reduce((a, route) => {
      if (navs.includes(route.title)) {
        route.items = !!route.items ? route.items : [];

        a.push({
          title: route.title,
          route: route.path,
          items: route.items.map((t) => ({ ...t, route: t.path }))
        });
      }
      return a;
    }, []).sort((a, b) => navs.indexOf(a.title) - navs.indexOf(b.title)),
    userData: {
      avatar: avatar,
      logo: user?.logo,
      name: user?.name
    },
    items: [
      {
         title: "Minha conta",
         route: "/private/profile"
      },
      {
        title: "Sair",
        onClick: (event) => {
          event.preventDefault();
          signout();
        }
      }
    ]
  };

  return <>{children(props)}</>;
};

export default Member;
