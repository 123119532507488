import { default as ReactBootstrapBreadcrumb } from 'react-bootstrap/Breadcrumb';

const Breadcrumb = ({crumbs}) => {
  return (
    <ReactBootstrapBreadcrumb>
      {crumbs?.length && crumbs.map( (crumb, index) => {
        return (
           <ReactBootstrapBreadcrumb.Item 
           href={crumb.url} 
           active={crumb.active}
           key={"#" + index + "-" + crumb.text }>
            {crumb.text}
          </ReactBootstrapBreadcrumb.Item>
        )
      } )}
    </ReactBootstrapBreadcrumb>
  )
}

export {
  Breadcrumb
}
