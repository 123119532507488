import React from "react";
import TableItem from "./TableItem";

const TableBody = ({ body, total, items }) => {
  const totalInstallments = items.length;

  const lastInstallment = (index) =>
    index > 0 ? items[index - 1] : null;

  const toggleInvoiceModal = async (installmentIndex) => {
    const { openInvoiceModal } = this.state;
    await this.setState({
      openInvoiceModal: !openInvoiceModal,
      installmentIndex
    });
  };

  return (
    <>
      <section className="dash-installments-table-body">
        <div className="body-block-container">
          {items.map((installment, i) => {
            return (
              <div className={`row installment row purple bold`} key={i}>
                <div className="left row placeholder" />

                <div className="box-container center">
                  <TableItem
                    toggleInvoiceModal={toggleInvoiceModal}
                    installment={installment}
                    index={i}
                    lastInstallment={lastInstallment(i)}
                    body={body}
                    total={total}
                    totalInstallments={totalInstallments}
                  />
                </div>
                <div className="row right placeholder" />
              </div>
            );
          })}
        </div>
        <div className="row fadeout" />
      </section>
    </>
  );
};

export default TableBody;
