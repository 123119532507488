import React from "react";
import { useFormContext } from "react-hook-form";
import InputMonetary from "../FormInputs/Bootstrap/InputMonetary";
import LargeTextInput from "../FormInputs/Bootstrap/LargeTextInput";
import Checkbox from "../FormInputs/Bootstrap/Checkbox";
import InputCode from "../FormInputs/InputCode";

import {
  Avatar,
  Radio,
  Search,
  Select,
  Document,
  Date,
  Email,
  Password,
  Phone,
  TextInput
} from "../FormInputs";
import CopyToClipboard from "../FormInputs/CopyToClipboard/CopyToClipboard";

const FormWizard = ({ fields, inputClass = "" }) => {
  const methods = useFormContext();

  const types = {
    email: (field) => <Email {...field} />,
    radio: (field) => <Radio {...field} />,
    select: (field) => <Select {...field} />,
    money: (field) => <InputMonetary {...field} />,
    short_text: (field) => <TextInput {...field} />,
    large_text: (field) => <LargeTextInput {...field} />,
    checkbox: (field) => <Checkbox {...field} />,
    date: (field) => <Date {...field} />,
    password: (field) => <Password {...field} />,
    cpfDocument: (field) => <Document {...{ ...field, formType: "PF" }} />,
    cnpjDocument: (field) => <Document {...{ ...field, formType: "PJ" }} />,
    phone: (field) => <Phone {...field} />,
    avatar: (field) => <Avatar {...field} />,
    search: (field) => <Search {...field} />,
    code: (field) => <InputCode {...field} />,
    CopyToClipboard: (field) => <CopyToClipboard {...field} />
  };

  return (
    <div className={inputClass}>
      {fields.map((field) =>
        types[field.type]({
          ...field,
          ...methods,
          name: field.id,
          key: field.id + field.name
        })
      )}
    </div>
  );
};

export default FormWizard;
