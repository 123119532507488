/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SimplePage } from "../../../components/Pages/SimplePage"
import { Modal } from "../../../components/Modal"
import { ForgotPasswordForm } from "../../../components/Auth/Forms/ForgotPasswordForm"
import ForgotPasswordContainer from "../../../containers/Auth/ForgotPassword";

import {
  getUserInitialRoute,
  isFirstAccess,
  getFirstAccessRoute,
  disableFirstAccess
} from "../../../helpers/navigation";
import { subscribeToModel } from "../../../helpers/dataStore";

import { useAuth } from "../../../components/Auth";
import { useMember } from "../../../components/Providers/MemberProvider";
import { useToastily } from "../../../components/Toastily/useToastily";

import FormPage from "../../../components/Auth/FormPage";
import Toastily from "../../../components/Toastily/Toastily";
import ConfirmationCodeModal from "../../../components/ConfirmationCodeModal";
import LoadingIndicator from "../../../components/LoadingIndicator";

import { ImageStick } from "../../../components/ImageStick";
import illustration_1 from "../../../../images/illustrations/illustration_1.svg"
import illustration_2 from "../../../../images/illustrations/illustration_2.svg"


import "./styles.scss";

const triggerNavigate = async ({ member, user }, cb) => {
  const firstAccess = await isFirstAccess(member);

  const route = firstAccess
    ? getFirstAccessRoute(member)
    : getUserInitialRoute(user);

  if (firstAccess) {
    disableFirstAccess(member);
  }

  cb(route);
};

const LoginPage = () => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [userInviteId, setUserInviteId] = useState(null);
  const [email, setEmail] = useState();
  const [willResendCode, setWillResendCode] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const { member } = useMember();
  const { toast, actions } = useToastily();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user.id && member) {
      triggerNavigate({ member, user: auth.user }, (route) => navigate(route));
    }
  }, [auth.user, member]);

  useEffect(() => {
    if (userInviteId) {
      setOpenModalConfirmation(true);
      setWillResendCode(true);
    }
  }, [userInviteId]);

  const handleGetUserInviteId = async (memberEmail) => {
    subscribeToModel({
      model: "Member",
      filter: (m) => m.email("eq", memberEmail),
      callback: (m) =>
        subscribeToModel({
          model: "UserInvite",
          filter: (invite) =>
            invite.guestId("eq", m[0].id).status("eq", "registered"),
          callback: (invite) => setUserInviteId(invite[0].id)
        })
    });
  };

  const props = {
    headerText: "",
    left: {
      title: "Login",
      buttonText: "ENTRAR",
      action: (event) => {
        setLoading(true);

        const onSuccess = () => {
          setLoading(false);
        };

        const onError = ({ code }) => {
          console.log("onError", code);
          if (code === "UserNotConfirmedException") {
            setEmail(event.email);
            handleGetUserInviteId(event.email);
          }
          setLoading(false);
        };

        auth.signin(event, onSuccess, onError);
      },
      forgotPasswordHandler: () => {
        setShowForgotPassword(true)
      },
    },
    right: {
      title: "Ainda não tem uma conta?",
      buttonText: "CADASTRE-SE",
      action: () => navigate("/register")
    },
    children: []
  };

  return (
    <SimplePage classNames={["LoginPage"]} extraLarge>
      {loading && <LoadingIndicator isFull={true} />}
      <Toastily {...{ ...toast }} />
      <ConfirmationCodeModal
        {...{
          toast: actions,
          email,
          userInviteId,
          showModal: openModalConfirmation,
          onHideModal: () => setOpenModalConfirmation(false),
          willResendCode
        }}
      />
      <FormPage {...props} />
      <Modal 
        title="Recuperação de senha"
        body={ <ForgotPasswordContainer /> }
        show={showForgotPassword}
        closeButton={true}
        onHide={ () => {setShowForgotPassword(false)} }
      />
      <ImageStick position={ {right: "0px", top: "149px"} } size={ {width: "291px", height: "278px"} } offset={ {right: "-84px"} } src={illustration_1}/>
      <ImageStick position={ {right: "380px", bottom: "0px"} } size={ {width: "238px", height: "380px"} } src={illustration_2}/>
    </SimplePage>
  );
};

export default LoginPage;
