import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAuth } from "../../../../components/Auth/useAuth";
import "./styles.scss";
import FormWizard from "../../../../components/Form/FormWizard/FormWizard";
import { useMember } from "../../../../components/Providers/MemberProvider";

const fields = [
  {
    id: "email",
    name: "email",
    label: "",
    type: "email",
    options: []
  }
];

const FormInvite = ({ triggerSendInvite, actions: actionsToast }) => {
  const { user } = useAuth();
  const { member } = useMember();

  const methods = useForm({ mode: "all" });

  const { handleSubmit, isSubmitting } = methods;

  const onSubmit = async (event) => {
    try {
      await triggerSendInvite({ user, member }, event.email);
      actionsToast.setSuccess("Convite enviado com sucesso!");
    } catch (err) {
      console.log("error in userInvite", { err });
      actionsToast.setError("Não foi possível enviar o convite.");
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className="form-user-invite">
        <FormWizard {...{ fields }} />
        <Button
          style={{ margin: "0 auto" }}
          type="submit"
          className="btn btn-primary white-button btn-user-invite"
          disabled={isSubmitting}
        >
          Enviar
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1" />
          )}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default FormInvite;
