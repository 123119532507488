import { DataStore } from "@aws-amplify/datastore";
import { CONFIG } from "App/config/config";
import { Organization } from "../../models";
import { PipefyService } from "./integrations/PipefyClient";
import { createTableRecord } from "./integrations/mutations";
const API_KEY = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VyIjp7ImlkIjozMDEyNjA0NzYsImVtYWlsIjoiZGV2QHBvbnR0ZS5jb20uYnIiLCJhcHBsaWNhdGlvbiI6MzAwMjEyMzk2fX0.iGh6T7W-nvhk-wA3JwH24HUAUHoGBxAVn_vfnx_nmy7XMGrK9_PZpfc9UBv4oU0B29756zGshKqyWRc921zJZg";
const ORGANIZATION_TABLE_ID = "302417340";

const getOrganizationById = async (id) => {
  if (!id) throw new Error("Invalidate id");
  return await DataStore.query(Organization, id);
};

const getOrganizationByDocumentNumber = async (documentNumber) => {
  if (!documentNumber) throw new Error("Invalidate documentNumber");

  return (
    await DataStore.query(Organization, (organization) => {
      return organization.documentNumber("eq", documentNumber);
    })
  ).pop();
};

const saveOrganization = ({ email, name, documentNumber, legalName, phone }) =>
  DataStore.save(
    new Organization({
      email,
      name,
      documentNumber,
      legalName,
      phone
    })
  );

const createOrganization = ({ name, documentNumber, legalName, phone }) => {
  return DataStore.save(
    new Organization({
      name,
      documentNumber,
      legalName,
      phone
    })
  );
};

const updateOrganization = async (id, data) => {
  const currentOrganization = await getOrganizationById(id);

  const response = DataStore.save(
    Organization.copyOf(currentOrganization, (organization) => {
      organization.name = data.name;
      organization.legalName = data.legalName;
      organization.documentNumber = data.documentNumber;
      organization.pipefyId = data.pipefyId;
      organization.phone = data.phone;
      organization.image = data.image;
      organization.address = data.address;
      organization.complement = data.complement;
      organization.city = data.city;
    })
  );

  return response;
};

const saveOnPipefy = async ({ organization, member }) => {
  const pipefy = new PipefyService(API_KEY);
  console.log("PIPEFY TABLE");
  console.log(CONFIG.PIPEFY_ORGANIZATION_TABLE);
  const fieldToSave = buildTableRecords({ organization, member });
  const {
    createTableRecord: {
      table_record: {
        id
      }
    }
  } = await pipefy.gqlRequest({
    document: createTableRecord,
    variables: {
      tableId: CONFIG.PIPEFY_ORGANIZATION_TABLE,
      fieldsAttributes: fieldToSave.filter((attr) => attr.field_value !== "")
    }
  });

  return id;
};

const buildTableRecords = ({
  organization: {
    id = "",
    name = "",
    legalName = "",
    documentNumber = ""
  },
  member: {
    name: memberName = "",
    email: memberEmail = "",
    documentNumber: memberDocumentNumber = ""
  }
}) => [
  {
    field_id: "name",
    field_value: name
  },
  {
    field_id: "legalname",
    field_value: legalName
  },
  {
    field_id: "documentnumber",
    field_value: documentNumber
  },
  {
    field_id: "member_name",
    field_value: memberName
  },
  {
    field_id: "member_email",
    field_value: memberEmail
  },
  {
    field_id: "member_documentnumber",
    field_value: memberDocumentNumber
  },
  {
    field_id: "organization_id",
    field_value: id
  }
];

export {
  getOrganizationById,
  saveOrganization,
  getOrganizationByDocumentNumber,
  updateOrganization,
  createOrganization,
  saveOnPipefy
};
