import React from "react";
import { useAuth } from "App/components/Auth";
import { Col, Container, Row } from "react-bootstrap";
import Btn from "App/components/Btn";
import "./styles.scss";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { validationSchema } from "App/containers/Members/validations";
import OrganizationSelectInput from "./OrganizationSelectInput";
import {
  Avatar,
  Document,
  Email,
  Phone,
  Select,
  TextInput
} from "App/components/Form/FormInputs";
import CopyToClipboard from "App/components/Form/FormInputs/CopyToClipboard/CopyToClipboard";

const defaultValues = (member, invite) => {

  return {
    name: member?.name || "",
    email: member?.email || "",
    documentNumber: member?.documentNumber || "",
    phone: member?.phone || "",
    invite: invite || "",
    cognitoGroups: ""
    // Add other fields as needed
  };
};
// Constants for options and default values
const DEFAULT_OPTION = { label: "Selecione...", value: "" };
const MEMBER_OPTION = { label: "Member", value: "Member" };
const ADMIN_OPTION = { label: "Admin", value: "Admin" };
const OPTIONS = [DEFAULT_OPTION, MEMBER_OPTION, ADMIN_OPTION];

const getCognitoGroupDefaultValue = (data) => {
  const { cognitoGroups } = data;
  return cognitoGroups.includes("Admin");
};

const CreateForm = ({
  isPontteUserWithOrganization,
  guestUserPermission,
  onSubmit,
  member,
  invite
}) => {
  console.log({ isPontteUserWithOrganization });

  const { user: hostUser } = useAuth();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues(member, invite)
  });

  const { handleSubmit, formState } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col className="form-records d-flex">
              <Row className="d-flex justify-content-lg-center">
                <Col className="col-lg-8">
                  {isPontteUserWithOrganization && (
                    <OrganizationSelectInput member={member} />
                  )}
                  <Row>
                    <Col>
                      <TextInput
                        id="name"
                        name="name"
                        label="Nome:*"
                        type="short_text"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                      <Email
                        id="email"
                        name="email"
                        label="Email:*"
                        type="email"
                        inputClassLabel={["bold", "col-lg-12"]}
                        {...methods}
                      />
                    </Col>
                    <Col>
                      <Document
                        id="documentNumber"
                        name="documentNumber"
                        label="CPF:*"
                        type="cpfDocument"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                      <Phone
                        id="phone"
                        name="phone"
                        label="Telefone:"
                        type="phone"
                        inputClassLabel={["bold"]}
                        {...methods}
                      />
                    </Col>
                  </Row>
                  <Row className={"my-5"}>
                    <Col>
                      <Row>
                        <CopyToClipboard
                          {...{
                            id: "invite",
                            name: "invite",
                            label: "Link de Cadastro:",
                            inputClassLabel: ["bold"],
                            defaultValue: invite,
                            invite,
                            value: invite
                          }}
                        />
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Select
                          id="cognitoGroups"
                          name="cognitoGroups"
                          label="Grupo de Permissão:"
                          type="select"
                          inputClassLabel={["bold"]}
                          isDisabled={!getCognitoGroupDefaultValue(hostUser)}
                          defaultValue={guestUserPermission}
                          options={OPTIONS}
                          {...methods}
                        />
                        {/*<CognitoGroupSelectInput*/}
                        {/*  user={user}*/}
                        {/*  member={member}*/}
                        {/*  gestUser={gestUser}*/}
                        {/*  invite={invite}*/}
                        {/*/>*/}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="col-lg-4 d-flex align-items-center"
                  style={{ flex: 1 }}
                >
                  <Avatar
                    id="avatar"
                    name="avatar"
                    label=""
                    type="avatar"
                    inputClassLabel={["bold"]}
                    classNames={["large"]}
                    {...methods}
                  />
                </Col>
              </Row>
            </Col>
            <div className="d-flex justify-content-start mb-4">
              <Btn
                title="Salvar"
                classNames={["primary", "pill"]}
                loading={formState.isSubmitting}
              />
            </div>
          </Row>
        </Container>
      </form>
    </FormProvider>
  );
};

export default CreateForm;
