import { useState } from "react"
import SearchDate from "../search/SearchDate/SearchDate"
import SearchList from "../search/searchList/SearchList"
import { FormProvider, useForm } from "react-hook-form";
import { Search, Select } from "../../components/Form/FormInputs"
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker"
import pt from 'date-fns/locale/pt';
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss"

registerLocale("es", pt)

const HeaderFilterSimple = ({list, nameColumn, namePlaceholder = "Pesquisar usuário", statusColumn, dateColumn, statusOptions, statusPlaceholder}) => {
  const methods = useForm({ mode: "all" });
  const { watch } = methods;
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [nameTerm, statusTerm] = watch(["name", "status", "date"])
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <>
    <div className="FilterablePaginatedList__filters">
        <FormProvider {...methods}>
        <div className="FilterablePaginatedList__filtersLeft">
          <Search id="name" name="name" placeholder={namePlaceholder}/>
          <SearchList itemList={list} keys={[nameColumn]} term={nameTerm}>
          </SearchList>
        </div>
        <div className="FilterablePaginatedList__filtersRight">
          <div>
            <Select multiSelect={true} name="status" options={statusOptions} {...methods} placeholder={statusPlaceholder}/>
            <SearchList itemList={list} keys={[statusColumn]} term={statusTerm}>
            </SearchList>
          </div>
          <div className="position-relative">
            <ReactDatePicker
              {...methods}
              onChange={onChangeDate}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              locale="es"
              placeholderText="Filtrar data"
            />
            <i class="fa fa-solid fa-calendar-days icon-calendar"></i>            
            <SearchDate itemList={list} key={dateColumn} startDate={startDate?.toString()} endDate={endDate?.toString()} novaChave={dateColumn}>
            </SearchDate>
          </div>
        </div>
      </FormProvider>
    </div>          
    </>
  )
}

export default HeaderFilterSimple;