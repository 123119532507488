import { useEffect, useState, useContext } from "react";
import { FilterableSearchContext } from "../Context";

const triggerFilteredItems = ({ items, dateRange, key, cb }) => {
  const filtered = items.filter((item) => {
    const targetDate = new Date(item[key]);
    const { endDate, startDate } = dateRange;
    if (!isNaN(startDate) && !isNaN(endDate)) {
      return targetDate >= startDate && targetDate <= endDate;
    } else if (!isNaN(startDate) && !!isNaN(endDate)) {
      return targetDate >= startDate;
    } else if (!!isNaN(startDate) && !isNaN(endDate)) {
      return targetDate <= startDate;
    }
  });

  return filtered;
};

const SearchDate = ({ itemList, startDate, endDate, dateRange, children, novaChave = "default" }) => {
  const [filtered, setFiltered] = useState([]);
  const {setResult} = useContext(FilterableSearchContext)

  useEffect(() => {
    if (novaChave && (startDate || endDate)) {
      const filteredList = triggerFilteredItems({
        key: novaChave,
        dateRange: {startDate: new Date(startDate), endDate: new Date(endDate)},
        items: itemList
      });
      setFiltered(filteredList)
    }

  }, [startDate, endDate]);

  useEffect(() => {
    setResult && setResult(filtered)
  }, [filtered]);

 return children ? children(filtered) : null;
};

export default SearchDate;
