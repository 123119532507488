import { createContext } from "react";
import { AuthProvider } from "../../Auth";
import { MemberProvider } from "../MemberProvider";

export const MemberContext = createContext();

const AuthMemberProvider = ({ children }) => {
  return (
    <AuthProvider>
      <MemberProvider>{children}</MemberProvider>
    </AuthProvider>
  );
};

export default AuthMemberProvider;
