export const organizationFields = [
  {
    id: "name",
    name: "name",
    label: "Razao Social:*",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "legalName",
    name: "legalName",
    label: "Nome Fantasia:*",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "documentNumber",
    name: "documentNumber",
    label: "CNPJ:*",
    type: "cnpjDocument",
    inputClassLabel: ["bold"],
  },
];

export const organizationContactFields = [
  {
    id: "address",
    name: "address",
    label: "Endereço:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "complement",
    name: "complement",
    label: "Complemento:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "city",
    name: "city",
    label: "Cidade:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "phone",
    name: "phone",
    label: "Telefone:",
    type: "phone",
    inputClassLabel: ["bold"],
  }
];

export const organizationAvatarFields = [
  {
    id: "image",
    name: "image",
    label: "Logo da sua empresa",
    type: "avatar",
    inputClassLabel: ["bold"],
    classNames: ['medium']
  }
];

export const organizationWhiteLabelFields = [
  {
    id: "primary",
    name: "primary",
    label: "Cor principal:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "secondary",
    name: "secondary",
    label: "Cor secundaria:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "tertiary",
    name: "tertiarya",
    label: "Cor terciaria:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "background",
    name: "background",
    label: "Cor do fundo:",
    type: "short_text",
    inputClassLabel: ["bold"],
  }
];
