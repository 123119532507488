import { useNavigate } from "react-router-dom";

const Basic = ({ children }) => {
  const navigate = useNavigate();

  const props = {
    login: false,
    loginButton: {
      title: "LOGIN",
      onClick: () => navigate("/login"),
      classNames: ["secondary", "pill"]
    },
    navLinks: [],
    title: "Valendo"
  };

  return <>{ children(props) }</>;
};

export default Basic;
