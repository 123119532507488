import React from "react";

const TableItem = ({ installment: installments, body }) => (
  <div className="grid-container row-gap ">
    {body.map((item) => (
      <div key={item} className={`item purple`}>
        <span>{installments[item]}</span>
      </div>
    ))}
  </div>
);

export default TableItem;
