import { formatGracePeriod, formatMonth } from "./formats"

const FIMapKeys = {
  "propertyValue": "propertyvalue",
  "loanValue": "loanvalue",
  "monthlyIncome": "monthlyincome",
  "loanDate": "loandate",
  "skipMonth": "skipmonth",
  "gracePeriod": "graceperiod",
  "linkPDFSimulation": "linkpdfsimulation",
  "term": "numero_de_parcelas"
}

const renameKeysOfObject = (object, newNamesMap) => {
  let objectWithRenamedKeys = Object.keys(newNamesMap).reduce( (accumulator, objectKey) => {
    accumulator[ newNamesMap[objectKey] ] = object[objectKey]
    return accumulator
  }, {})

  return objectWithRenamedKeys
}

const fixPipefyDate = (dateString) => {
  let [year, month, day] = dateString.split('-') // Current saved value is YYYY-MM-DD

  return `${day}${month}${year.substr(2)}`
}

export const simulationToFiLead = (simulation) => {
  const leadData = renameKeysOfObject(simulation, FIMapKeys)

  leadData['graceperiod'] = formatGracePeriod(leadData['graceperiod']);
  leadData['skipmonth'] = formatMonth(leadData['skipmonth']);
  leadData['loandate'] = leadData['loandate'] ? fixPipefyDate(leadData['loandate']) : "";

  return leadData
}