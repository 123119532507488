import pdfMake from "pdfmake/build/pdfmake";

import { footer, header } from "./components/header-footer";
import { introductionPage } from "./Pages/introduction";

import { proposalPage } from "./Pages/proposal";

import { debtsPage } from "./components/debts";
import { installmentsPage } from "./components/installments";
import { signature } from "./components/signature";
import { glossaryPage } from "./components/glossary";
import { ColorScheme } from "./components/constants";
import fonts from "./utils/fonts";
const { $MAIN_DARK } = ColorScheme;

const buildContent = ({ proposal, contract, final }) => {
  const content = [
    introductionPage({ proposal, user: contract.user }),
    proposalPage({ proposal, final }),
    debtsPage({ contract, proposal }),
    installmentsPage({ proposal })
  ];

  if (final) {
    content.push(signature({ contract }));
  }

  content.push(...glossaryPage());

  return content;
};

const getDocDefinition = ({ proposal, contract, final }) => ({
  content: buildContent({ proposal, contract, final }),
  footer,
  header: () => header({ user: contract.user }),
  pageSize: "A4",
  pageMargins: [40, 80, 40, 80],
  styles: {
    proposalTable: {
      margin: [15, 15, 15, 15],
      fontSize: 8,
      paddingLeft: 5
    }
  },
  defaultStyle: {
    font: "Roboto",
    color: $MAIN_DARK
  }
});

const generateProposalPdf = async ({ proposal, contract, final }) => {
  const docDefinition = getDocDefinition({ proposal, contract, final });

  const pdfBuffer = await new Promise((resolve) => {
    const doc = pdfMake.createPdf(docDefinition, null, fonts);

    doc.download(`Proposta`, resolve, {});
  });

  return pdfBuffer;
};

export { generateProposalPdf };
