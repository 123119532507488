import { updateMember } from "../services/MemberService";

export const getUserInitialRoute = (user) => {
  const isAdmin = user.cognitoGroups?.includes("Pontte_Users");
  return isAdmin ? "/private/dashboard" : "/private/help";
};

export const isFirstAccess = async (member) => {
  return member.welcome === undefined || member.welcome === null
    ? true
    : member.welcome;
};

export const disableFirstAccess = async (member) => {
  try {
    updateMember(member.id, { welcome: false });
  } catch (error) {
    console.log("Error on disable first access: ", { member });
  }
};

export const getFirstAccessRoute = () => {
  return "/private/help";
};

export const generateCrumbsFromPath = (path) => {
  let currentPath = ''
  const crumbs = path.split("/").slice(1).map( (token) => {
    currentPath += `/${token}`
    return {'text': token, 'url': currentPath}
  } )

  return crumbs
};
