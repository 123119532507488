import "./styles.scss";

const Label = ({ title, classNames = [], color = "white" }) => {
  if (classNames.length === 0) {
    classNames.push("primary");
  }

  const styles = classNames.join(" ");

  return <div style={{backgroundColor: color}} className={"label label__".concat(styles)}>{title}</div>;
};

export default Label;
