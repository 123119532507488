import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col } from "react-bootstrap";
import { useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import FormWizard from "../../Form/FormWizard/FormWizard";
import FormCard from "../Cards/FormCard/FormCard";
import FormPage from "../FormPage";

const LeftCardSlot = ({ children }) => {
  return <>{children}</>;
};

const RightCardSlot = ({ children }) => {
  return <>{children}</>;
};

const CreateForm = ({
  fields,
  validationSchema,
  onSubmit,
  onError,
  btnSubmit,
  header,
  leftCardSlot,
  rightCardSlot,
  resolver,
  defaultValues,
  triggerValidationFields
}) => {
  const getResolver = ({ validationSchema, resolver }) => {
    if (resolver) {
      return resolver;
    }
    if (validationSchema) {
      return yupResolver(validationSchema);
    }

    return null;
  };
  const methods = useForm({
    mode: "all",
    defaultValues,
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: getResolver({ validationSchema, resolver })
  });

  const { handleSubmit, formState, trigger, control } = methods;

  const { isDirty, dirtyFields } = formState;
  const watchedValuesForValidation = useWatch({
    control,
    name: triggerValidationFields?.length > 0 ? triggerValidationFields : ""
  });

  useEffect(() => {
    if (triggerValidationFields?.length > 0) {
      if (isDirty) {
        const fieldToTrigger = triggerValidationFields.filter((field) =>
          Object.keys(dirtyFields).includes(field)
        );
        trigger(fieldToTrigger);
      }
    }
  }, [watchedValuesForValidation]);

  return (
    <FormPage {...{ header }}>
      {leftCardSlot && (
        <Col style={{ flexGrow: 1 }}>
          <LeftCardSlot>{leftCardSlot}</LeftCardSlot>
        </Col>
      )}
      <Col style={{ flexGrow: 3 }}>
        <FormCard>
          <FormProvider {...methods}>
            <form
              style={{ width: "100%" }}
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="form-records">
                <FormWizard {...{ fields }} inputClass="form-records-fields" />
              </div>
              <div className="d-flex justify-content-center">
                <Button className="text-white" type="submit">
                  {formState.isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  {btnSubmit?.text}
                </Button>
              </div>
            </form>
          </FormProvider>
        </FormCard>
      </Col>
      {rightCardSlot && (
        <Col style={{ flexGrow: 1 }}>
          <RightCardSlot>{rightCardSlot}</RightCardSlot>
        </Col>
      )}
    </FormPage>
  );
};

export default CreateForm;
