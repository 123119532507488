import { useState, useRef } from "react"
import "./styles.scss"

const VideoPlayer = ({src, buttonTitle, buttonAction}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoPlayerEl = useRef(null)

  const togglePlaying = (event) => {
    const updatedIsPlaying = !isPlaying;
    setIsPlaying(updatedIsPlaying)
    if (updatedIsPlaying) {
      videoPlayerEl.current.play()
    } else {
      videoPlayerEl.current.pause()
    }
  }

  return (
    <div className={"VideoPlayer " + (isPlaying ? 'VideoPlayer--playing': '')}>
      <button className="VideoPlayer__play-button" data-testid="play-button" onClick={togglePlaying}></button>
      <video ref={videoPlayerEl} data-testid="video-player" src={src}/>
      <button className="VideoPlayer__action-button" onClick={buttonAction}>{buttonTitle}</button>
    </div>
  )
}

export default VideoPlayer;