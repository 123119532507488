import { DataStore } from "@aws-amplify/datastore";
import { useAuth } from "App/components/Auth";
import { subscribeToModel } from "App/helpers/dataStore";
import { getQueryFilter } from "App/helpers/auth";
import { Simulation } from "models";

const getSimulationById = async (id) => {
  if (!id) throw new Error("Invalidate id");

  return DataStore.query(Simulation, id);
};

const updateSimulation = async (id, data) => {
  const simulation = await getSimulationById(id);

  const response = DataStore.save(
    Simulation.copyOf(simulation, (s) => {
      s.propertyValue = data.propertyValue;
      s.loanValue = data.loanValue;
      s.monthlyIncome = data.monthlyIncome;
      s.loanDate = data.loanDate;
      s.skipMonth = data.skipMonth;
      s.gracePeriod = data.gracePeriod;
      s.linkPDFSimulation = data.linkPDFSimulation;
      s.term = data.term;
    })
  );

  return response;
};

const saveSimulation = async (simulation) => {
  const response = await DataStore.save(
    new Simulation({
      ...simulation
    })
  );
  console.log({ id: response.id})
  return response;
};

const getSimulationsByMemberId = async (memberId) => {
  try {
    const simulations = await DataStore.query(Simulation, (simulation) => simulation.memberId('eq', memberId))

    return simulations
  } catch (error) {
      console.log('error on get simulations:', error);
  }
}

const useSubscribeToSimulations = () => {
  const { user } = useAuth()
  const member = user.Members.items[0];
  const queryFilter = getQueryFilter({groups: user.cognitoGroups, member})

  return {
    'subscribeSimulations': () => {
      return new Promise((resolve, reject) => {
        subscribeToModel({
          model: "Simulation",
          filter: queryFilter,
          callback: (items) => {
            resolve(items)
          }
        }); 
      })
    }
  }
}

const getSimulationsByUser = async ({user}) => {
  const member = user.Members.items[0];
  const queryFilter = getQueryFilter({groups: user.cognitoGroups, member})
  const simulations = await DataStore.query(Simulation, queryFilter)

  return simulations;
}


export { getSimulationById, updateSimulation, saveSimulation, getSimulationsByMemberId, useSubscribeToSimulations, getSimulationsByUser };
