import { useState } from "react";
import Button from "../../components/Btn/Btn";
import "./styles.scss";

const clsx = (active) => (active ? "primary" : "secondary");
const NeBtn = ({ active = false, label, value, toogle }) => {
  return (
    <Button
      key={"ref-" + value}
      classNames={[clsx(active), "large-2"]}
      title={label}
      onClick={(data) => toogle(data)}
    />
  );
};
const ContextSelect = ({ defaultOption = "HE", children }) => {
  console.log({defaultOption})
  const [isActive, setIsActive] = useState(defaultOption);
  useState(()=>{
    setIsActive(defaultOption)
  })
  return (
    <>
      <div className="ContextSelect__buttonsArea">
        <NeBtn
          {...{
            label: "Home Equity",
            value: "HE"
          }}
          toogle={() => setIsActive("HE")}
          active={isActive === "HE"}
        />
        <NeBtn
          {...{
            label: "Financiamento",
            value: "FI"
          }}
          active={isActive === "FI"}
          toogle={() => setIsActive("FI")}
        />
      </div>
      {children({ contextValue: isActive })}
    </>
  );
};

export default ContextSelect;
