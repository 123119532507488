import { useEffect, useState } from "react";
import { useAuth } from "App/components/Auth";
import { SectionedForm, DIRECTIONS } from "App/components/Form/SectionedForm";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { uploadAvatar, generateAvatarFileName } from "App/helpers/storage/uploadAvatar";
import { updateFieldsWithDefaultValues } from "App/helpers/forms/utils";
import { getMemberFields, getAvatarDefaultValue } from "App/helpers/forms/member"
import { updateMember } from "App/services/MemberService";
import { validationSchema } from "./validations";
import { memberAvatarFields, memberFields, memberContactFields } from "./fields";

const MemberProfile = ({member}) => {
  const [formSections, setFormSections] = useState()
  const { actions: actionsToast, toast } = useToastily();
  const { getCurrentSession } = useAuth()  

  useEffect( async () => {
    if (member) {
      try {
        let defaultValues = getMemberFields(member);
        let defaultAvatarValue = [
          {
            'name': 'avatar',
            'defaultValue': member.avatar
          }
        ]
        const memberFieldsWithDefaultValues = updateFieldsWithDefaultValues(memberFields, defaultValues)
        const memberContactFieldsWithDefaultValues = updateFieldsWithDefaultValues(memberContactFields, defaultValues)
        const avatarFieldWithDefaultValue = updateFieldsWithDefaultValues(memberAvatarFields, defaultAvatarValue)
        setFormSections([
          { fields: avatarFieldWithDefaultValue, sectionClass: "form-avatar", inputClass: "" },
          { fields: memberFieldsWithDefaultValues, inputClass: "form-records-fields" },
          { title: "Informações de contato", fields: memberContactFieldsWithDefaultValues, inputClass: "form-records-fields" }
        ]);
      } catch (error) {
        console.log({error})
      }
    }
  }, [member])

  const onSubmit = async (formData) => {
    if (formData.avatar) {
      const fileName = generateAvatarFileName(formData.avatar)
      const {key: avatarPath} = await uploadAvatar(fileName, formData.avatar)
      formData.avatar = avatarPath
    }
    try {
      await updateMember(member.id, formData);
      const updatedUser = await getCurrentSession();
      actionsToast.setSuccess("Dados atualizados com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possivel atualizar os dados. Tente novamente mais tarde."
      );
      console.log({ error });
    }
  }

  return (
    <>
      <Toastily {...toast} />
      {formSections && (<SectionedForm direction={DIRECTIONS.VERTICAL} sections={formSections} onSubmit={onSubmit} validation={validationSchema}/>)}    
    </>
  )
};

export default MemberProfile;
