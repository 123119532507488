import { Row } from "react-bootstrap";

const FormCard = ({ header, children }) => (
  <Row className="card justify-content-around mb-3">
    <div className="profile-show">
      <div className="profile-show-header">{header}</div>
      {children}
    </div>
  </Row>
);

export default FormCard;
