import { Container } from "react-bootstrap";
import { Sidebar } from "../Sidebar/Sidebar";
import NavProvider from "../Sidebar/NavProvider";
import { RequireAuth } from "../Auth";
import Footer from "../Footer/Fotter";
import { NotificationProvider } from "../Providers/NotificationProvider";
import "./style.scss";
import { useAuth } from "../Auth";
import { useNavigate } from "react-router-dom";
import HeaderContainer from "../../containers/Header/Header";

const PublicLayout = ({ children }) => {
  return (
    <>
      <HeaderContainer transparent={true} />
      {children}
    </>
  );
};

const PrivateLayout = ({ children }) => {
  return (
    <>
      <RequireAuth>
        <NavProvider>
          {(state) => (
            <div
              className="d-flex h-100"
              style={{ backgroundColor: "#f0f0f7", minHeight: "100vh" }}
            >
              <Sidebar {...state} />
              <div
                className="d-flex flex-column w-100 h-100 justify-content-between"
                style={{ minHeight: "100vh" }}
              >
                <div id="content">
                  <NotificationProvider>
                    <HeaderContainer toogleOffCanvas={state.toogleOffCanvas} />
                  </NotificationProvider>
                  {children}
                </div>
                <Footer />
              </div>
            </div>
          )}
        </NavProvider>
      </RequireAuth>
    </>
  );
}

export { PublicLayout, PrivateLayout };
