import { memberFields } from "./helpers/constants";
import { useMember } from "../../../../components/Providers/MemberProvider";
import CreateForm from "../../../../components/FormPage/CreateForm/CreateForm";
import Toastily from "../../../../components/Toastily/Toastily";
import { useToastily } from "../../../../components/Toastily/useToastily";
import { validateCpf } from "../../../../components/Form/FormInputs/helpers/utils";
import * as yup from "yup";
import { triggerCreateMember } from "./helpers/helpers";
import ProfileCard from "../../../../components/FormPage/Cards/ProfileCard/ProfileCard";
import { useState } from "react";
import Page from "../../../../components/Page/Page";

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .test("cpf validation", "Documento Inválido", (cpf) => {
      if (!cpf) return false;
      return validateCpf(cpf.replace(/\D/g, ""));
    }),
  email: yup
    .string()
    .required("Campo obrigatório")
    .matches(/\S+@\S+\.\S+/, "Por favor, informe um e-mail válido!")
});

const MemberProfile = () => {
  const { member } = useMember();
  const { toast, actions: actionsToast } = useToastily();
  const [picture, setPicture] = useState();

  const onSubmit = async (data) => {
    try {
      await triggerCreateMember(
        {
          ...data,
          avatar: picture
        },
        member
      );
      actionsToast.setSuccess("Membro criado com sucesso!");
    } catch (err) {
      console.error(err);
      actionsToast.setError(
        err.message ||
          "Não foi possível realizar a criação de um novo membro. Tente novamente mais tarde!"
      );
    }
  };

  const getPicture = (value) => {
    setPicture(value);
  };

  const props = {
    fields: memberFields,
    validationSchema,
    onSubmit,
    btnSubmit: {
      text: "Criar Membro"
    },
    header: {
      title: "Membro",
      subtitle: "Crie um novo membro para sua organização"
    },
    rightCardSlot: (
      <ProfileCard {...{ getPicture, avatar: { readonly: false } }} />
    )
  };

  return (
    <>
      <Page showBreadcrumb={true} fullWidth={true} >
        <Toastily {...toast} />
        <CreateForm
          {...{
            fields: memberFields,
            validationSchema,
            onSubmit,
            btnSubmit: {
              text: "Criar Membro"
            },
            header: {
              title: "Membro",
              subtitle: "Crie um novo membro para sua organização"
            },
            rightCardSlot: (
              <ProfileCard {...{ getPicture, avatar: { readonly: false } }} />
            )
          }}
        />
      </Page>
    </>
  );
};

export default MemberProfile;
