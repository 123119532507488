import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { DataStore } from "@aws-amplify/datastore";
import { Organization } from "../../../../../../models";
import { FormGroup, Row } from "react-bootstrap";
import "../styles.scss";

const maxLength = 40;

const OrganizationSelect = ({
                                name,
                                cognitoGroups = [],
                                organization,
                                inputClassNames = []
                            }) => {
    const [organizationsOptions, setOrganizationsOptions] = useState([]);
    const {
        formState: { errors },
        control,
        setValue
    } = useFormContext();

    // Fetch organizations from DataStore
    const fetchOrganizations = async () => {
        const models = await DataStore.query(Organization, (o) =>
            o.name("contains", "").or((o) => o.documentNumber("contains", ""))
        );
        return models.map(({ id, name, documentNumber }) => ({
            value: id,
            label: `${documentNumber} (${name})`
        }));
    };

    const formatOptionLabel = ({ label }) => {
        return label.length > maxLength
            ? `${label.substring(0, maxLength)}...`
            : label;
    };

    useEffect(() => {
        if (organization) {
            setValue(name, { value: organization.id, label: organization.name });
            setValue("member.organizationId", organization.id);
        }
    }, [organization, setValue, name]);

    useEffect(() => {
        const fetchOrganizationsAndSetState = async () => {
            const options = await fetchOrganizations();
            setOrganizationsOptions(options);
        };

        if (cognitoGroups.includes("Pontte_Users") && organization) {
            fetchOrganizationsAndSetState();
        }
    }, [cognitoGroups, organization]);

    return (
        <Row className={"mb-4 " + inputClassNames.join(" ")}>
            <FormGroup>
                <label className={"input-label bold form-label"} htmlFor={name}>
                    <span>Parceiro</span>
                </label>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={
                        organization
                            ? { value: organization.id, label: organization.name }
                            : null
                    }
                    render={({ field }) => (
                        <Select
                            {...field}
                            styles={{ backgroundColor: "white" }}
                            options={organizationsOptions}
                            className={"simple-select-input"}
                            isDisabled={!cognitoGroups.includes("Pontte_Users")}
                            placeholder="Selecione uma organização"
                            isSearchable={true}
                            formatOptionLabel={formatOptionLabel}
                            menuPlacement={"bottom"}
                            menuShouldScrollIntoView={true}
                            onChange={(value) => {
                                setValue("member.organizationId", value?.value || "");
                                field.onChange(value);
                            }}
                            onBlur={field.onBlur}
                        />
                    )}
                />
                {errors[name] && <span>{errors[name].message}</span>}
            </FormGroup>
        </Row>
    );
};

OrganizationSelect.propTypes = {
    name: PropTypes.string.isRequired,
    cognitoGroups: PropTypes.array,
    organization: PropTypes.object,
    inputClassNames: PropTypes.array
};

export default OrganizationSelect;
