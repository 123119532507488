import { formatMoney, formatPercentage } from "../helpers/number";
import { ColorScheme, fillColor } from "../components/constants";

const { $MAIN_PURPLE } = ColorScheme;

const formatValue = (value) => ({
  text: value,
  bold: true,
  fontSize: 10,
  color: $MAIN_PURPLE
});

const layout = {
  defaultBorder: false,
  fillColor: (rowIndex) => fillColor(rowIndex),
  paddingLeft: () => 15
};

const table1 = ({
  first_installment,
  dueDay,
  gracePeriod,
  ltv,
  index,
  table,
  itbi,
  propertyValue,
  reportPropertyValue
}) => {
  const propertyRealValue = reportPropertyValue || propertyValue;

  const body = [
    ["PRIMEIRA PRESTAÇÃO", formatValue(formatMoney(first_installment))],
    ["DIA DO VENCIMENTO", formatValue(dueDay)],
    ["INDEXADOR", formatValue(index)],
    [
      "CARÊNCIA",
      formatValue(`${gracePeriod ? `${gracePeriod} meses` : "Nenhum"}`)
    ],
    ["VALOR DO IMÓVEL", formatValue(formatMoney(propertyRealValue))],
    ["LTV", formatValue(formatPercentage(ltv * 100))]
  ];
  if (itbi && table) {
    body.push(
      ["ITBI", formatValue(itbi === "true" ? "Sim" : "Não")],
      ["TABELA", formatValue(table)]
    );
  }
  return body;
};
const table2 = ({
  netLoan,
  iof,
  teo,
  registry_value,
  eCpfInvoiceValue,
  entranceValue
}) => {
  const body = [
    ["VALOR LÍQUIDO", formatValue(formatMoney(netLoan))],
    ["IOF", formatValue(formatMoney(iof))],
    ["TAC", formatValue(formatMoney(teo))],
    ["E-CPF", formatValue(formatMoney(eCpfInvoiceValue))],
    ["REGISTRO", formatValue(formatMoney(registry_value))]
  ];
  if (entranceValue) {
    body.push(["VALOR DA ENTRADA", formatValue(formatMoney(entranceValue))]);
  }
  return body;
};
const proposal = ({ proposal, final }) => {
  const data = {
    stack: [
      {
        style: "proposalTable",
        table: {
          headerRows: 0,
          widths: ["*", 100],
          body: table1(proposal)
        },
        layout,
        margin: [0, 40, 0, 0]
      },
      {
        style: "proposalTable",
        table: {
          headerRows: 0,
          widths: ["*", 100],
          body: table2(proposal)
        },
        layout,
        margin: [0, 20, 0, 0]
      }
    ],
    margin: [45, 30, 0, 0]
  };

  if (!final) {
    data.stack.push({
      text: "* Os valores demonstrados nesta proposta são informações preliminares e de caráter provisório, estando condicionadas à posterior comprovação de dados e análise de crédito, podendo sofrer alterações.",
      fontSize: 10,
      margin: [0, 15, 0, 0],
      bold: false
    });
  }

  return data;
};

export { proposal as proposalPage };
