import {
  validateCompromisedIncome,
  validateLTV
} from "App/helpers/simulations/validationSimulation";
import { objectCamelToSnake } from "App/helpers/utils";
import { ApiPortal } from "App/services/api/ApiPortal";

export const saveSimulation = async (values, toast, cb) => {
  try {
    const api = new ApiPortal();
    const data = objectCamelToSnake(values);
    const flow = await api.calculateFlowHE({ ...data, use_new_tac_calc: true  });

    validate({
      formValues: data,
      calculatedData: flow,
      toast
    });

    cb();
  } catch (error) {
    toast.setError(error.message);
  }
};

const validate = ({ formValues, calculatedData, toast }) => {
  validateCompromisedIncome(
    calculatedData.installment,
    formValues["monthly_income"],
    toast
  );
  validateLTV(calculatedData.ltv, toast);
};
