import {DataStore, Predicates} from "@aws-amplify/datastore";
import {API, graphqlOperation} from "aws-amplify";
import {listMembers} from "../../graphql/queries";
import {Member, UserInvite} from "../../models";

const saveMember = (email, organizationId, name = "") => DataStore.save(new Member({
    name, email, organizationId
}));

const createMember = (member) => DataStore.save(new Member({
    ...member
}));

const getMemberById = (id) => {
    if (!id) throw new Error("Invalidate id");
    return DataStore.query(Member, id);
};

const updateMember = async (id, data) => {
    const member = await getMemberById(id);

    const updatedMember = {...member, ...data};

    DataStore.save(Member.copyOf(member, (m) => {
        m.name = updatedMember.name;
        m.documentNumber = updatedMember.documentNumber;
        m.userId = updatedMember?.userId;
        m.phone = updatedMember?.phone;
        m.address = updatedMember?.address;
        m.complement = updatedMember?.complement;
        m.city = updatedMember?.city;
        m.avatar = updatedMember.avatar;
        if (updatedMember.welcome === true || updatedMember.welcome === false) {
            m.welcome = updatedMember.welcome;
        }
        m.phone = data.phone;
    }));
};

const getMemberByEmail = async (email, organizationId) => {
    if (!email) throw new Error("Invalidate email");
    if (!organizationId) throw new Error("Invalidate organization");

    const member = await DataStore.query(Member, (m) => m.email("eq", email).organizationId("eq", organizationId));

    return member[0];
};

const getMembersByEmail = async (email) => {
    if (!email) throw new Error("Invalidate email");

    const members = await DataStore.query(Member, (m) => m.email("eq", email));

    return members;
};
const getUserInvites = async (guestId, orgId) => {
    const userInvites = await DataStore.query(UserInvite, (u) => u.guestId("eq", guestId).organizationId('eq', orgId));
    console.log(userInvites)
    return userInvites
}

const getMembersByMonth = async (month, year) => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    if (month.length === 1) {
        month = "0" + month;
    }

    try {
        const {
            data: {
                listMembers: {items}
            }
        } = await API.graphql(graphqlOperation(listMembers, {
            filter: {
                createdAt: {
                    between: [`${year}-${month}-01T00:00:00`, `${year}-${month}-${lastDayOfMonth}}T23:59:59`]
                }
            }
        }));

        return items;
    } catch (error) {
        console.log("error on get members:", error);
    }
};
const getMembersUntilMonth = async (month, year) => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();

    try {
        const members = await DataStore.query(Member, (member) => member.createdAt("lt", `${year}-0${month}-${lastDayOfMonth}}T23:59:59`));
        return members;
    } catch (error) {
        console.log("error on get members:", error);
    }
};
const getAllMembers = async () => {
    try {
        const members = await DataStore.query(Member, Predicates.ALL);

        return members;
    } catch (error) {
        console.log("error on get members:", error);
    }
};

const getMembersByOrganizationIdUntilMonth = async (organizationId, month, year) => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    if (month.toString.length === 1) {
        month = "0" + month.toString()
    }

    try {
        const members = await DataStore.query(Member, (member) => member.organizationId('eq', organizationId).createdAt("lt", `${year}-${month}-${lastDayOfMonth}}T23:59:59`));
        return members;
    } catch (error) {
        console.log("error on get members:", error);
    }
};


const getAllMembersByOrganizationId = async (organizationId) => {
    try {
        const members = await DataStore.query(Member, (member) => member.organizationId('eq', organizationId));

        return members;
    } catch (error) {
        console.log("error on get members:", error);
    }
};


const deleteMember = async (id) => {
    try {
        await DataStore.delete(Member, id);
    } catch (error) {
        console.log("error on delete members:", error);
    }
}

export {
    saveMember,
    createMember,
    updateMember,
    getMemberById,
    getMembersByMonth,
    getMemberByEmail,
    getMembersByEmail,
    getAllMembers,
    getMembersUntilMonth,
    getMembersByOrganizationIdUntilMonth,
    getAllMembersByOrganizationId,
    deleteMember,
    getUserInvites
};
