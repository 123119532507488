import { DataStore } from "@aws-amplify/datastore";
import { UserInvite, Lead, Member, Organization, Simulation, User } from "../../models";

const models = {
  UserInvite,
  Lead,
  Member,
  Organization,
  Simulation, 
  User
};

export const subscribeToModel = async ({
  model,
  filter = (e) => e,
  callback
}) => {
  DataStore.observeQuery(models[model], filter).subscribe((snapshot) => {
    const { items, isSynced } = snapshot;

    if (isSynced) {
      callback(items);
    } 
    
    console.log(
      `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
    );
  });
};
