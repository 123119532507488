import React from 'react';
import "./styles.scss"
import Iframe from "../../../components/Iframe/Iframe";

const PublicPage = () => {
  return (
    <div  className="PublicPage" >
      <Iframe url="https://www.sejaparceiro.pontte.com.br/" />
    </div>
  );
};

export default PublicPage;