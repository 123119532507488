import { Breadcrumb } from './Breadcrumb'
import { useLocation } from 'react-router-dom'
import { generateCrumbsFromPath } from '../../helpers/navigation'
import { Helmet } from 'react-helmet'
import { SimplePage } from "../Pages/SimplePage"
import { faClosedCaptioning } from '@fortawesome/free-regular-svg-icons'

const Page = ({children, showBreadcrumb, crumbs, fullWidth=false, helmet, noSpaces=false, medium=true, large=false}) => {
  const location = useLocation()
  showBreadcrumb = false; // @TODO Disable before revamp
  crumbs = undefined // @TODO Disable before revamp

  if (showBreadcrumb && !crumbs) {
    crumbs = generateCrumbsFromPath(location.pathname)
  }

  return (
    <>
      {!fullWidth && (
        <SimplePage medium={medium} large={large} noSpaces={noSpaces}>
        {children}
        </SimplePage>
      )}
      {fullWidth && (
        <div>
          { (showBreadcrumb || crumbs?.length > 0) && <Breadcrumb crumbs={crumbs}/> }
          {children}
        </div>
      )}
    </>
  )
}

export default Page;