import React from "react";
import LargeIframe from "../../../../components/Iframe/LargeIframe";

const HelpContent = () => (
  <LargeIframe
    url={
      "https://www.sejaparceiro.pontte.com.br/c%C3%B3pia-interna-material-he"
    }
  />
);

export default HelpContent;
