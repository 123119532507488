import { Container, Row, Col } from "react-bootstrap";
import Toastily from "../Toastily/Toastily";
import Header from "./Cards/HeaderCard";
import "./styles.scss";

const FormPage = ({ header, toast, children }) => {
  return (
    <Container fluid className="flex form-page">
      <div className="image" />
      <Row className="d-flex w-100">
        <Col className="d-flex">
        <Toastily {...toast} />
        <Row>
          <Header {...header} />
        </Row>
        <Row className="form-page-children xs-1 justify-content-center">
          {children}
        </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FormPage;
