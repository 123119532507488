import './style.scss'


const ImageStick = ({src, size, position, offset}) => {

  return (
    <div className="ImageStick" style={ { ...size, ...position } }>
      <img className="ImageStick__image" src={src} style={{...offset}}/>
    </div>
  )
}

export default ImageStick;