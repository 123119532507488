import EmbeddedForm from "../../../components/Pipefy/EmbeddedForm/EmbeddedForm";
import { CONFIG } from "App/config/config";

const PIPEFY_PUBLIC_FORM = CONFIG?.PIPEFY_PIPE_FI_FORM_URL || "https://app.pipefy.com/public/form/rDv0mKk0";

const FI = ({ initialValues }) => {
  const url = `${PIPEFY_PUBLIC_FORM}?embedded=true`;

  return (
    <EmbeddedForm
      title={"cadastro leads Finan"}
      initialValues={initialValues}
      PIPEFY_PUBLIC_FORM={url}
      size={{ width: " 100%", height: "260vh" }}
    />
  );
};

export default FI;
