import {
  formatDateToLocaleDateBR,
  formatGracePeriod,
  formatMoney2,
  formatMonth,
  formatTerms,
  translateDate2
} from "../../../../../helpers/formats";

import { setPosition } from "../../../../../helpers/utils";
import { ApiPortal } from "../../../../../services/api/ApiPortal";


const triggerCalculateFlow = async (simulation, cb) => {
  const apiPortal = new ApiPortal();

  const payload = {
    legal_person: simulation.legalPerson?.toString(),
    itbi: simulation.itbi?.toString(),
    table: simulation.table,
    grace_period: simulation.gracePeriod,
    day_due: simulation.dayDue,
    // skip_month: simulation.skipMonth,
    loan_value: simulation.loanValue,
    terms: simulation.term,
    entrance_value: simulation.entranceValue,
    monthly_income: simulation.monthlyIncome,
    property_value: simulation.propertyValue,
    interest_rate: 0.85,
    use_new_tac_calc: true
  };

  if (payload.skip_month == -1) {
    delete payload.skip_month;
  }

  cb(await apiPortal.calculateFlowFI(payload));
};

const columns = [
  {
    key: "position",
    value: "Parcela"
  },
  {
    key: "dueOn",
    value: "Data Vencimento"
  },
  {
    key: "quota",
    value: "Parcelas Mensais"
  },
  {
    key: "payment",
    value: "Amortização"
  },
  {
    key: "interest",
    value: "Juros"
  },
  {
    key: "balanceDue",
    value: "Saldo Devedor"
  },
  {
    key: "mip",
    value: "Seguro MIP"
  },
  {
    key: "dfi",
    value: "Seguro DFI"
  },
  {
    key: "adm",
    value: "Taxa Adm"
  },
  {
    key: "installment",
    value: "Prestação Mensal"
  }
];

const formatHeader = (simulation, flow) => [
  {
    key: "Valor do Empréstimo",
    value: formatMoney2(simulation.loanValue)
  },
  {
    key: "Prazo",
    value: formatTerms(simulation.term)
  },
  {
    key: "Valor do Imóvel:",
    value: formatMoney2(simulation.propertyValue)
  },
  {
    key: "Renda Mensal",
    value: formatMoney2(simulation.monthlyIncome)
  },
  {
    key: "Valor de Entrada",
    value: formatMoney2(simulation.entranceValue)
  },
  {
    key: "Carência",
    value: formatGracePeriod(simulation.gracePeriod)
  },
  {
    key: "Primeira Parcela",
    value: translateDate2(flow.installment ? flow.installment[0].dueOn : "")
  },
  {
    key: "Tipo de Amortização",
    value: simulation.table || "SAC"
  },
  {
    key: "Mês do ano sem pagar",
    value: formatMonth(simulation.skipMonth)
  }
];

const formatInstallments = (installments) =>
  installments.map((item, index) => ({
    position: setPosition(index, installments.length),
    dueOn: formatDateToLocaleDateBR(item.dueOn),
    quota: formatMoney2(item.quota),
    payment: formatMoney2(item.payment),
    interest: formatMoney2(item.interest),
    balanceDue: formatMoney2(item.balanceDue),
    mip: formatMoney2(item.mip),
    dfi: formatMoney2(item.dfi),
    adm: formatMoney2(item.adm),
    installment: formatMoney2(item.installment)
  }));

export { columns, formatHeader, formatInstallments, triggerCalculateFlow };
