export const updateFieldsWithDefaultValues = (fields, defaultValues) => {
  let fieldsWithDefaultValues = fields;
  if (defaultValues?.length > 0) {
    fieldsWithDefaultValues = fields.map((field) => {
      const defaultValue = defaultValues.find( (defaultValue) => {
        return defaultValue.name === field.name
      })

      return {
        ...field,
        ...defaultValue
      }
    });
  }

  return fieldsWithDefaultValues;
}