/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormWizard from "App/components/Form/FormWizard/FormWizard";
import { saveSimulation as fetchSimulation } from "./services/services";
import { fields } from "./helpers/fields";
import { useEffect } from "react";
import { validationSchema } from "./helpers/validations";
import Btn from "App/components/Btn";
import {
  saveSimulation,
  updateSimulation
} from "App/services/SimulationService";
import { buildPayload } from "./helpers/utils";
import { useNavigate } from "react-router-dom";

const Create = ({ actions: actionsToast, member }) => {
  const defaultValues = {};

  fields.forEach(({ name, defaultValue }) => {
    defaultValues[name] = defaultValue;
  });

  const methods = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const navigate = useNavigate();

  const { handleSubmit, formState, setValue, watch } = methods;
  const { isSubmitting } = formState;

  const [propertyValue, loanValue] = watch([
    "propertyValue",
    "loanValue"
  ]);

  const setLoanValue = (value) => setValue("loanValue", value);

  useEffect(() => {
    if (!!loanValue && !!propertyValue) {
      methods.trigger("loanValue")
      methods.trigger("propertyValue")
    }
  }, [propertyValue, loanValue]);

  const triggerCalculator = async (data) => {
    const payload = buildPayload({ ...data, type: "HE", use_new_tac_calc: true }, member);



    await fetchSimulation(data, actionsToast, () => {
      saveSimulation(payload)
        .then((s) => {
          updateSimulation(s.id, {
            ...s,
            linkPDFSimulation: `https://parcerias.pontte.com.br/private/simulations/${s.id}`
          });
          navigate(`/private/simulations/${s.id}`);
        })
        .catch(() =>
          actionsToast.setError(
            "Algo inesperado aconteceu. Tente novamente mais tarde"
          )
        );
    });

    return data;
  };

  return (
    <FormProvider {...methods}>
      <Row justify="center" style={{ justifyContent: "center" }}>
        <Col className="pp-new-public-form-fields ">
          <Form onSubmit={handleSubmit(triggerCalculator)}>
            <div className="form-records" style={{ padding: "0" }}>
              <FormWizard {...{ fields }} inputClass="form-records-fields" />
            </div>
            <div className="d-flex justify-content-start">
              <Btn
                title="Calcular fluxo"
                loading={isSubmitting}
                onClick={handleSubmit(triggerCalculator)}
                />
            </div>
          </Form>
        </Col>
      </Row>
    </FormProvider>
  );
};

export default Create;
