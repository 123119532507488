import React from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import Logo from "../Logo/Logo";
import { Divider, Menu } from "./helpers/Menu";

const OffcanvasSidebar = ({ show, toogleOffCanvas }) => (
  <>
    {show && (
      <div className="navbar-nav bg-gradient-primary">
        <Offcanvas
          show={show}
          onHide={toogleOffCanvas}
          style={{ width: "auto" }}
        >
          <ul
            className={"navbar-nav sidebar sidebar-light accordion"}
            style={{ padding: 0 }}
          >
            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="/"
            >
              <Row className="sidebar-brand-icon">
                <Col>
                  <Logo color="#5c3b6b" toggled={true} />
                </Col>
              </Row>
            </a>
            <Divider clsx="my-0" />
            <Menu callback={toogleOffCanvas} />
            <div className="text-center  d-md-inline ">
              <button
                className="rounded-circle border-0"
                id="sidebarToggle"
                onClick={() => toogleOffCanvas()}
              />
            </div>
          </ul>
        </Offcanvas>
      </div>
    )}
  </>
);

export default OffcanvasSidebar;
