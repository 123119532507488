import PropTypes from "prop-types";
import "./styles.scss";

const Btn = ({
  title,
  onClick,
  classNames = [],
  disabled,
  loading = false
}) => {
  if (classNames.length === 0) {
    classNames.push("primary");
  }

  if (disabled) {
    classNames.push("disabled");
  }

  if (loading) {
    classNames.push("disabled");
  }

  const styles = classNames.join(" ");

  return (
    <button
      className={"button button__".concat(styles)}
      onClick={onClick}
      disabled={classNames.includes("disabled")}
    >
      {loading && <span className="spinner-border spinner-border-sm mr-1" />}
      {title}
    </button>
  );
};

Btn.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
  classNames: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default Btn;
