import { Helmet } from "react-helmet";
import "./styles.scss";

const SimplePage = ({
  title,
  subtitle,
  description,
  icon,
  children,
  classNames = [],
  helmet,
  large=false,
  medium=false,
  small=false,
  extraLarge=false,
  noSpaces=false,
  style
}) => {
  if (small) {
    classNames.push('space-small', 'background-small')
  }
  if (medium) {
    classNames.push('space-medium', 'background-medium')
  }
  if (large) {
    classNames.push('space-large', 'background-large')
  }
  if (extraLarge) {
    classNames.push('background-extra-large')
  }
  if(noSpaces) {
    classNames.push('no-spaces')
  }
  return (
    <>
      {helmet?.props?.children && <Helmet>{helmet.props.children}</Helmet>}
      <div
        className={`SimplePage ${classNames && classNames
          .map((className) => {
            return "SimplePage__" + className;
          })
          .join(" ")}`}
      >
        <div className="d-flex SimplePage__header">
          {icon && <img className="SimplePage__icon" src={icon} alt="icon"/> }
          <div>
            <h1 className="SimplePage__title">{title}</h1>
            <p className="SimplePage__subtitle">{subtitle}</p>
            <h2 className="SimplePage__description">{description}</h2>
          </div>
        </div>
        <div className="SimplePage__content">{children}</div>
      </div>
    </>
  );
};

export default SimplePage;
