/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHookActions = /* GraphQL */ `
  mutation CreateHookActions(
    $input: CreateHookActionsInput!
    $condition: ModelHookActionsConditionInput
  ) {
    createHookActions(input: $input, condition: $condition) {
      id
      userId
      action
      data
      authType
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateHookActions = /* GraphQL */ `
  mutation UpdateHookActions(
    $input: UpdateHookActionsInput!
    $condition: ModelHookActionsConditionInput
  ) {
    updateHookActions(input: $input, condition: $condition) {
      id
      userId
      action
      data
      authType
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteHookActions = /* GraphQL */ `
  mutation DeleteHookActions(
    $input: DeleteHookActionsInput!
    $condition: ModelHookActionsConditionInput
  ) {
    deleteHookActions(input: $input, condition: $condition) {
      id
      userId
      action
      data
      authType
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPhase = /* GraphQL */ `
  mutation CreatePhase(
    $input: CreatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    createPhase(input: $input, condition: $condition) {
      id
      name
      color
      index
      description
      pipefyPipeId
      pipefyId
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePhase = /* GraphQL */ `
  mutation UpdatePhase(
    $input: UpdatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    updatePhase(input: $input, condition: $condition) {
      id
      name
      color
      index
      description
      pipefyPipeId
      pipefyId
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePhase = /* GraphQL */ `
  mutation DeletePhase(
    $input: DeletePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    deletePhase(input: $input, condition: $condition) {
      id
      name
      color
      index
      description
      pipefyPipeId
      pipefyId
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      welcome
      authType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      welcome
      authType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      welcome
      authType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWhiteLabel = /* GraphQL */ `
  mutation CreateWhiteLabel(
    $input: CreateWhiteLabelInput!
    $condition: ModelWhiteLabelConditionInput
  ) {
    createWhiteLabel(input: $input, condition: $condition) {
      id
      primary
      secondary
      logo
      background
      tertiary
      type
      partnerName
      Organization {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      active
      path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      whiteLabelOrganizationId
      owner
      __typename
    }
  }
`;
export const updateWhiteLabel = /* GraphQL */ `
  mutation UpdateWhiteLabel(
    $input: UpdateWhiteLabelInput!
    $condition: ModelWhiteLabelConditionInput
  ) {
    updateWhiteLabel(input: $input, condition: $condition) {
      id
      primary
      secondary
      logo
      background
      tertiary
      type
      partnerName
      Organization {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      active
      path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      whiteLabelOrganizationId
      owner
      __typename
    }
  }
`;
export const deleteWhiteLabel = /* GraphQL */ `
  mutation DeleteWhiteLabel(
    $input: DeleteWhiteLabelInput!
    $condition: ModelWhiteLabelConditionInput
  ) {
    deleteWhiteLabel(input: $input, condition: $condition) {
      id
      primary
      secondary
      logo
      background
      tertiary
      type
      partnerName
      Organization {
        id
        name
        documentNumber
        legalName
        phone
        createdAt
        Members {
          items {
            id
            name
            documentNumber
            phone
            createdAt
            birthDate
            organizationId
            email
            avatar
            userId
            welcome
            address
            complement
            city
            authType
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UserInvites {
          items {
            id
            guestId
            hostId
            organizationId
            status
            authType
            email
            organizationName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pipefyId
        Simulations {
          items {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        image
        address
        complement
        city
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      active
      path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      whiteLabelOrganizationId
      owner
      __typename
    }
  }
`;
export const createSimulation = /* GraphQL */ `
  mutation CreateSimulation(
    $input: CreateSimulationInput!
    $condition: ModelSimulationConditionInput
  ) {
    createSimulation(input: $input, condition: $condition) {
      id
      propertyValue
      loanValue
      monthlyIncome
      loanDate
      skipMonth
      gracePeriod
      linkPDFSimulation
      memberId
      organizationId
      term
      dayDue
      type
      legalPerson
      entranceValue
      table
      itbi
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateSimulation = /* GraphQL */ `
  mutation UpdateSimulation(
    $input: UpdateSimulationInput!
    $condition: ModelSimulationConditionInput
  ) {
    updateSimulation(input: $input, condition: $condition) {
      id
      propertyValue
      loanValue
      monthlyIncome
      loanDate
      skipMonth
      gracePeriod
      linkPDFSimulation
      memberId
      organizationId
      term
      dayDue
      type
      legalPerson
      entranceValue
      table
      itbi
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteSimulation = /* GraphQL */ `
  mutation DeleteSimulation(
    $input: DeleteSimulationInput!
    $condition: ModelSimulationConditionInput
  ) {
    deleteSimulation(input: $input, condition: $condition) {
      id
      propertyValue
      loanValue
      monthlyIncome
      loanDate
      skipMonth
      gracePeriod
      linkPDFSimulation
      memberId
      organizationId
      term
      dayDue
      type
      legalPerson
      entranceValue
      table
      itbi
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserInvite = /* GraphQL */ `
  mutation CreateUserInvite(
    $input: CreateUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    createUserInvite(input: $input, condition: $condition) {
      id
      guestId
      hostId
      organizationId
      status
      authType
      email
      organizationName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserInvite = /* GraphQL */ `
  mutation UpdateUserInvite(
    $input: UpdateUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    updateUserInvite(input: $input, condition: $condition) {
      id
      guestId
      hostId
      organizationId
      status
      authType
      email
      organizationName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserInvite = /* GraphQL */ `
  mutation DeleteUserInvite(
    $input: DeleteUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    deleteUserInvite(input: $input, condition: $condition) {
      id
      guestId
      hostId
      organizationId
      status
      authType
      email
      organizationName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      pipefyId
      createdAt
      updatedAt
      name
      phone
      email
      value
      documentNumber
      organizationId
      memberId
      Simulation {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      type
      phaseID
      Phase {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      leadSimulationId
      owner
      __typename
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      pipefyId
      createdAt
      updatedAt
      name
      phone
      email
      value
      documentNumber
      organizationId
      memberId
      Simulation {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      type
      phaseID
      Phase {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      leadSimulationId
      owner
      __typename
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      pipefyId
      createdAt
      updatedAt
      name
      phone
      email
      value
      documentNumber
      organizationId
      memberId
      Simulation {
        id
        propertyValue
        loanValue
        monthlyIncome
        loanDate
        skipMonth
        gracePeriod
        linkPDFSimulation
        memberId
        organizationId
        term
        dayDue
        type
        legalPerson
        entranceValue
        table
        itbi
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      type
      phaseID
      Phase {
        id
        name
        color
        index
        description
        pipefyPipeId
        pipefyId
        Leads {
          items {
            id
            pipefyId
            createdAt
            updatedAt
            name
            phone
            email
            value
            documentNumber
            organizationId
            memberId
            status
            type
            phaseID
            _version
            _deleted
            _lastChangedAt
            leadSimulationId
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      leadSimulationId
      owner
      __typename
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name
      documentNumber
      phone
      createdAt
      birthDate
      organizationId
      email
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      avatar
      userId
      welcome
      address
      complement
      city
      authType
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name
      documentNumber
      phone
      createdAt
      birthDate
      organizationId
      email
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      avatar
      userId
      welcome
      address
      complement
      city
      authType
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      name
      documentNumber
      phone
      createdAt
      birthDate
      organizationId
      email
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      avatar
      userId
      welcome
      address
      complement
      city
      authType
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      documentNumber
      legalName
      phone
      createdAt
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserInvites {
        items {
          id
          guestId
          hostId
          organizationId
          status
          authType
          email
          organizationName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pipefyId
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      image
      address
      complement
      city
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      documentNumber
      legalName
      phone
      createdAt
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserInvites {
        items {
          id
          guestId
          hostId
          organizationId
          status
          authType
          email
          organizationName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pipefyId
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      image
      address
      complement
      city
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      documentNumber
      legalName
      phone
      createdAt
      Members {
        items {
          id
          name
          documentNumber
          phone
          createdAt
          birthDate
          organizationId
          email
          Leads {
            nextToken
            startedAt
            __typename
          }
          Simulations {
            nextToken
            startedAt
            __typename
          }
          avatar
          userId
          welcome
          address
          complement
          city
          authType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          pipefyId
          createdAt
          updatedAt
          name
          phone
          email
          value
          documentNumber
          organizationId
          memberId
          Simulation {
            id
            propertyValue
            loanValue
            monthlyIncome
            loanDate
            skipMonth
            gracePeriod
            linkPDFSimulation
            memberId
            organizationId
            term
            dayDue
            type
            legalPerson
            entranceValue
            table
            itbi
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          status
          type
          phaseID
          Phase {
            id
            name
            color
            index
            description
            pipefyPipeId
            pipefyId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          leadSimulationId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UserInvites {
        items {
          id
          guestId
          hostId
          organizationId
          status
          authType
          email
          organizationName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pipefyId
      Simulations {
        items {
          id
          propertyValue
          loanValue
          monthlyIncome
          loanDate
          skipMonth
          gracePeriod
          linkPDFSimulation
          memberId
          organizationId
          term
          dayDue
          type
          legalPerson
          entranceValue
          table
          itbi
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      image
      address
      complement
      city
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
