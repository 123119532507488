const ConsentBox = () => (
  <div className="consent-box">
    <p className="consent">
      Ao continuar, você concorda com a nossa&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="site-porttal-factory-0-2-1934 site-porttal-factory-d0-0-2-1936 site-porttal-typography-0-2-1927 site-porttal-typography-d0-0-2-1931 site-porttal-link-0-2-1924 site-porttal-classSignUpRouterLink-0-2-1695 site-porttal-typographyFluid-0-2-1930 site-porttal-typographyFluid-d2-0-2-1933"
        href="/politica-de-privacidade"
      >
        Política de Privacidade&nbsp;
      </a>
      e o uso das suas informações pela Pontte, tudo bem?
    </p>
  </div>
);

export default ConsentBox;
