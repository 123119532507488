import axios from "axios";
import { CONFIG } from "App/config/config";

export class ApiPortal {
  _URL =
    CONFIG.SIMULATION_API || "https://api-portal.pontte.com.br";
  _request = null;

  constructor() {
    this._request = axios.create({
      baseURL: this._URL
    });
  }

  async calculateFlowFI(params) {
    const { data } = await this._request.post("/financing/v1/calculator", { ...params });
    return data
  }

  async calculateFlowHE(params) {
    const { data } = await this._request.post("/simulator/v1/calculator", { ...params });
    return data;
  }
}
