/* eslint-disable react-hooks/exhaustive-deps */
import "@fortawesome/fontawesome-free/css/all.min.css";

import { DataStore } from "@aws-amplify/datastore";
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";

import { Navigate, Route, Routes } from "react-router-dom";

import AuthMemberProvider from "./components/Providers/AuthMemberProvider/";
import Layout from "./components/Layout";
import { RequireAuth } from "./components/Auth";

import ROUTES from "./routes";
import "./App.scss";

Amplify.configure(awsconfig);
DataStore.start();

export default function App() {
  return (
    <AuthMemberProvider>
      <Routes>
        <Route element={<Layout />}>
          {ROUTES.map(({ path, element, isPrivate = false, authGroup }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? (
                  <RequireAuth authGroup={authGroup}> {element} </RequireAuth>
                ) : (
                  element
                )
              }
            />
          ))}
          <Route path="/" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    </AuthMemberProvider>
  );
}
