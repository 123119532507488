import React from "react";
import { Container } from "react-bootstrap";
import Toastily from "../../../../components/Toastily/Toastily";
import { useToastily } from "../../../../components/Toastily/useToastily";
import FormInvite from "./FormInvite";
import { triggerSendInvite } from './helpers'
import Page from '../../../../components/Page/Page'
import "./styles.scss";

const UserInvite = () => {
  const { toast, actions } = useToastily();

  return (
    <>
      <Page showBreadcrumb={true}>
        <Toastily {...toast} />
        <Container className="container-user-invite">
          <header className="L-title">
            Envie um convite para fazer parte do Portal do Parceiro!
          </header>
          <FormInvite {...{ triggerSendInvite, actions }} />
        </Container>
      </Page>
    </>
  );
};

export default UserInvite;
