export const memberFields = [
  {
    id: "name",
    name: "name",
    label: "Nome:*",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "email",
    name: "email",
    label: "Email:*",
    type: "email",
    inputClassLabel: ["bold"],
  }
];

export const memberContactFields = [
  {
    id: "address",
    name: "address",
    label: "Endereço:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "complement",
    name: "complement",
    label: "Complemento:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "city",
    name: "city",
    label: "Cidade:",
    type: "short_text",
    inputClassLabel: ["bold"],
  },
  {
    id: "phone",
    name: "phone",
    label: "Telefone:",
    type: "phone",
    inputClassLabel: ["bold"],
  }
];

export const memberAvatarFields = [
  {
    id: "avatar",
    name: "avatar",
    type: "avatar",
    inputClassLabel: ["bold"],
    classNames: ['medium']
  }
];
