/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import iconePageSimulation from "../../../../../images/icone-page-2.svg";
import { getSimulationById } from "../../../../services/SimulationService";
import SimulateFlow from "../../../../containers/Simulations/Flow/SimulateFlow";
import { authorizePage, formatFlowDataToPDF } from "./helpers";

import { SimplePage } from "../../../../components/Pages/SimplePage";
import InstallmentsTable from "../../../../components/Lists/InstallmentsTable";
import Btn from "../../../../components/Btn/Btn";

import "./styles.scss";

import { useMember } from "../../../../components/Providers/MemberProvider";
import { useAuth } from "../../../../components/Auth";
import { generateProposalPdf } from "../../../../helpers/proposal/generateProposalPdf";

const ShowSimulation = () => {
  const [simulation, setSimulation] = useState();
  const [loadingPDF, setLoadingPDF] = useState(false);

  const { id: simulationId } = useParams();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { member } = useMember();

  useEffect(() => {
    if (simulationId && member) {
      (async () => {
        const params = await getSimulationById(simulationId);
        if (!params || !authorizePage({ user, member, simulation: params }))
          return navigate("/not-found");
        setSimulation(params);
      })();
    }
  }, [member, simulationId]);

  const handleGeneratePDF = (flow) => {
    setLoadingPDF(true);

    const fn = generateProposalPdf;
    if(simulation.type === "HE") {
      const calculateGrossLoan = ({iof, teo, eCpfInvoiceValue, registry_value, netLoan }) => iof + teo + eCpfInvoiceValue + registry_value + netLoan 
      flow.grossLoan = calculateGrossLoan(flow)
    }
    

    const data = formatFlowDataToPDF({ flow, simulation });

    fn(data).finally(() => setLoadingPDF(false));
  };

  const handleNewSimulation = () => {
    const route =
      simulation.type === "HE"
        ? "/private/simulations/new/HE" //@TODO pathParam HE
        : "/private/simulations/new/FI"; // @TODO pathParam FI

    navigate(route);
  };

  const handleIndicateOperation = () =>
    navigate(`/private/leads/new/${simulation.type}`, {
      state: {simulation: simulation}
    });

  const subtitle =
    simulation?.type === "HE"
      ? "Home Equity - Fluxo das Parcelas"
      : simulation?.type === "FI"
      ? "Financiamento - Fluxo das Parcelas"
      : "";

  return (
    <SimplePage
      title="Simulação"
      subtitle={subtitle}
      description="Aqui está o resultado da sua simulação:"
      large={true}
      icon={iconePageSimulation}
    >
      <SimulateFlow {...{ simulation }}>
        {({ flow, installments, columns, header, loading }) => (
          <>
            <section className="show-simulation-feature-btns">
              <Btn
                title="FAZER NOVA SIMULAÇÃO"
                onClick={() => handleNewSimulation()}
              />
              <Btn
                title="GERAR PDF"
                onClick={() => handleGeneratePDF(flow)}
                loading={loadingPDF}
                disabled={loading}
              />
            </section>
            <InstallmentsTable
              {...{
                header: header ?? [],
                columns: columns ?? [],
                body: installments ?? [],
                tableTitle: "Parcelas",
                loading,
                footerChild: (
                  <Btn
                    title="Indicar Operação"
                    onClick={() => handleIndicateOperation()}
                  />
                )
              }}
            />
          </>
        )}
      </SimulateFlow>
    </SimplePage>
  );
};

export default ShowSimulation;
