import { Container } from "react-bootstrap";
import PaginatedContainer from "../../../containers/PaginatedContainer/PaginatedContainer";
import { SimpleTable, FooterPaginated } from "../ListItems";
import "./styles.scss";

export const PaginatedList = ({
  header,
  items,
  formatCell,
  onItemClick,
  loading,
  numberPerPage = 5,
  childTopBar,
  showColumns
}) => {
  if (showColumns) {
    header = showColumns.map( (column) => {
      return column.label;
    })
  }
  return(
  <div className="pagelist">
    <PaginatedContainer items={items} numberPerPage={numberPerPage}>
      {({ list, ...footer }) => {
        return (
        <SimpleTable
          showColumns={showColumns}
          header={header}
          list={list}
          onItemClick={onItemClick}
          handleFormatCell={formatCell}
          loading={loading}
          childTopBar={childTopBar}
          childFooter={<FooterPaginated {...footer} />}
        />
      )} }
    </PaginatedContainer>
  </div>
)};

export default PaginatedList;
