import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "App/components/Auth";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { Card, Col, Row } from "react-bootstrap";
import Btn from "App/components/Btn";
import iconePageUser from "../../../../../images/icone-page-user.svg";
import { SimplePage } from "App/components/Pages/SimplePage";
import {
  generateAvatarFileName,
  uploadAvatar
} from "App/helpers/storage/uploadAvatar";
import { useMember } from "App/components/Providers/MemberProvider";

import {
  deleteMember,
  getMemberById,
  getUserInvites,
  updateMember
} from "App/services/MemberService";
import { triggerSendInvite } from "App/helpers/member";
import { DataStore } from "aws-amplify";
import { User, UserInvite } from "../../../../../models";
import CreateForm from "./CreateForm";
import "./styles.scss";
import { HookActionService } from "./helpers/HookActionService";

const updateUserInvite = async (userInvite, newAuthType) => {
  try {
    // Query the original UserInvite object from the DataStore
    const originalInvite = await DataStore.query(UserInvite, userInvite.id);

    // Check if the original UserInvite exists
    if (!originalInvite) {
      throw new Error(`UserInvite with ID ${userInvite.id} not found`);
    }

    // Update and save the UserInvite object
    const updatedInvite = await DataStore.save(
      UserInvite.copyOf(originalInvite, (updated) => {
        updated.authType = newAuthType;
      })
    );

    console.log("UserInvite updated successfully:", updatedInvite);
  } catch (error) {
    console.error("Error updating user invite:", error);
  }
};
const updateUserAuthType = async (guestUser, newAuthType) => {
  try {
    const originalUser = await DataStore.query(User, guestUser.id);

    const updatedUser = await DataStore.save(
      User.copyOf(originalUser, (updated) => {
        updated.authType = newAuthType;
      })
    );

    console.log("User authType updated successfully");
    return updatedUser;
  } catch (error) {
    console.error("Error updating user authType:", error);
  }
};
const Edit = ({
  member,
  invite,
  guestUserPermission,
  handleInvite,
  guestMemberHasUser,
  handleDelete,
  actionsToast,
  inviteData
}) => {
  const { user } = useAuth();
  const { cognitoGroups } = user;
  const isPontteUserWithOrganization = cognitoGroups.includes("Pontte_Users");

  const [guestUser, setGuestUser] = useState({});

  useEffect(() => {
    // Fetch guest user data if needed

    const fetchGuestUser = async () => {
      if (member?.userId) {
        try {
          const memberUser = await DataStore.query(User, member.userId);
          setGuestUser({ ...memberUser });
        } catch (error) {
          console.error("Error fetching guest user:", error);
        }
      }
    };

    fetchGuestUser();
  }, [member]);

  const onSubmit = async (formData) => {
    if (formData.avatar) {
      const fileName = generateAvatarFileName(formData.avatar);
      const { key: avatarPath } = await uploadAvatar(fileName, formData.avatar);
      formData.avatar = avatarPath;
    }

    try {
      await updateMember(member.id, formData);
      if (guestMemberHasUser) {
        await HookActionService.createAuthUpdateHookAction(
          member.userId,
          formData.cognitoGroups
        );
        await updateUserAuthType(guestUser, formData.cognitoGroups);
      } else {
        console.log({ invite, authType: formData.cognitoGroups });
        await updateUserInvite(inviteData, formData.cognitoGroups);
      }
      actionsToast.setSuccess("Dados atualizados com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possível atualizar os dados. Tente novamente mais tarde."
      );
      console.error(error);
    }
  };
  const defaultValues = (member, invite) => {
    console.log({ guestUser });
    return {
      name: member?.name || "",
      email: member?.email || "",
      documentNumber: member?.documentNumber || "",
      phone: member?.phone || "",
      invite: invite || "", // cognitoGroups: guestUser?.authType || "Member"
      cognitoGroups: guestUserPermission
      // Add other fields as needed
    };
  };
  console.log(defaultValues(member, invite));
  return (
    <Card className="card-member">
      <Row className="card-member-header">
        <Col className="card-member-header title">
          <h1 className="L-title">Informações do Usuário</h1>
        </Col>
        <Col className="card-member-header header-items">
          <div className="header-items buttons">
            <Btn title="Convidar" onClick={handleInvite} />
            <Btn title="Deletar" onClick={handleDelete} />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="card-member-body">
        {member && (
          <CreateForm
            gestUser={guestUser}
            user={user}
            guestUserPermission={guestUserPermission}
            onSubmit={onSubmit}
            member={member}
            invite={invite}
            isPontteUserWithOrganization={isPontteUserWithOrganization}
          />
        )}
      </Row>
    </Card>
  );
};

const EditWrapper = () => {
  const currentMember = useMember().member;
  const { user } = useAuth();

  const navigate = useNavigate();
  const { id: memberId } = useParams();
  const { actions: actionsToast, toast } = useToastily();

  const [member, setMember] = useState(null);
  const [inviteData, setInviteData] = useState(null);
  const [invite, setInvite] = useState(null);
  const [guestMemberHasUser, setGuestMemberHasUser] = useState(false);
  const [guestUser, setGuestUser] = useState({});
  const [guestUserPermission, setGuestUserPermission] = useState(null);

  useEffect(() => {
    const fetchMemberAndInvite = async () => {
      if (!memberId) {
        return;
      }

      try {
        const memberData = await getMemberById(memberId);
        const invites = await getUserInvites(
          memberId,
          memberData.organizationId
        );
        const inv = invites[0];
        const invId = inv?.id;
        setInviteData(inv);
        setInvite(
          `https://parcerias.pontte.com.br/register?userInviteId=${invId}`
        );

        if (memberData?.userId) {
          const memberUser = await DataStore.query(User, memberData.userId);
          setGuestUser({ ...memberUser });
          const userPermission = memberUser?.authType?.includes("Admin")
            ? "Admin"
            : "Member";
          setGuestUserPermission(userPermission);
        } else {
          setGuestUserPermission(
            inv?.authType?.includes("Admin") ? "Admin" : "Member"
          );
        }

        setGuestMemberHasUser(!!memberData?.userId);

        if (memberData?.id) {
          setMember(memberData);
        } else {
          navigate("not-found");
        }
      } catch (error) {
        console.error("Error fetching member data:", error);
      }
    };

    fetchMemberAndInvite();
  }, [memberId, navigate]);

  // if has user permission comes from user if not permission comes from invite
  //   keep in mind both invite and user may have empty permissions which indicates a "Member" permission

  const handleInvite = async () => {
    try {
      await triggerSendInvite({ member: currentMember, user }, member);
      actionsToast.setSuccess("Convite enviado com sucesso!");
    } catch (error) {
      actionsToast.setError(
        "Não foi possível enviar o convite. Tente novamente mais tarde."
      );
      console.error("Error sending invite:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMember(member.id);
      navigate("/private/members", {
        state: { toast: "Usuário deletado com sucesso!" }
      });
    } catch (error) {
      actionsToast.setError(
        "Não foi possível deletar o usuário. Tente novamente mais tarde."
      );
      console.error("Error deleting member:", error);
    }
  };

  return (
    <SimplePage title="Adicionar usuário" large={true} icon={iconePageUser}>
      <Toastily {...toast} />
      <Edit
        guestUserPermission={guestUserPermission}
        member={member}
        invite={invite}
        inviteData={inviteData}
        handleInvite={handleInvite}
        guestMemberHasUser={guestMemberHasUser}
        handleDelete={handleDelete}
        actionsToast={actionsToast}
        guestUser={guestUser}
      />
    </SimplePage>
  );
};

export default EditWrapper;
