import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormWizard from "../../../Form/FormWizard/FormWizard";
import "./styles.scss";

const FormImage = ({ getPicture = (_) => _, avatar = {} }) => {
  const methods = useForm();
  const { watch } = methods;
  const { defaultValue, readonly } = avatar;

  const fields = [
    {
      id: "avatar",
      name: "avatar",
      type: "avatar",
      defaultValue,
      readonly
    }
  ];

  const [picture] = watch(["avatar"]);

  useEffect(() => {
    getPicture(picture);
  }, [picture, getPicture]);

  return (
    <FormProvider {...methods}>
      <form style={{ width: "100%" }}>
        <div className="form-image form-records">
          <FormWizard {...{ fields }} inputClass="form-records-fields" />
        </div>
      </form>
    </FormProvider>
  );
};

const ProfileCard = (props) => {
  const { info, config } = props;

  return (
    <div className="card profile-card">
      <div className="profile-card-buttons">
        <Button
          className="card-btn text-white"
          disabled={
            config?.leftButton?.disabled !== undefined
              ? config.leftButton.disabled
              : true
          }
          onClick={config?.leftButton?.clickHandler}
        >
          {config?.leftButton?.text ? config.leftButton.text : "Convidar"}
        </Button>
        <FormImage {...{ ...props }} />

        <Button className="card-btn text-white" disabled>
          Deletar
        </Button>
      </div>
      <div className="profile-card-infos">
        {info && (
          <Row style={{ width: "25rem", maxWidth: "300px" }}>
            <Col className="d-flex flex-column justify-content-center">
              <span className="info">{info?.completed}</span>
              <span className="profile-card__score-label">Concluidos</span>
            </Col>
            <Col className="d-flex flex-column justify-content-center">
              <span className="info">{info?.inProgress}</span>
              <span className="profile-card__score-label">Em Analise</span>
            </Col>
            <Col className="d-flex flex-column justify-content-center">
              <span className="info">{info?.rejected}</span>
              <span className="profile-card__score-label">Reprovados</span>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
