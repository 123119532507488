import EmbeddedForm from "../../../components/Pipefy/EmbeddedForm/EmbeddedForm";
import { CONFIG } from "App/config/config";

const PIPEFY_PUBLIC_FORM = CONFIG?.PIPEFY_PIPE_HE_FORM_URL || "https://app.pipefy.com/public/form/DLjIhyW0";

const HE = ({ initialValues }) => {
  const url = `${PIPEFY_PUBLIC_FORM}?embedded=true`;

  return (
    <EmbeddedForm
      title={"cadastro leads Home Equity"}
      initialValues={initialValues}
      PIPEFY_PUBLIC_FORM={url}
      size={{ width: " 100%", height: "205vh" }}
    />
  );
};

export default HE;