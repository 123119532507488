import LoadingIndicator from "../../../LoadingIndicator";
import { Data, EmptyList, Footer, Header, Items, TopInfo } from "../Items";

const SimpleTable = ({
  list,
  header,
  loading,
  handleFormatCell,
  onItemClick,
  childFooter,
  childTopBar,
  childEmptyList,
  showColumns
}) => (
  <div className="container-list">
    {loading && <LoadingIndicator />}
    <TopInfo children={childTopBar}/>
    <div className="container-table">
      {list?.length === 0 && !loading && (childEmptyList || <EmptyList />)}
      <table className="list">
        <Header list={header} showColumns={showColumns} />
        <Items
          showColumns={showColumns}
          list={list}
          children={Data}
          onItemClick={onItemClick}
          handleFormatCell={handleFormatCell}
        />
      </table>
    </div>
    {childFooter || <Footer />}
  </div>
);

export default SimpleTable;
