//import { useMember } from "../../../../components/Providers/MemberProvider";
import { useMember } from "App/components/Providers/MemberProvider";
import Toastily from "App/components/Toastily/Toastily";
import { useToastily } from "App/components/Toastily/useToastily";
import { FIHEContextSelect } from "App/containers/ContextSelect";
import { SimplePage } from "App/components/Pages/SimplePage";
import simulationsIcon from "images/simulations-icon.svg";
import { FormCreateSimulationFI } from "App/containers/Simulations/FI/Create";
import { FormCreateSimulationHE } from "App/containers/Simulations/HE/Create";
import "./styles.scss";
import { useParams } from "react-router-dom";

const SimulationCreate = () => {
  const { toast, actions } = useToastily();
  const { member } = useMember();
  const { type="HE" } = useParams();
  const newType = type === ":type" ? "HE" : type;

  return (
    <SimplePage
      medium={true}
      title="Simulador"
      description="Preencha todos os campos para gerar uma simulação do fluxo de parcelas:"
      icon={simulationsIcon}
    >
      <Toastily {...toast} />
      <FIHEContextSelect type={newType}>
        {({ contextValue }) => {
          return (
            <div className="SimulationCreate">
              {contextValue === "FI" && (
                <FormCreateSimulationFI {...{ actions, member }} />
              )}
              {contextValue === "HE" && (
                <FormCreateSimulationHE {...{ actions, member }} />
              )}
            </div>
          );
        }}
      </FIHEContextSelect>
    </SimplePage>
  );
};

export default SimulationCreate;
